import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@video-env/environment';
import sweetalert2 from 'sweetalert2';
import { VSSSentryService } from '@redngapps/videosprechstunde/feature';

@Injectable()
export class ApplicationErrorHandler extends ErrorHandler {
  constructor(private sentryService: VSSSentryService) {
    super();
  }

  handleError(error: any): void {
    if (/Loading chunk [^\s]+ failed/.test(error.message)) {
      sweetalert2.fire({
        title: 'Aufgrund einer Softwareaktualisierung muss die Anwendung neu geladen werden.',
        type: 'error',
      });

      return;
    }

    if (environment.featureSwitches.sentry) {
      this.sentryService.handleError(error);
    } else {
      // handler will call the native handleError so do not call it in production mode
      super.handleError(error);
    }
  }
}
