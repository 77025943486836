import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { ConferenceComponent } from './conference/conference.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { TextMaskModule } from 'angular2-text-mask/dist/angular2TextMask';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ImprintComponent } from './imprint/imprint.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedUiModule, RedTooltipModule } from '@redngapps/shared/ui';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { environment } from '@video-env/environment';
import {
  Logger,
  LoggerService,
  BrowserDetectionService,
  SharedUtilModule,
  SentryLoggerService,
  SentryService,
} from '@redngapps/shared/util';
import { HeaderNavigationComponent } from './header-navigation/header-navigation.component';
import { ParticipantListComponent, VideosprechstundeUiModule } from '@redngapps/videosprechstunde/ui';

import { TurnConfirmDialogComponent } from './turn-confirm-dialog/turn-confirm-dialog.component';
import { HangUpDialogComponent } from './hang-up-dialog/hang-up-dialog.component';
import { ConferenceSidenavComponent } from './conference-sidenav/conference-sidenav.component';
import { VideoComponent } from './video/video.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ParticipantSmallVideosComponent } from './participant-small-videos/participant-small-videos.component';
import { SharingNotAuthorizedDialogComponent } from './sharing-not-authorized-dialog/sharing-not-authorized-dialog.component';
import { ScreenSharingPlaceholderComponent } from './screen-sharing-placeholder/screen-sharing-placeholder.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SettingsComponent } from './settings/settings.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AudioSettingsComponent } from './settings/audio-settings/audio-settings.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { VideoSettingsComponent } from './settings/video-settings/video-settings.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedComponentsUiModule } from '@redngapps/shared/components/ui';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { FaqComponent } from './faq/faq.component';
import { VssErrorMonitoringLoggerService } from './services/logging/core/vss-error-monitoring-logger.service';
import { VssSentryErrorHandler } from './services/sentry/vss-sentry-error-handler.service';
import { VSSSentryService } from './services/sentry/vss-sentry.service';
import { MatListModule } from '@angular/material/list';
import { SidenavContentDirective } from './conference-sidenav/sidenav-content.directive';
import { UpgradeInfoComponent } from './components/upgrade-info/upgrade-info.component';
import { DataPrivacyStatementComponent } from './components/data-privacy-statement/data-privacy-statement.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TextMaskModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatInputModule,
    MatDialogModule,
    MatExpansionModule,
    MatSidenavModule,
    MatSnackBarModule,
    SweetAlert2Module.forRoot(),
    DeviceDetectorModule.forRoot(),
    SharedUtilModule,
    SharedUiModule,
    SharedComponentsUiModule,
    RedTooltipModule,
    VideosprechstundeUiModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatSelectModule,
    MatProgressBarModule,
    MatListModule,
    RouterModule,
  ],
  declarations: [
    LoginComponent,
    ConferenceComponent,
    TermsAndConditionsComponent,
    ImprintComponent,
    HeaderNavigationComponent,
    TurnConfirmDialogComponent,
    HangUpDialogComponent,
    ConferenceSidenavComponent,
    VideoComponent,
    SharingNotAuthorizedDialogComponent,
    ParticipantSmallVideosComponent,
    UnsupportedBrowserComponent,
    ScreenSharingPlaceholderComponent,
    FaqComponent,
    SettingsComponent,
    AudioSettingsComponent,
    VideoSettingsComponent,
    SidenavContentDirective,
    UpgradeInfoComponent,
    DataPrivacyStatementComponent,
  ],
  providers: [
    {
      provide: Logger,
      useClass: environment.featureSwitches.sentry ? VssErrorMonitoringLoggerService : LoggerService,
    },
    environment.featureSwitches.sentry ? VSSSentryService : [],
    environment.featureSwitches.sentry ? [SentryLoggerService, VssSentryErrorHandler] : [],
    {
      provide: SentryService,
      useClass: VSSSentryService,
    },
    BrowserDetectionService,
  ],
  entryComponents: [
    TurnConfirmDialogComponent,
    HangUpDialogComponent,
    SharingNotAuthorizedDialogComponent,
    ScreenSharingPlaceholderComponent,
    SettingsComponent,
    ParticipantListComponent,
  ],
  exports: [UpgradeInfoComponent],
})
export class VideosprechstundeFeatureModule {}
