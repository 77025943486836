import { Pipe, PipeTransform } from '@angular/core';
import { Router, UrlSegmentGroup, UrlSegment } from '@angular/router';
import { flatten } from 'lodash';

/**
 * Transforms a url into an array of router commands.
 */
@Pipe({ name: 'routerCommands', pure: true })
export class RouterCommandsPipe implements PipeTransform {
  constructor(private router: Router) {}

  transform(value: string) {
    const urlTree = this.router.parseUrl(value);
    return ['/', ...serializeSegmentGroup(urlTree.root)];
  }
}

function serializeSegmentGroup(group: UrlSegmentGroup) {
  const children = Object.entries(group.children);

  if (children.length > 0) {
    return [
      ...flatten(group.segments.map(serializeUrlSegments)),
      {
        outlets: Object.entries(group.children).reduce((acc, [outletName, g]) => {
          acc[outletName] = serializeSegmentGroup(g);
          return acc;
        }, {}),
      },
    ];
  }
  return flatten(group.segments.map(serializeUrlSegments));
}

function serializeUrlSegments(segment: UrlSegment) {
  const result: (string | UrlSegment['parameters'])[] = [segment.path];

  if (segment.parameterMap.keys.length > 0) {
    result.push(segment.parameters);
  }
  return result;
}
