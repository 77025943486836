// this is a way to access our breakpoints programmatically when not being able to use media queries only
// when adjusting these, _breakpoints.scss has also to be adjusted

export class Breakpoints {
  static readonly XS_AND_DOWN = '(max-width: 575.98px)';
  static readonly S_AND_DOwN = '(max-width: 767.98px)';
  static readonly M_AND_DOWN = '(max-width: 991.98px)';
  static readonly L_AND_DOWN = '(max-width: 1199.98px)';
  static readonly XXL_AND_DOWN = '(max-width: 1599.98px)';

  static readonly S_ONLY = '(min-width: 576px) and (max-width: 767.98px)';
  static readonly M_ONLY = '(min-width: 768px) and (max-width: 991.98px)';
  static readonly L_ONLY = '(min-width: 992px) and (max-width: 1199.98px)';

  static readonly XS_AND_UP = '(min-width: 360px)';
  static readonly S_AND_UP = '(min-width: 576px)';
  static readonly M_AND_UP = '(min-width: 768px)';
  static readonly L_AND_UP = '(min-width: 992px)';
  static readonly XL_AND_UP = '(min-width: 1200px)';
}
