import { Injectable } from '@angular/core';
import { Router, UrlTree, CanActivateChild } from '@angular/router';

import { BROWSERS, DeviceDetectorService, OS } from 'ngx-device-detector';
import { BrowserDetectionService } from '@redngapps/shared/util';

@Injectable({ providedIn: 'root' })
export class UnsupportedBrowserGuard implements CanActivateChild {
  constructor(
    private browserDetectionService: BrowserDetectionService,
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) {}

  canActivateChild(): boolean | UrlTree {
    const deviceInfo = this.deviceService.getDeviceInfo();
    const isMobileApple =
      [OS.MAC, OS.IOS].includes(deviceInfo.os) && 'ontouchstart' in window && 'onorientationchange' in window;

    const tree = this.router.parseUrl('/unsupported-browser');

    // we only support windows os with 64 bit! Other os is generally supported
    if (
      ![BROWSERS.SAFARI, BROWSERS.CHROME, BROWSERS.FIREFOX, BROWSERS.SAMSUNG, BROWSERS.MS_EDGE_CHROMIUM].includes(
        deviceInfo.browser,
      )
    ) {
      return tree;
    }

    if (isMobileApple && deviceInfo.browser !== BROWSERS.SAFARI) {
      return this.router.parseUrl('/iOS-unsupported');
    }
    // for non apple devices disallow safari
    if (deviceInfo.browser === BROWSERS.SAFARI && ![OS.MAC, OS.IOS].includes(deviceInfo.os)) {
      return this.router.parseUrl('/unsupported-safari');
    }

    if (this.browserDetectionService.isUserBrowserSupportedAndOutdatedVersion()) {
      return this.router.parseUrl('/unsupported-browser-version');
    }

    return true;
  }
}
