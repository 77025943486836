import { Injectable } from '@angular/core';
import { MediaService } from './media.service';
import { AudioAnalyseService } from './audio-analyse.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable, interval } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';

@Injectable({
  providedIn: 'root',
})
export class AudioSettingsService {
  constructor(private mediaService: MediaService, private analyserService: AudioAnalyseService) {}
  /**
   * Plays a sound on the device with provided deviceId. If setting the deviceId isn't supported,
   * plays on a default device.
   * @example
   * const audio = new Audio('/assets/sounds/sound.vaw');
   * audioSettings.playSound('123', audio);
   * @param deviceId
   * @param audio
   */
  async playTestSound(deviceId: string | null | undefined, audio: HTMLAudioElement): Promise<void> {
    // @ts-ignore
    if (audio.setSinkId && deviceId) {
      // @ts-ignore
      await audio.setSinkId(deviceId);
    }
    return audio.play();
  }

  /**
   * Gets microphone volume and updates the value periodically with provided interval.
   * @param deviceId
   * @param checkInterval
   */
  getVolume(deviceId: string, checkInterval = 150): Observable<number> {
    return from(this.mediaService.getLocalUserMediaStreamByDeviceId('audioinput', deviceId)).pipe(
      map((stream: MediaStream) => this.analyserService.createAnalyser(stream)),
      switchMap((analyser: AnalyserNode) =>
        interval(checkInterval).pipe(map(() => this.analyserService.getStreamVolume(analyser))),
      ),
    );
  }
}
