import { Component, ChangeDetectionStrategy } from '@angular/core';
import { environment } from '@video-env/environment';

@Component({
  selector: 'red-upgrade-info',
  templateUrl: './upgrade-info.component.html',
  styleUrls: ['./upgrade-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeInfoComponent {
  featureList: string[];

  constructor() {
    this.featureList = [
      'Einladung per integrierter E-Mail',
      'Einladung per SMS',
      'persönlicher Telefon-Support',
      'persönlicher E-Mail-Support',
      'beliebige viele weitere Nutzer',
      'Bildschirmübertragung',
      'Konferenzen mit mehreren Teilnehmern',
    ];
  }

  onUpgrade() {
    // Since "connect" can only have 1 user, which is admin, it has access to "stammdaten/rechnungsdaten/vertrag".
    window.location.href = `${environment.urls.redClient}#/stammdaten/rechnungsdaten/vertrag`;
  }
}
