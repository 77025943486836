import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedComponentsUiModule } from '@redngapps/shared/components/ui';

import { WorkflowComponent } from './workflow.component';

@NgModule({
  imports: [SharedComponentsUiModule, CommonModule],
  declarations: [WorkflowComponent],
  exports: [WorkflowComponent],
})
export class WorkflowModule {}
