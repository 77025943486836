import { Injectable } from '@angular/core';
import { UrlTree, Router, CanActivateChild } from '@angular/router';
import { MediaService } from '@redngapps/videosprechstunde/feature';
import { DeviceErrorType } from '@redngapps/videosprechstunde/types';

@Injectable({ providedIn: 'root' })
export class DevicesUnavailableGuard implements CanActivateChild {
  constructor(private mediaService: MediaService, private router: Router) {}

  async canActivateChild(): Promise<boolean | UrlTree> {
    const deviceError = await this.mediaService.checkIODevices();

    if (deviceError === DeviceErrorType.NOT_FOUND) {
      return this.router.parseUrl('/devices-not-found');
    }

    if (deviceError === DeviceErrorType.ERROR || deviceError === DeviceErrorType.NOT_ALLOWED_BY_SYSTEM) {
      return this.router.parseUrl('/devices-error');
    }

    return true;
  }
}
