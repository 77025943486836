import { Injectable } from '@angular/core';
import { UrlTree, Router, CanActivate } from '@angular/router';

import { WebRtcService } from '@redngapps/videosprechstunde/feature';

@Injectable({ providedIn: 'root' })
export class WebRTCConnectedGuard implements CanActivate {
  constructor(
    private webRtcService: WebRtcService,
    private router: Router,
  ) { }

  canActivate(): boolean | UrlTree {
    if (!this.webRtcService.isConnected()) {
      return this.router.parseUrl('/login');
    }
    return true;
  }
}
