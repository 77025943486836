import { NgModule } from '@angular/core';
import { CompareToFormValueComponent } from './compare-to-form-value.component';
import { CommonModule } from '@angular/common';
import { CompareToFormHintDirective, CompareToHintMarkerDirective } from './compare-to-form-hint.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [CompareToFormValueComponent, CompareToFormHintDirective, CompareToHintMarkerDirective],
  exports: [CompareToFormValueComponent, CompareToFormHintDirective, CompareToHintMarkerDirective],
})
export class FormComparisonModule {}
