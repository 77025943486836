import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { SubformDirective } from './directives/subform-name.directive';
import { SubformArrayNameDirective } from './directives/subform-array-name.directive';
import { SubformArrayIndexDirective } from './directives/subform-array-index.directive';

@NgModule({
  imports: [ReactiveFormsModule, PortalModule],
  exports: [SubformDirective, SubformArrayNameDirective, SubformArrayIndexDirective],
  declarations: [SubformDirective, SubformArrayNameDirective, SubformArrayIndexDirective],
})
export class RedSubformModule {}
