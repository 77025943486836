import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({ name: 'routerQueryParams', pure: true })
export class RouterQueryParamsPipe implements PipeTransform {
  constructor(private router: Router) {}

  transform(value: string) {
    const urlTree = this.router.parseUrl(value);
    return urlTree.queryParams;
  }
}
