import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IAudioSettings, ICallSettings, IVideoSettings } from './call-settings';
import idx from 'idx';
import { MediaService } from '../services/media.service';
import { Observable, from } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IUserMediaDevices } from '@redngapps/videosprechstunde/types';
import { uniqBy } from 'lodash';

@Component({
  selector: 'red-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnChanges, OnInit {
  /**
   * Initial settings to show.
   */
  @Input() settings: ICallSettings;
  @Output() settingsChange = new EventEmitter<ICallSettings>();

  audioSettings: IAudioSettings;
  videoSettings: IVideoSettings;

  cameras$: Observable<MediaDeviceInfo[]>;

  audioInputSources$: Observable<MediaDeviceInfo[]>;
  audioOutputSources$: Observable<MediaDeviceInfo[]>;

  constructor(private mediaService: MediaService) {}

  ngOnInit() {
    const devices$: Observable<IUserMediaDevices> = from(this.mediaService.getUserMediaDevices()).pipe(shareReplay(3));
    this.cameras$ = devices$.pipe(map(devices => devices.videoInput));
    this.audioInputSources$ = devices$.pipe(
      map(devices => {
        const isGroupIdEmpty = devices.audioInput.some(audioInput => audioInput.groupId === '');
        if (isGroupIdEmpty) {
          return devices.audioInput;
        }
        return uniqBy(devices.audioInput, 'groupId');
      }),
    );
    this.audioOutputSources$ = devices$.pipe(map(devices => devices.audioOutput));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (idx(changes, _ => _.settings.currentValue)) {
      this.setSettings(changes.settings.currentValue);
    }
  }

  setSettings(settings: ICallSettings): void {
    this.settings = settings;
    if (idx(this.settings, _ => _.audio)) {
      this.audioSettings = this.settings.audio;
    }

    if (idx(this.settings, _ => _.video)) {
      this.videoSettings = this.settings.video;
    }
  }

  onAudioChange(event: IAudioSettings): void {
    this.settingsChange.emit({
      audio: event,
      video: this.videoSettings,
    });
  }

  onVideoChange(event: IVideoSettings): void {
    this.settingsChange.emit({
      audio: this.audioSettings,
      video: event,
    });
  }
}
