/*
 *  Header for files which are sent to the server.
 *  The Server expects a 'header' for a specific file type. The header should be place before the
 *  the content.
 *  e.g. file: ServerFileHeader + Base64String
 */
export enum ServerFileHeader {
  imgBase64 = 'data:image/jpeg;base64,',
  zipBase64 = 'data:application/zip;base64,',
}
