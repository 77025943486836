import { trigger, transition, style, animate, keyframes } from '@angular/animations';

export const flyInAnimation = trigger('flyInAnimation', [
  transition(':enter', [
    style({
      transform: 'translateX(-500px)',
    }),
    animate(
      '1500ms ease-out',
      keyframes([
        style({ transform: 'translateX(-500px)', offset: 0.5 }), // simulate 750ms delay
        style({ transform: 'translateX(0px)', offset: 1 }),
      ]),
    ),
  ]),
]);
