import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [MatIconModule],
})
export class RedCustomIconsModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconInNamespace(
      'red',
      'arv',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/icons/arv.svg'),
    );
  }
}
