import { NgZone, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { ScrollStrategy, RepositionScrollStrategyConfig, OverlayRef } from '@angular/cdk/overlay';

/**
 * Config options for the RepositionScrollStrategy.
 */
export interface RepositionScrollableScrollStrategyConfig extends RepositionScrollStrategyConfig {
  /** The trigger element */
  triggerElement: ElementRef;
}

/**
 * Strategy that will update the element position as the user is scrolling.
 */
export class RepositionScrollableScrollStrategy implements ScrollStrategy {
  private _scrollSubscription: Subscription | null = null;
  private _overlayRef: OverlayRef;

  constructor(
    private _scrollDispatcher: ScrollDispatcher,
    private _viewportRuler: ViewportRuler,
    private _ngZone: NgZone,
    private _config: RepositionScrollableScrollStrategyConfig,
  ) {}

  /** Attaches this scroll strategy to an overlay. */
  attach(overlayRef: OverlayRef) {
    if (this._overlayRef) {
      throw new Error('Overlay already attached');
    }

    this._overlayRef = overlayRef;
  }

  /** Enables repositioning of the attached overlay on scroll. */
  enable() {
    if (!this._scrollSubscription) {
      const throttle = this._config.scrollThrottle || 0;

      const ancestorScrollContainers = this._scrollDispatcher.getAncestorScrollContainers(this._config.triggerElement);

      this._scrollSubscription = this._scrollDispatcher.scrolled(throttle).subscribe(() => {
        this._overlayRef.updatePosition();

        // TODO(crisbeto): make `close` on by default once all components can handle it.
        if (this._config.autoClose) {
          const overlayRect = this._config.triggerElement.nativeElement.getBoundingClientRect();
          const { width, height } = this._viewportRuler.getViewportSize();

          const parentRects = [
            { width, height, bottom: height, right: width, top: 0, left: 0 },
            ...ancestorScrollContainers.map(container =>
              container.getElementRef().nativeElement.getBoundingClientRect(),
            ),
          ];

          if (isElementScrolledOutsideView(overlayRect, parentRects)) {
            this.disable();
            this._ngZone.run(() => this._overlayRef.detach());
          }
        }
      });
    }
  }

  /** Disables repositioning of the attached overlay on scroll. */
  disable() {
    if (this._scrollSubscription) {
      this._scrollSubscription.unsubscribe();
      this._scrollSubscription = null;
    }
  }
}

/**
 * Gets whether an element is scrolled outside of view by any of its parent scrolling containers.
 * @param element Dimensions of the element (from getBoundingClientRect)
 * @param scrollContainers Dimensions of element's scrolling containers (from getBoundingClientRect)
 * @returns Whether the element is scrolled out of view
 * @docs-private
 */
export function isElementScrolledOutsideView(element: ClientRect, scrollContainers: ClientRect[]) {
  return scrollContainers.some(containerBounds => {
    const outsideAbove = element.bottom < containerBounds.top;
    const outsideBelow = element.top > containerBounds.bottom;
    const outsideLeft = element.right < containerBounds.left;
    const outsideRight = element.left > containerBounds.right;

    return outsideAbove || outsideBelow || outsideLeft || outsideRight;
  });
}
