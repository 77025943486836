import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfluenceService } from '@redngapps/shared/util';
import { IWikiPageContent } from '@redngapps/shared/types';
import { take } from 'rxjs/operators';

@Component({
  selector: 'red-wiki-page-renderer',
  templateUrl: './wiki-page-renderer.component.html',
  styleUrls: ['./wiki-page-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiPageRendererComponent implements OnChanges {
  @Input() wikiPageId: number;
  content: IWikiPageContent;

  constructor(private confluenceService: ConfluenceService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.wikiPageId.currentValue) {
      this.loadContent(changes.wikiPageId.currentValue);
    } else {
      this.content = undefined;
      this.changeDetectorRef.detectChanges();
    }
  }

  private loadContent(pageId: number): void {
    this.confluenceService
      .getPageContent(pageId)
      .pipe(take(1))
      .subscribe(content => {
        this.content = content;
        this.changeDetectorRef.detectChanges();
      });
  }
}
