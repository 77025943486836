import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceErrorType } from '@redngapps/videosprechstunde/types';
import idx from 'idx';
import { BehaviorSubject } from 'rxjs';
import { MediaService } from '../../services/media.service';
import { WebRtcService } from '../../services/web-rtc.service';

@Component({
  selector: 'red-data-privacy-statement',
  templateUrl: './data-privacy-statement.component.html',
  styleUrls: ['./data-privacy-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPrivacyStatementComponent {
  form: FormGroup;
  deviceError = new BehaviorSubject<DeviceErrorType>(undefined);

  constructor(private webRtcService: WebRtcService, private router: Router, private mediaService: MediaService) {}

  async onAccept() {
    try {
      await this.webRtcService.initLocalStream();

      const webRTCEvent = idx(window, x => x.history.state.event);
      this.webRtcService.setConnected(true);
      this.webRtcService.roomTitle.next(webRTCEvent ? webRTCEvent.roomTitle : undefined);

      this.router.navigate(['/conference']);
    } catch (e) {
      this.deviceError.next(this.mediaService.mapDeviceErrors(e));
    }
  }

  onCancel() {
    this.router.navigate(['/login']);
  }
}
