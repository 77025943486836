import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConferenceInstancesService {
  private CONFERENCE_OPEN_STORAGE_ITEM_NAME = 'conferenceOpen';

  isAConferenceRunning(): boolean {
    const item = localStorage.getItem(this.CONFERENCE_OPEN_STORAGE_ITEM_NAME);
    return item && item === 'true';
  }

  saveConferenceIsOpen(): void {
    localStorage.setItem(this.CONFERENCE_OPEN_STORAGE_ITEM_NAME, 'true');
  }

  removeConferenceIsOpen(): void {
    localStorage.setItem(this.CONFERENCE_OPEN_STORAGE_ITEM_NAME, 'false');
  }
}
