import { LogSafeValueImpl, LogSafeValue, LoggerString } from '../services/logging/anonymized-logger.models';
import { pick as _pick } from 'lodash';
/**
 * Use this on simple variables you don't want to be censored by 'XXX'
 *
 * **Example**
 * ```
 * let name = "Mike";
 * let age = 28;
 *
 * logMessage`This is safe to log ${logSafe(name)} and this is not ${age}`
 * ```
 * Output: This is safe to log Mike and this is not XXX
 */
export function logSafe(value: unknown): LogSafeValueImpl {
  return new LogSafeValueImpl(value);
}

/**
 * Use this method to whitelist properites of an object you want to be logged.
 * Everything else will be omitted. So if nothing is whitelisted only an empty object '{}' will be logged.
 *
 * **Example**
 * ```
 * let foo = {
 *   name: { first: "Mike", last: "Joe", secret: "secret" },
 *   age: 4
 * };
 * let age = 28;
 *
 * logMessage`This is safe to log ${logSafeObject(foo, "name.first", "name.last")} and this is not ${age}`
 * ```
 * Output: This is safe to log {"name":{"first":"Mike","last":"Joe"}} and this is not XXX
 */
export function logSafeObject(value: unknown, ...whitelist: string[]): LogSafeValueImpl {
  if (typeof value === 'object') {
    const whitelistedObject = _pick(value, whitelist);
    return new LogSafeValueImpl(whitelistedObject);
  }
  return new LogSafeValueImpl(value);
}

/**
 * Use this message to create a `LoggerString`.
 *
 * In a `LoggerString` every interpolation, which is not marked as save to log, will be censored.
 * To mark interpolations as save use the helper methods `logSafe` and `logSafeObject`.
 *
 * **Example**
 * ```
 * let name = "Mike";
 * let age = 28;
 *
 * logMessage`This is safe to log ${logSafe(name)} and this is not ${age}`
 * ```
 * Output: This is safe to log Mike and this is not XXX
 */
export function logMessage(strings: TemplateStringsArray, ...interpolations: (unknown | LogSafeValue)[]): LoggerString {
  const result = [];
  for (let i = 0; i < strings.length; i++) {
    result.push(strings[i]);

    if (i < interpolations.length) {
      const interpolation = getMaskedValue(interpolations[i]);
      if (typeof interpolation === 'object') {
        if (interpolation instanceof Error) {
          try {
            result.push(interpolation.stack || interpolation.toString());
          } catch (e) {
            result.push('[unserializable error]');
          }
        } else {
          try {
            result.push(JSON.stringify(interpolation));
          } catch (e) {
            result.push('[circular object]');
          }
        }
      } else {
        result.push(interpolation);
      }
    }
  }

  return new LoggerString(result.join(''));
}

export function isLogSafeValue(value: any): value is LogSafeValue {
  return value instanceof LogSafeValueImpl;
}

export function getLogValue(value: LogSafeValue): unknown {
  if (isLogSafeValue(value)) {
    return (value as LogSafeValueImpl).value;
  }
  throw new Error('Provided value must be of type LogSafeValue');
}

function getMaskedValue(value: unknown | LogSafeValue): unknown {
  if (isLogSafeValue(value)) {
    return getLogValue(value);
  }
  return 'XXX';
}
