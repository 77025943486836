import { RouterQueryParamsPipe } from './pipes/router-query-params.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserDetectionService } from './services/browser-detection.service';
import { ConfluenceService } from './services/confluence.service';
import { ZipService } from './services/zip.service';
import { DownloadService } from './services/download.service';
import { E2eIdDirective } from './directives/e2e-id.directive';
import { LetDirective } from './directives/let.directive';
import { RouterCommandsPipe } from './pipes/router-commands.pipe';
import { HasRequiredValidatorPipe } from './pipes/has-required-validator.pipe';
import { CarouselDirective } from './directives/carousel.directive';
import { QuarterDatePipe } from './pipes/quarter-date.pipe';

@NgModule({
  imports: [CommonModule],
  providers: [BrowserDetectionService, ConfluenceService, ZipService, DownloadService],
  declarations: [
    E2eIdDirective,
    LetDirective,
    CarouselDirective,
    RouterCommandsPipe,
    RouterQueryParamsPipe,
    HasRequiredValidatorPipe,
    QuarterDatePipe,
  ],
  exports: [
    E2eIdDirective,
    LetDirective,
    CarouselDirective,
    RouterCommandsPipe,
    RouterQueryParamsPipe,
    HasRequiredValidatorPipe,
    QuarterDatePipe,
  ],
})
export class SharedUtilModule {}
