import { Component, Input } from '@angular/core';
import { ParticipantInfo } from '@redngapps/videosprechstunde/types';

@Component({
  selector: 'red-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss'],
})
export class ParticipantListComponent {
  @Input() participants: ParticipantInfo[] = [];
}
