import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ComponentFactoryResolver,
  OnDestroy,
  ComponentRef,
  Type,
  OnInit,
} from '@angular/core';
import { CameraFacingMode, ParticipantInfo } from '@redngapps/videosprechstunde/types';
import { ICallSettings } from '../settings/call-settings';
import { SidenavContentDirective } from './sidenav-content.directive';
import { SettingsComponent } from '../settings/settings.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ParticipantListComponent } from '@redngapps/videosprechstunde/ui';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'red-conference-sidenav',
  templateUrl: './conference-sidenav.component.html',
  styleUrls: ['./conference-sidenav.component.scss'],
})
export class ConferenceSidenavComponent implements OnInit, OnDestroy {
  @Input() participants: ParticipantInfo[] = [];
  @Input() settings: ICallSettings;

  @Output() closeClicked = new EventEmitter<void>();
  @Output() settingsChange = new EventEmitter<ICallSettings>();

  @ViewChild(SidenavContentDirective, { static: true }) contentDirective: SidenavContentDirective;

  showEntryList = true;

  showSettingsMenuEntry = true;

  private entryChange$ = new Subject<void>();
  private mobileCameraFacingMode: CameraFacingMode = 'user';

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private deviceDetector: DeviceDetectorService,
  ) {}

  ngOnInit() {
    this.showSettingsMenuEntry = this.deviceDetector.isDesktop();
  }

  ngOnDestroy() {
    this.entryChange$.next();
    this.entryChange$.complete();
  }

  onCloseClick(): void {
    this.closeClicked.emit();
    this.resetState();
  }

  onBackClick(): void {
    this.resetState();
  }

  onParticipantsClick(): void {
    this.showEntryList = false;
    this.entryChange$.next();

    const componentRef = this.setupEntry(ParticipantListComponent);
    componentRef.instance.participants = this.participants;
  }

  onSettingsClick(): void {
    this.showEntryList = false;
    this.entryChange$.next();

    const componentRef = this.setupEntry(SettingsComponent);
    componentRef.instance.setSettings(this.settings);
    componentRef.instance.settingsChange
      .pipe(takeUntil(this.entryChange$))
      .subscribe((settings: ICallSettings) => this.settingsChange.emit(settings));
  }

  onSwitchCameraClick(): void {
    this.switchCameraFacingMode();
    if (this.settings) {
      this.settings.video = { facingMode: this.mobileCameraFacingMode };
    } else {
      this.settings = { video: { facingMode: this.mobileCameraFacingMode } };
    }

    this.settingsChange.emit(this.settings);
  }

  /**
   * Dynamically renders component into a view container.
   * @returns a reference to a component so inputs/outputs can be set up.
   * @param component
   * @private
   */
  private setupEntry<T>(component: Type<T>): ComponentRef<T> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const containerRef = this.contentDirective.viewContainerRef;
    containerRef.clear();

    return containerRef.createComponent<T>(factory);
  }

  /**
   * Executed on close to reset the sidenav to original state.
   * @private
   */
  private resetState(): void {
    this.entryChange$.next();
    this.contentDirective.viewContainerRef.clear();
    this.showEntryList = true;
  }

  /**
   * Switch the camera facing mode setting between front and back facing cameras.
   * @private
   */
  private switchCameraFacingMode(): void {
    if (this.mobileCameraFacingMode === 'environment') {
      this.mobileCameraFacingMode = 'user';
    } else {
      this.mobileCameraFacingMode = 'environment';
    }
  }
}
