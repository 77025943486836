import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface CarouselContext<T> {
  redCarousel: T[];
  $implicit?: T;
  selectedIndex: number;
  elementCount: number;
  controller: {
    next: () => void;
    prev: () => void;
  };
}

@Directive({
  selector: '[redCarousel]',
})
export class CarouselDirective<T> {
  private context: CarouselContext<T> = {
    redCarousel: [],
    $implicit: undefined,
    selectedIndex: 0,
    elementCount: 0,
    controller: {
      next: () => this.next(),
      prev: () => this.prev(),
    },
  };

  private current = 0;

  @Input()
  set redCarousel(elements: T[]) {
    this.context.redCarousel = elements;
    this.updateContext();
  }

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<CarouselContext<T>>) {
    this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }

  next() {
    this.current++;
    if (this.current >= this.context.redCarousel.length) {
      this.current = 0;
    }
    this.updateContext();
  }

  prev() {
    this.current--;
    if (this.current < 0) {
      this.current = this.context.redCarousel.length - 1;
    }
    this.updateContext();
  }

  private updateContext() {
    this.context.$implicit = this.context.redCarousel[this.current];
    this.context.selectedIndex = this.current;
    this.context.elementCount = this.context.redCarousel.length;
  }
}
