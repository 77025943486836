import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IVideoSettings } from '../call-settings';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'red-video-settings',
  templateUrl: './video-settings.component.html',
  styleUrls: ['./video-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoSettingsComponent {
  @Input() cameras: MediaDeviceInfo[];

  /**
   * Initial video settings.
   */
  @Input() settings: IVideoSettings;
  @Output() change = new EventEmitter<IVideoSettings>();

  onCameraChange(event: MatSelectChange): void {
    if (this.settings) {
      this.settings.input = event.value;
    } else {
      this.settings = { input: event.value };
    }

    this.change.emit(this.settings);
  }
}
