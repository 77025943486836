import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { fadeInAndOutAnimation } from '@redngapps/videosprechstunde/ui';
import { Logger, logMessage } from '@redngapps/shared/util';
import idx from 'idx';

@Component({
  selector: 'red-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  animations: [fadeInAndOutAnimation],
})
export class VideoComponent implements OnChanges, AfterViewInit {
  @ViewChild('video', { static: false }) videoElementRef: ElementRef<HTMLVideoElement>;

  @Input() stream: MediaStream;
  @Input() muted = false;
  @Input() cameraOff = false;
  @Input() small = false;
  @Input() showGradient = true;
  @Input() audioOutputDeviceId: string;

  @Output() toggleConnectionInfo = new EventEmitter<boolean>();

  constructor(private logger: Logger) {}

  ngOnChanges(changes: SimpleChanges): void {
    const newAudioOutputDeviceId = idx(changes, _ => _.audioOutputDeviceId.currentValue);
    if (newAudioOutputDeviceId && this.videoElementRef) {
      const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;
      this.changeAudioOutput(videoElement, newAudioOutputDeviceId);
    }
  }

  ngAfterViewInit(): void {
    if (!this.small) {
      this.videoElementRef.nativeElement.addEventListener('loadstart', () => {
        this.toggleConnectionInfo.emit(true);
      });
      this.videoElementRef.nativeElement.addEventListener('playing', () => {
        this.toggleConnectionInfo.emit(false);
      });
    }
  }

  /**
   * Change the audio output of the video element to the device with id audioOutputDeviceId.
   * @param video
   * @param audioOutputDeviceId
   */
  private changeAudioOutput(video: HTMLVideoElement, audioOutputDeviceId: string): void {
    if (video && !!video['setSinkId']) {
      video
        // @ts-ignore 'setSinkId' is experimental and not supported in all browsers.
        .setSinkId(audioOutputDeviceId)
        .catch(e => this.logger.error(logMessage`Failed to set audio output sinkId`, e));
    } else {
      this.logger.warn(logMessage`setSinkId isn't supported`);
    }
  }
}
