export interface ChipCategory {
  name: string;
  color: string;
  text: string;
}

const FORMULARE_COLOR = '#B0B3BC';
const ALLE_COLOR = '#272D40';
const MEDIKAMENTE_COLOR = '#9B92F7';
const KOSTEN_UND_EPISODEN_COLOR = '#3241B4';
const LEISTUNG_COLOR = '#00BFC6';
const DIAGNOSE_COLOR = '#10E3EB';

export class ChipCategories {
  static readonly Default: ChipCategory = { name: '', text: '', color: 'rgba(0, 0, 0, .87)' };

  static readonly Alle: ChipCategory = { name: 'Alle', text: 'A', color: ALLE_COLOR };
  static readonly Patienten: ChipCategory = { name: 'Patienten', text: 'P', color: FORMULARE_COLOR };
  static readonly Formulare: ChipCategory = { name: 'Formulare', text: 'F', color: FORMULARE_COLOR };

  // Administrative Daten Diagnosen (Suche, Auswahl, Listen)
  static readonly Diagnosen: ChipCategory = { name: 'Diagnosen', text: 'D', color: DIAGNOSE_COLOR };
  static readonly EigeneDiagnosen: ChipCategory = { name: 'Eigene Diagnosen', text: 'Dx', color: DIAGNOSE_COLOR };

  // Administrative Daten Leistungen (Suche, Auswahl, Listen)
  static readonly OMIMStammdatei: ChipCategory = { name: 'OMIM-Stammdatei', text: 'Lm', color: LEISTUNG_COLOR };
  static readonly OPSStammdatei: ChipCategory = { name: 'OPS-Stammdatei', text: 'Lo', color: LEISTUNG_COLOR };
  static readonly EigeneEBMLeistungen: ChipCategory = {
    name: 'eigene EBM Leistungen',
    text: 'Lk',
    color: LEISTUNG_COLOR,
  };
  static readonly EigeneGOAELeistungen: ChipCategory = {
    name: 'eigene GOÄ Leistungen',
    text: 'Lp',
    color: LEISTUNG_COLOR,
  };
  static readonly Leistungen: ChipCategory = { name: 'Leistungen', text: 'L', color: LEISTUNG_COLOR };
  static readonly EBMLeistungen: ChipCategory = { name: 'EBM-Leistungen', text: 'Lk', color: LEISTUNG_COLOR };
  static readonly GOAELeistungen: ChipCategory = { name: 'GOÄ-Leistungen', text: 'Lp', color: LEISTUNG_COLOR };
  static readonly UVLeistungen: ChipCategory = { name: 'UV-Leistungen', text: 'Lu', color: LEISTUNG_COLOR };

  // Administrative Daten Episoden (Auswahl, Listen)
  static readonly Episodentypen: ChipCategory = { name: 'Episodentypen', text: 'E', color: KOSTEN_UND_EPISODEN_COLOR };
  static readonly Betriebsstaetten: ChipCategory = {
    name: 'Betriebsstätten',
    text: 'Bs',
    color: KOSTEN_UND_EPISODEN_COLOR,
  };
  static readonly Privatliquidationseinheiten: ChipCategory = {
    name: 'Privatliquidationseinheiten',
    text: 'Pl',
    color: KOSTEN_UND_EPISODEN_COLOR,
  };
  static readonly EpisodeGesetztlich: ChipCategory = {
    name: 'Episode gesetztlich',
    text: 'Ek',
    color: KOSTEN_UND_EPISODEN_COLOR,
  };
  static readonly EpisodePrivat: ChipCategory = {
    name: 'Episode privat',
    text: 'Ep',
    color: KOSTEN_UND_EPISODEN_COLOR,
  };

  // Administrative Daten Kostenträger (Kostenträgersuche, Auswahl)
  static readonly KostentraegerStammdatei: ChipCategory = {
    name: 'Kostenträger aus Stammdatei',
    text: 'K',
    color: KOSTEN_UND_EPISODEN_COLOR,
  };
  static readonly TemporaereKostentraeger: ChipCategory = {
    name: 'Temporäre Kostenträger',
    text: 'Kx',
    color: KOSTEN_UND_EPISODEN_COLOR,
  };
  static readonly KostentraegerUnfallversicherung: ChipCategory = {
    name: 'Kostenträger der Unfallversicherung',
    text: 'Kb',
    color: KOSTEN_UND_EPISODEN_COLOR,
  };

  // Administrativ/Klinischer Bereich Arzneimittelverordnung (Suche, Auswahl, Listen)
  static readonly Medikamente: ChipCategory = { name: 'Medikamente', text: 'M', color: MEDIKAMENTE_COLOR };
  static readonly Wirkstoff: ChipCategory = { name: 'Wirkstoff', text: 'Mw', color: MEDIKAMENTE_COLOR };
  static readonly Hersteller: ChipCategory = { name: 'Hersteller', text: 'Mh', color: MEDIKAMENTE_COLOR };
  static readonly Anbieter: ChipCategory = { name: 'Anbieter', text: 'Ma', color: MEDIKAMENTE_COLOR };
  static readonly Atc: ChipCategory = { name: 'Atc', text: 'Mc', color: MEDIKAMENTE_COLOR };
  static readonly IwwListen: ChipCategory = { name: 'Iww-Listen', text: 'Iw', color: MEDIKAMENTE_COLOR };
  static readonly Rezept: ChipCategory = { name: 'Rezept', text: 'R', color: MEDIKAMENTE_COLOR };
}
