import { AfterViewInit, Component, OnDestroy, ViewChild, OnInit, HostListener } from '@angular/core';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { WebRtcService } from '../services/web-rtc.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TurnConfirmDialogComponent, TurnDialogAnswers } from '../turn-confirm-dialog/turn-confirm-dialog.component';
import { MediaService } from '../services/media.service';
import { SignalingService } from '../services/signaling.service';
import { IRedRTCEvent, ParticipantInfo } from '@redngapps/videosprechstunde/types';
import { takeUntil, take, shareReplay, map, withLatestFrom } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HangUpDialogComponent } from '../hang-up-dialog/hang-up-dialog.component';
import { AudioAnalyseService } from '../services/audio-analyse.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fadeInAndOutAnimation, flyInAnimation } from '@redngapps/videosprechstunde/ui';
import { KeyboardKeys } from '@redngapps/shared/types';
import { SharingNotAuthorizedDialogComponent } from '../sharing-not-authorized-dialog/sharing-not-authorized-dialog.component';
import { AuthService } from '../services/auth.service';
import { Logger, logMessage, logSafe } from '@redngapps/shared/util';
import { ConferenceInstancesService } from '@redngapps/videosprechstunde/util';
import { ICallSettings } from '../settings/call-settings';
import idx from 'idx';

enum ConnectionStates {
  WAITING_FOR_PEER = 'waitingForPeer',
  CONNECTED = 'connected',
  PEER_DISCONNECTED = 'peerDisconnected',
  HUNG_UP = 'hungUp',
}

enum ConnectionInfo {
  HIDE = 'hide',
  IS_CONNECTING = 'showIsConnecting',
  IS_CONNECTING_TAKES_LONG = 'isConnectingTakesLong',
}

@Component({
  selector: 'red-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.scss'],
  animations: [fadeInAndOutAnimation, flyInAnimation],
})
export class ConferenceComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('offlineSwal', { static: true }) offlineSwal: SwalComponent;

  ConnectionStates = ConnectionStates;
  ConnectionInfo = ConnectionInfo;

  turnDialogRef: MatDialogRef<TurnConfirmDialogComponent>;
  hangUpDialogRef: MatDialogRef<HangUpDialogComponent>;

  sidenavOpened = false;
  isDoctor = false;
  participants: ParticipantInfo[];
  mainDisplayParticipant: ParticipantInfo;
  localStream: MediaStream;

  showScreenSharingBtn$: Observable<boolean>;
  isScreenSharingDisabled$: Observable<boolean>;
  screenSharingTooltip$: Observable<string>;
  // camera and mic enabled per default
  microphoneActivationState = true;
  cameraActivationState = true;
  /** Indicates if we are sharing our screen at the moment */
  screenSharingActivationState = new BehaviorSubject(false);
  /** Indicates if a screen is being shared by someone else at the moment */
  screenSharingOn = new BehaviorSubject(false);
  fullScreenActivationState = false;

  isDesktop: boolean;
  hideButtons = false;
  isConnectionEstablished: ConnectionInfo = ConnectionInfo.HIDE;
  roomTitle: BehaviorSubject<string>;

  audioOutputDeviceId = null;
  settings: ICallSettings;

  showUpgradeInfo$: Observable<boolean>;

  connectionState$: Observable<ConnectionStates>;
  connectionState = new BehaviorSubject<ConnectionStates>(ConnectionStates.WAITING_FOR_PEER);

  private mouseInactivityTimeout;
  private WAITING_MESSAGE = 'Bitte warten ...';
  private acceptedConnectionOverTURN = false;
  private reconnectOverTURNWith = [];
  private wasOfflineMoreThan10Sec = false;

  private firstTimeoutScreenSharing: ReturnType<typeof setTimeout>;
  private secondTimeoutScreenSharing: ReturnType<typeof setTimeout>;

  private unsubscribe = new Subject();

  constructor(
    private mediaService: MediaService,
    private signalingService: SignalingService,
    private webRtcService: WebRtcService,
    private audioAnalyseService: AudioAnalyseService,
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private dialog: MatDialog,
    protected matSnackBar: MatSnackBar,
    private logger: Logger,
    private conferenceInstancesServices: ConferenceInstancesService,
  ) {
    this.initOfflineHandling();
    this.participants = [];

    this.connectionState$ = this.connectionState.asObservable().pipe(shareReplay(1));
  }

  ngOnInit(): void {
    this.showUpgradeInfo$ = this.connectionState$.pipe(
      map(connectionState => {
        // we only want to show the info to doctors / organizers with a basic connect-license
        if (!this.authService.isConnectPlusLicense() && this.isDoctor) {
          return connectionState === ConnectionStates.PEER_DISCONNECTED || connectionState === ConnectionStates.HUNG_UP;
        }

        return false;
      }),
      shareReplay(1),
    );

    this.conferenceInstancesServices.saveConferenceIsOpen();
    this.isDesktop = this.deviceService.isDesktop();
    this.isDoctor = this.authService.codeType === 'doctor';

    this.initScreenSharingObservables();

    this.webRtcService.onPeerUpdate.pipe(takeUntil(this.unsubscribe)).subscribe(newParticipantInfo => {
      const participant = this.participants.find(e => e.id === newParticipantInfo.id && !e.isRemoteSharedScreen);
      if (participant) {
        this.updateParticipant(participant, newParticipantInfo);
      } else {
        this.addNewParticipant(newParticipantInfo);
      }
    });

    this.roomTitle = this.webRtcService.roomTitle;

    this.audioAnalyseService.onParticipantStartingTalking.pipe(takeUntil(this.unsubscribe)).subscribe(participant => {
      if (!this.screenSharingActivationState.getValue() && !this.screenSharingOn.getValue()) {
        this.mainDisplayParticipant = participant;
      }
    });
    this.mediaService.onMediaTrackEnded.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.webRtcService.removeScreensharingTrack();
      this.screenSharingActivationState.next(false);
      this.mainDisplayParticipant = this.participants[0];
    });
    this.mediaService.onPermissionDeniedBySystem.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.dialog.open(SharingNotAuthorizedDialogComponent, {
        width: '371px',
      });
    });
    this.resetInactivityTimer();
  }

  // tslint:disable-next-line:red-restrict-async-lifecycle-hooks - DEV-10762
  async ngAfterViewInit(): Promise<void> {
    this.localStream = await this.mediaService.getLocalUserMediaStream();
    this.createCallSettings(this.localStream);

    this.initOnConnectionFailedReceived();
    this.initOnPeerJoinedRoom();
    this.initOnPeerLeftRoom();
  }

  onHome(): void {
    this.conferenceInstancesServices.removeConferenceIsOpen();
    document.location.href = '/';
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onMuteToggle(): void {
    this.microphoneActivationState = !this.microphoneActivationState;

    this.mediaService.updateMicrophoneState(this.microphoneActivationState);

    this.logger.info(logMessage`Mute toggle; new state: ${logSafe(this.microphoneActivationState)}`);
  }

  onCameraToggle(): void {
    this.cameraActivationState = !this.cameraActivationState;

    this.mediaService.updateCameraState(this.cameraActivationState);

    this.webRtcService.notifyCameraToggle(!this.cameraActivationState);

    this.logger.info(logMessage`Camera toggle; new state: ${logSafe(this.cameraActivationState)}`);
  }

  onFullScreenToggle(): void {
    this.fullScreenActivationState = !this.fullScreenActivationState;
  }

  async onScreenSharingToggle(): Promise<void> {
    const newActivationState = !this.screenSharingActivationState.getValue();

    if (!newActivationState) {
      this.webRtcService.removeScreensharingTrack();
      this.screenSharingActivationState.next(newActivationState);
      this.mainDisplayParticipant = this.participants[0];
    } else {
      const mediaStream = await this.mediaService.getDisplayMediaStream();
      if (mediaStream && mediaStream.getVideoTracks().length === 1) {
        this.webRtcService.addScreensharingTrack(mediaStream.getVideoTracks()[0]);
        this.screenSharingActivationState.next(newActivationState);
        this.mainDisplayParticipant = null;
      }
    }
    this.logger.info(
      logMessage`Screen Sharing toggle; new state: ${logSafe(this.screenSharingActivationState.getValue())}`,
    );
  }

  hangUp(): void {
    this.hangUpDialogRef = this.dialog.open(HangUpDialogComponent, {
      disableClose: true,
      width: '416px',
    });
    this.hangUpDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(confirm => {
        if (confirm) {
          this.connectionState.next(ConnectionStates.HUNG_UP);
          this.disconnect();
        }
      });
  }

  toggleSidenav(): void {
    this.sidenavOpened = !this.sidenavOpened;
  }

  @HostListener('click')
  @HostListener('mousemove')
  resetInactivityTimer(): void {
    if (this.mouseInactivityTimeout) {
      this.hideButtons = false;
      clearTimeout(this.mouseInactivityTimeout);
    }
    this.mouseInactivityTimeout = setTimeout(() => {
      this.hideButtons = true;
    }, 2000);
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (
      [
        KeyboardKeys.Escape,
        KeyboardKeys.Tab,
        KeyboardKeys.Space,
        KeyboardKeys.ArrowUp,
        KeyboardKeys.ArrowDown,
        KeyboardKeys.ArrowLeft,
        KeyboardKeys.ArrowRight,
        KeyboardKeys.Enter,
      ].includes(event.key as KeyboardKeys)
    ) {
      this.resetInactivityTimer();
    }
  }

  @HostListener('window:beforeunload', ['$event']) removeLocalStorageItem() {
    this.conferenceInstancesServices.removeConferenceIsOpen();
  }

  onSettingsChange(event: ICallSettings): void {
    this.settings = event;

    // Change audio output.
    const newAudioOutputDeviceId = idx(event, _ => _.audio.output);
    if (newAudioOutputDeviceId) {
      this.audioOutputDeviceId = newAudioOutputDeviceId;
    }

    // Change audio and video inputs for peers
    if (idx(event, _ => _.audio.input) || idx(event, _ => _.video.input) || idx(event, _ => _.video.facingMode)) {
      this.mediaService
        .changeInputOutput(event)
        .then((newStream: MediaStream) => {
          this.localStream = newStream;
          return this.webRtcService.changeAudioVideoStream(newStream);
        })
        .catch(e => this.logger.error(logMessage`Error changing IO`, e));
    }
  }

  onToggleConnectionInfo(shouldShow: boolean): void {
    if (shouldShow) {
      this.firstTimeoutScreenSharing = setTimeout(() => {
        this.isConnectionEstablished = ConnectionInfo.IS_CONNECTING;
        this.secondTimeoutScreenSharing = setTimeout(() => {
          this.isConnectionEstablished = ConnectionInfo.IS_CONNECTING_TAKES_LONG;
        }, 5000);
      }, 3000);
    } else {
      if (this.firstTimeoutScreenSharing) {
        clearTimeout(this.firstTimeoutScreenSharing);
      }
      if (this.secondTimeoutScreenSharing) {
        clearTimeout(this.secondTimeoutScreenSharing);
      }
      this.isConnectionEstablished = ConnectionInfo.HIDE;
    }
  }

  private initOfflineHandling(): void {
    let timeout;
    window.onoffline = () => {
      this.offlineSwal.show();
      timeout = setTimeout(() => {
        this.wasOfflineMoreThan10Sec = true;
      }, 10000);
    };

    window.ononline = () => {
      this.offlineSwal.nativeSwal.close();
      if (timeout) {
        clearTimeout(timeout);
      }
      if (this.wasOfflineMoreThan10Sec) {
        this.wasOfflineMoreThan10Sec = false;
        this.onHome();
      }
    };
  }

  private showSnackBarJoined(participant): void {
    this.matSnackBar.open(`${participant.name} ist der Konferenz beigetreten`, 'Ok', {
      duration: 3000,
    });
  }

  private updateParticipant(currentParticipantInfo: ParticipantInfo, newParticipantInfo: ParticipantInfo): void {
    currentParticipantInfo.remoteScreenVideoTrack = newParticipantInfo.remoteScreenVideoTrack;

    currentParticipantInfo.remoteStream = newParticipantInfo.remoteStream;
    this.toggleScreenSharing(currentParticipantInfo);
    this.audioAnalyseService.listenIfParticipantIsTalking(currentParticipantInfo);

    if (this.hasNameBeenUpdated(currentParticipantInfo, newParticipantInfo)) {
      currentParticipantInfo.name = newParticipantInfo.name;
      this.showSnackBarJoined(currentParticipantInfo);

      const remoteScreen = this.participants.find(e => e.id === currentParticipantInfo.id && e.isRemoteSharedScreen);
      if (remoteScreen) {
        remoteScreen.name = `Bildschirm von ${currentParticipantInfo.name}`;
      }
    }
    currentParticipantInfo.cameraOff = newParticipantInfo.cameraOff;
  }

  private hasNameBeenUpdated(currentParticipantInfo: ParticipantInfo, newParticipantInfo: ParticipantInfo): boolean {
    return (
      newParticipantInfo.name && (!currentParticipantInfo.name || currentParticipantInfo.name === this.WAITING_MESSAGE)
    );
  }

  private addNewParticipant(participant: ParticipantInfo): void {
    this.participants.push({
      ...participant,
      name: participant.name || this.WAITING_MESSAGE,
    });
    this.toggleScreenSharing(participant);
    this.audioAnalyseService.listenIfParticipantIsTalking(participant);
    if (participant.name) {
      this.showSnackBarJoined(participant);
    }
  }

  private toggleScreenSharing(participant: ParticipantInfo): void {
    if (participant.remoteScreenVideoTrack && !this.screenSharingOn.getValue()) {
      this.addRemoteSharedScreenTrackToParticipants(participant.remoteScreenVideoTrack, participant);
    } else if (
      // check if participant was sharing his screen and now isnt anymore
      !participant.remoteScreenVideoTrack &&
      this.screenSharingOn.getValue() &&
      this.participants.find(e => e.id === participant.id && e.isRemoteSharedScreen)
    ) {
      this.removeRemoteSharedScreenTrackFromParticipants();
    }
  }

  private addRemoteSharedScreenTrackToParticipants(track: MediaStreamTrack, participant: ParticipantInfo): void {
    const firstStream = new MediaStream();
    firstStream.addTrack(track);
    // the track from the shared screen gets its own participant object.
    // The peer sharing his screen still has his own separate participant object in the list
    const firstStreamFakeParticipant: ParticipantInfo = {
      remoteStream: firstStream,
      name: participant.name ? `Bildschirm von ${participant.name}` : this.WAITING_MESSAGE,
      id: participant.id,
      cameraOff: false,
      remoteScreenVideoTrack: null,
      timeActivelyTalking: 0,
      isRemoteSharedScreen: true,
    };
    this.screenSharingOn.next(true);
    this.mainDisplayParticipant = firstStreamFakeParticipant;
    this.participants = [
      firstStreamFakeParticipant,
      participant,
      ...this.participants.filter(e => e.id !== participant.id),
    ];
    this.resetInactivityTimer();
  }

  private removeRemoteSharedScreenTrackFromParticipants(): void {
    this.screenSharingOn.next(false);
    this.participants = this.participants.filter(e => !e.isRemoteSharedScreen);
    this.mainDisplayParticipant = this.participants[0];
    this.resetInactivityTimer();
  }

  private disconnect(): void {
    try {
      this.webRtcService.leave();

      this.stopStreaming();
    } catch (e) {
      // ignore Websocket already closed error
    }
  }

  private stopStreaming(): void {
    this.mediaService.stopTracks();
  }

  private initOnPeerJoinedRoom(): void {
    this.webRtcService.onPeerJoinedRoom.pipe(takeUntil(this.unsubscribe)).subscribe((event: IRedRTCEvent) => {
      this.connectionState.next(ConnectionStates.CONNECTED);
      if (!this.participants.find(e => e.id === event.socketId)) {
        this.participants.push({
          id: event.socketId,
          name: this.WAITING_MESSAGE,
          remoteStream: null,
          cameraOff: false,
          timeActivelyTalking: 0,
          remoteScreenVideoTrack: null,
          isRemoteSharedScreen: false,
        });

        if (!this.mainDisplayParticipant && !this.screenSharingActivationState.getValue()) {
          this.mainDisplayParticipant = this.participants[0];
        }

        this.logger.info(logMessage`Paticipants peer joined: ${logSafe(event.socketId)}`);
      }
    });
  }

  private initOnPeerLeftRoom(): void {
    this.signalingService.onPeerLeftRoom.pipe(takeUntil(this.unsubscribe)).subscribe((event: IRedRTCEvent) => {
      if (!event) {
        // When there is no event, we can't do anything here, because we don't get a socketId.
        // This happens when the signaling service get's a message with type "reconnected".
        // In this case it emits a "left" event without any message (called event here), because it can't determine by which socket this event was triggered,
        // so the socketId would always be our own.
        return;
      }
      this.logger.info(logMessage`Paticipant left: ${logSafe(event.socketId)}`);

      const leavingParticipants = this.participants.filter(participant => participant.id === event.socketId);

      // the peer who left was sharing his screen
      if (leavingParticipants.length > 1) {
        this.screenSharingOn.next(false);
      }

      leavingParticipants.forEach(participant => {
        this.audioAnalyseService.stopListeningToParticipant(participant);
      });

      this.participants = this.participants.filter(participant => participant.id !== event.socketId);

      if (this.reconnectOverTURNWith.length === 1 && this.reconnectOverTURNWith[0] === event.socketId) {
        if (this.turnDialogRef) {
          this.turnDialogRef.close();
        }
      }
      if (!this.participants.length) {
        if (this.hangUpDialogRef) {
          this.hangUpDialogRef.close();
        }
        this.sidenavOpened = false;

        this.connectionState.next(ConnectionStates.PEER_DISCONNECTED);

        this.disconnect();
      } else if (this.mainDisplayParticipant && this.mainDisplayParticipant.id === event.socketId) {
        this.mainDisplayParticipant = this.participants[0];
      }
    });
  }

  private initOnConnectionFailedReceived(): void {
    this.webRtcService.onStunConnectionFailed.pipe(takeUntil(this.unsubscribe)).subscribe(socketId => {
      if (!this.acceptedConnectionOverTURN) {
        this.reconnectWithTurn(socketId);
      } else {
        this.webRtcService.reconnectOverTurn(socketId);
      }
    });
  }

  private reconnectWithTurn(socketId: string): void {
    if (this.turnDialogRef) {
      this.reconnectOverTURNWith.push(socketId);
    } else {
      this.reconnectOverTURNWith = [socketId];
      this.turnDialogRef = this.dialog.open(TurnConfirmDialogComponent, {
        disableClose: true,
        maxWidth: '750px',
      });
      this.turnDialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((turn: TurnDialogAnswers) => {
          if (turn === TurnDialogAnswers.REJECT) {
            this.webRtcService.leave();
          } else if (turn === TurnDialogAnswers.ACCEPT) {
            this.acceptedConnectionOverTURN = true;
            this.reconnectOverTURNWith.forEach(id => this.webRtcService.reconnectOverTurn(id));
          }
        });
    }
  }

  /**
   * Reads current input and output device ids from the stream and creates call settings based on them.
   * @private
   */
  private createCallSettings(stream: MediaStream): void {
    if (stream) {
      this.settings = {};

      const audioTracks = stream.getAudioTracks();
      if (audioTracks && audioTracks[0]) {
        this.settings.audio = {
          input: audioTracks[0].getSettings().deviceId,
        };
      }

      const videoTracks = stream.getVideoTracks();
      if (videoTracks && videoTracks[0]) {
        this.settings.video = {
          input: videoTracks[0].getSettings().deviceId,
        };
      }
    }
  }

  private initScreenSharingObservables() {
    this.showScreenSharingBtn$ = this.connectionState$.pipe(
      map(connectionState => {
        if (this.authService.isConnectPlusLicense() || this.isDoctor) {
          return connectionState === ConnectionStates.CONNECTED && this.isDesktop;
        }
        return false;
      }),
      shareReplay(1),
    );

    this.isScreenSharingDisabled$ = this.screenSharingOn.asObservable().pipe(
      map((isScreenShared: boolean) => {
        if (this.authService.isConnectPlusLicense()) {
          return isScreenShared;
        }
        return true;
      }),
      shareReplay(1),
    );

    this.screenSharingTooltip$ = this.screenSharingActivationState.pipe(
      withLatestFrom(this.isScreenSharingDisabled$),
      map(([screenSharingActive, isDisabled]: [boolean, boolean]) => {
        if (isDisabled && !this.authService.isConnectPlusLicense()) {
          return 'Bildschirm teilen ist eine Funktion von RED connect plus. Weitere Informationen finden Sie auf der Startseite der Terminverwaltung.';
        }

        return screenSharingActive ? 'Freigabe beenden' : 'Bildschirm freigeben';
      }),
      shareReplay(1),
    );
  }
}
