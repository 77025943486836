import { Component, Input } from '@angular/core';

export type MessageLevel = 'info' | 'warning' | 'error';

@Component({
  selector: 'red-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  /**
   * Role of the message
   */
  @Input() role: MessageLevel;
}
