import { RedSignaling } from '@redmedical/webrtc-server';
import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { ISignalingMessage, IRedRTCEvent, ICustomError } from '@redngapps/videosprechstunde/types';
import { Logger, logMessage } from '@redngapps/shared/util';
import { environment } from '@video-env/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalingService {
  onConnectionOpen: ReplaySubject<void> = new ReplaySubject(1);
  onPeerLeftRoom: Subject<IRedRTCEvent> = new Subject();
  onError: Subject<IRedRTCEvent> = new Subject();
  onCustomError: Subject<ICustomError> = new Subject();
  onConnectionInterrupted: Subject<IRedRTCEvent> = new Subject();
  onConnectionError: Subject<IRedRTCEvent> = new Subject();
  onSignalingMessage: Subject<ISignalingMessage> = new Subject();
  onLoginQuotaAvailable: Subject<ISignalingMessage> = new Subject();

  signaling: RedSignaling;

  constructor(private logger: Logger) {}

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.signaling) {
        this.signaling.leave();
      }
      const signalingServiceEndpoint = environment.urls.signalingServer;
      this.signaling = new RedSignaling(signalingServiceEndpoint);

      this.signaling.on('connectionError', (err: IRedRTCEvent) => {
        this.onConnectionError.next(err);
        reject();
      });

      this.signaling.on('connectionInterrupted', (err: IRedRTCEvent) => {
        this.onConnectionInterrupted.next(err);
      });

      this.signaling.on('error', (event: IRedRTCEvent) => {
        this.logger.info(logMessage`Signaling ERROR`, event);
        if (
          event === 'key_in_use' ||
          event === 'key_invalid' ||
          event === 'max_room_size_reached' ||
          event === 'login_quota_exceeded'
        ) {
          this.onCustomError.next(event as ICustomError);
        } else {
          this.onError.next(event);
        }
      });

      this.signaling.on('message', msg => {
        this.onSignalingMessage.next(msg);
      });

      this.signaling.on('loginQuotaAvailable', msg => {
        this.onLoginQuotaAvailable.next(msg);
      });

      this.signaling.on('left', (event: IRedRTCEvent) => {
        this.onPeerLeftRoom.next(event);
      });

      this.signaling.on('open', () => {
        this.onConnectionOpen.next();
        resolve();
      });
    });
  }

  async join(accessCode: string, options?: { name?: string | number }) {
    return this.signaling.join(accessCode, options);
  }

  sendCandidate(candidate: RTCIceCandidate, member: string, receiver: string): void {
    this.signaling.send({ candidate, member, receiver });
  }

  sendDescription(sdp: RTCSessionDescription, member: string, receiver: string) {
    this.signaling.send({ sdp, member, receiver });
  }

  sendReconnectMessage(member: string, receiver: string): void {
    this.signaling.send({ member, receiver, type: 'reconnectPc' });
  }

  leave() {
    this.signaling.leave();
  }
}
