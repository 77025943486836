import { LoggerString } from './anonymized-logger.models';

export enum Severity {
  Info,
  Warning,
  Error,
}

export abstract class Logger {
  /**
   * General call for logging API.
   * @param message
   * @param {Severity} severity
   * @param {Error} error
   * @param metaData
   */
  abstract log(message: LoggerString, severity: Severity, error?: Error, ...metaData: any[]): void;

  /**
   * Log message with "information" severity.
   * @param message
   * @param metaData
   */
  info(message: LoggerString, ...metaData: any[]) {
    this.log(message, Severity.Info, null, ...metaData);
  }

  /**
   * Log message with "warning" severity.
   * @param message
   * @param metaData
   */
  warn(message: LoggerString, ...metaData: any[]) {
    this.log(message, Severity.Warning, null, ...metaData);
  }

  /**
   * Log message with "error" severity.
   * @param message
   * @param {Error} error
   * @param metaData
   */
  error(message: LoggerString, error?: Error, ...metaData: any[]) {
    this.log(message, Severity.Error, error, ...metaData);
  }
}
