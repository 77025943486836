import { AfterViewInit, Component, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'red-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
})
export class HeaderNavigationComponent implements AfterViewInit {
  @Input() roomTitle: string;
  isLoggedIn = false;

  constructor(private authService: AuthService) {}
  // tslint:disable-next-line:red-restrict-async-lifecycle-hooks - DEV-10762
  async ngAfterViewInit(): Promise<void> {
    this.isLoggedIn = (await this.authService.getAuthState()).isLoggedIn;
  }
}
