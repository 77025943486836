import { Injectable } from '@angular/core';
import { UrlTree, Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { MediaService } from '@redngapps/videosprechstunde/feature';

@Injectable({ providedIn: 'root' })
export class DevicesAvailableGuard implements CanActivate {
  constructor(private mediaService: MediaService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const deviceErrorType = route.data.deviceErrorType;

    const deviceError = await this.mediaService.checkIODevices();

    if (deviceErrorType !== deviceError) {
      return this.router.parseUrl('/login');
    }

    return true;
  }
}
