import { InjectionToken } from '@angular/core';
import { IWebrtcConfig } from '@redngapps/videosprechstunde/types';

export const RED_WEBRTC_CONFIG = new InjectionToken<IWebrtcConfig>('IWebrtcConfig', {
  providedIn: 'root',
  factory: () => ({
    peerConfigStunOnly: {
      iceServers: [
        {
          urls: 'stun:stun.redmedical.de',
        },
      ],
    },
    peerConfig: {
      iceServers: [
        {
          urls: 'stun:stun.redmedical.de',
        },
        {
          urls: 'turns:relay.redmedical.de:443',
          credential: 'iu8aThi1',
          username: 'iogeeX9u',
        },
      ],
    },
  }),
});
