import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, startWith, take } from 'rxjs/operators';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { isDate } from 'date-fns';
import { ParsedUrl, UrlParameter } from '@redngapps/shared/types';

// workaround to find out if the given control has the required-validator
// https://github.com/angular/angular/issues/13461#issuecomment-340368046
export function hasRequiredValidator(abstractControl: AbstractControl): boolean {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  return false;
}

/**
 * This function removes empty values from an object, because they would cause server-errors.
 *
 * *Create*
 * When creating a new entry all fields, that are sent to the server, need values. No '$unset' allowed.
 *
 * *Update*
 * When updating an entry you can remove exisiting values by setting them to '$unset'.
 * If the query is set to `fullUpdate: true` they could just be omitted aswell.
 *
 * {@see new-client implementation: libs\client-newclient\src\app\core\services\utils\service.utils.cleanObject.ts}
 */
export function cleanObjectForServer<T>(object: T, isCreate?: boolean): T {
  if (Array.isArray(object)) {
    throw new TypeError("This function can't be used for cleaning an array");
  }

  Object.keys(object).forEach(k => {
    if (object[k] === '' || object[k] === null || object[k] === undefined || (isCreate && object[k] === '$unset')) {
      delete object[k];
    }
  });
  return Object.keys(object).length === 0 ? null : object;
}

export function cleanObjectForServerDeep<T>(object: T, isCreate?: boolean): T {
  if (Array.isArray(object)) {
    object.forEach(item => cleanObjectForServerDeep(item, isCreate));
    return object;
  }

  Object.keys(object).forEach(k => {
    if ((typeof object[k] === 'object' && object[k] !== null) || Array.isArray(object[k])) {
      object[k] = cleanObjectForServerDeep(object[k], isCreate);
    }

    if (object[k] === '' || object[k] === null || object[k] === undefined || (isCreate && object[k] === '$unset')) {
      delete object[k];
    }
  });

  const isEmptyObject = !(object instanceof Date) && Object.keys(object).length === 0;
  return isEmptyObject ? null : object;
}

/**
 * To remove a already saved string value you need to send `'$unset'` to the server.
 * Empty strings would be ignored and the original value remains unchanged.
 */
export function trimReplaceFalsyString(value: string) {
  return value.trim() ? value.trim() : '$unset';
}

export function escapeRegExp(str: string) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

/**
 * Checks if the given value is `null`, `undefined` or has the length 0.
 * *This is simply angular's internal implementation that isn't made public/ exported*
 */
export function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value === null || value === undefined || value.length === 0;
}

/**
 * **This is copied from the angular source, since this functions is only available in version 8 and higher**
 *
 * Remove all controls in the `FormArray`.
 *
 * @usageNotes
 * ### Remove all elements from a FormArray
 *
 * ```ts
 * const arr = new FormArray([
 *    new FormControl(),
 *    new FormControl()
 * ]);
 * console.log(arr.length);  // 2
 *
 * arr.clear();
 * console.log(arr.length);  // 0
 * ```
 *
 * It's a simpler and more efficient alternative to removing all elements one by one:
 *
 * ```ts
 * const arr = new FormArray([
 *    new FormControl(),
 *    new FormControl()
 * ]);
 *
 * while (arr.length) {
 *    arr.removeAt(0);
 * }
 * ```
 */
export function clear(formArray: FormArray): void {
  if (formArray.controls.length < 1) return;
  // tslint:disable-next-line: no-empty
  formArray.controls.forEach((control: AbstractControl) => (<any>control)._registerOnCollectionChange(() => {}));
  formArray.controls.splice(0);
  formArray.updateValueAndValidity();
}

/**
 * Returns the current value of the observable.
 * *obs.pipe(take(1)).toPromise()*
 * @param obs
 */
export function currentValueAsPromise<T>(obs: Observable<T>): Promise<T> {
  if (!obs) {
    return undefined;
  }
  return obs.pipe(take(1)).toPromise();
}

/**
 * Returns the higher number.
 * If the numbers are equal the first parameter is returned.
 * @param a
 * @param b
 */
export function maxValue(a: number, b: number) {
  if (a >= b) {
    return a;
  }
  return b;
}

export function propertyOf<T>(name: keyof T) {
  return name;
}

export function removeBase64PdfHeader(pdfFile: string): string {
  const pdfHeader = 'data:application/pdf;base64,';
  if (pdfFile.startsWith(pdfHeader)) {
    return pdfFile.split(pdfHeader)[1];
  }
  return pdfFile;
}

/**
 * Convert Base64 string to Blob.
 */
export function base64toBlob(base64: string, contentType = 'application/octet-stream', sliceSize = 512): Blob {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

/**
 * Copies a string to the clipboard. Must be called from within an
 * event handler such as click. May return false if it failed, but
 * this is not always possible.
 */
export function copyToClipboard(text: string) {
  if ((<any>window).clipboardData && (<any>window).clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return (<any>window).clipboardData.setData('Text', text);
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textArea = document.createElement('textarea');
    textArea.textContent = text;
    textArea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textArea);
    textArea.select();
    try {
      // Security exception may be thrown by some browsers.
      return document.execCommand('copy');
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textArea);
    }
  }
}

/** This function should be used to ensure that a uniform format is sent to the server. */
export function mapUserInsertedPhoneNumber(dirtyPhoneNumber: string): string {
  const parsedPhoneNumber = parsePhoneNumberFromString(dirtyPhoneNumber, 'DE');
  return parsedPhoneNumber.number.toString();
}

/**
 * trim recursively all strings of an object
 */
export function deepTrim<T>(obj: T): T {
  if (obj === null || obj === undefined || isDate(obj) || (!Array.isArray(obj) && typeof obj !== 'object')) {
    return obj;
  }

  Object.keys(obj).forEach(key => {
    obj[key] = typeof obj[key] === 'string' ? obj[key].trim() : deepTrim(obj[key]);
  });

  return obj;
}

export function firstToLowerCase(value: string): string {
  return value[0].toLowerCase() + value.substring(1);
}

export function waitForFormToNotBePending(abstractControl: AbstractControl): Promise<string> {
  return abstractControl.statusChanges
    .pipe(
      startWith(abstractControl.status),
      filter((status: string): boolean => status !== 'PENDING'),
      take(1),
    )
    .toPromise();
}

/**
 * Returns the name of the first invalid control of the given form-group.
 *
 * **Caution!**
 *
 * You must add the controls to the form-group in the same order as they are displayed in the view,
 * otherwise the returned control-name will not match the first invalid field on the view.
 */
export function getFirstInvalidControlName(formGroup: FormGroup): string {
  if (!formGroup) {
    return null;
  }

  for (const controlName of Object.keys(formGroup.controls)) {
    const control = formGroup.controls[controlName];
    if (!control.valid) {
      return controlName;
    }
  }
}

export function parseUrl(url: string): ParsedUrl {
  const [path, parameters] = url.split('?');
  let finalParameters: UrlParameter[] = [];

  if (parameters) {
    const splitParameters = parameters.split('&');
    finalParameters = splitParameters
      .map(p => p.split('='))
      .map(splitParameter => ({ name: splitParameter[0], value: splitParameter[1] }));
  }

  return { path, parameters: finalParameters };
}

export function toIsoDate(n: string | number | Date) {
  if (!n) {
    return undefined;
  }

  const date = new Date(n);

  return date && date.toISOString();
}
