import { IEnvironment } from './environment.interface';
import { EEnvironment } from './environment.enum';

export const environment: IEnvironment = {
  name: EEnvironment.staging,
  urls: {
    signalingServer: 'wss://' + location.hostname + (location.port ? ':' + location.port : ''),
    redClient: 'https://release.ci.redicals.de/',
  },
  featureSwitches: {
    sentry: true,
    importErrorModule: true,
    angularProdModeEnabled: true,
  },
  softwareVersion: '2.48.0',
  gitHash: 'will-be-replaced',
};
