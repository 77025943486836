import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum TurnDialogAnswers {
  REJECT = 'reject',
  ACCEPT = 'accept',
}

@Component({
  selector: 'red-turn-confirm-dialog',
  templateUrl: 'turn-confirm-dialog.component.html',
  styleUrls: ['turn-confirm-dialog.component.scss'],
})
export class TurnConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<TurnConfirmDialogComponent>) {}

  clickCancel(): void {
    this.dialogRef.close(TurnDialogAnswers.REJECT);
  }

  clickConfirm(): void {
    this.dialogRef.close(TurnDialogAnswers.ACCEPT);
  }
}
