import {
  Directive,
  ViewContainerRef,
  TemplateRef,
  Input,
  ComponentFactoryResolver,
  Injector,
  Renderer2,
  OnDestroy,
  ComponentRef,
  NgZone,
  Inject,
  EmbeddedViewRef,
  AfterViewInit,
} from '@angular/core';
import { InfoComponent } from './info/info.component';
import { InfoButtonComponent } from './info-button/info-button.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INFO_FIELD_RELATIVE_PARENT_CLASS } from './field-info-relative-parent.directive';
import { DOCUMENT } from '@angular/common';
import { KeyboardKeys } from '@redngapps/shared/types';

@Directive({
  selector: '[redFieldInfo]',
})
export class FieldInfoDirective implements OnDestroy, AfterViewInit {
  @Input()
  redFieldInfoOffset = 32;

  @Input()
  redFieldInfoWidth: number;

  private infoPopover: Element;
  private infoButtonComponentRef: ComponentRef<InfoButtonComponent>;
  private labelElementRef: HTMLElement;
  private infoTemplateRef: TemplateRef<unknown>;

  private unsubscribe = new Subject<void>();
  private embeddedViewRef: EmbeddedViewRef<unknown>;
  private unregisterListeners: () => void;
  private onPositionChange: () => void;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  @Input() set redFieldInfo(infoTemplateRef: TemplateRef<unknown>) {
    this.infoTemplateRef = infoTemplateRef;
    if (this.embeddedViewRef) {
      this.embeddedViewRef.destroy();
    }
    this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
  }

  ngAfterViewInit(): void {
    if (this.infoTemplateRef) {
      this.insertInfoIcon();
      this.listenToClicksOnIcon(this.infoTemplateRef);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.removeListeners();
    if (this.embeddedViewRef) {
      this.embeddedViewRef.destroy();
    }
    if (this.infoPopover) {
      this.hideInfoPopup();
    }
  }

  /**
   * Programmatically close the fieldinfo if open
   */
  close() {
    if (this.infoPopover) {
      this.hideInfoPopup();
    }
  }

  /**
   * Programmatically open the fieldinfo if not open
   */
  open() {
    if (!this.infoPopover && this.infoTemplateRef) {
      this.createInfoPopover(this.infoTemplateRef);
    }
  }

  private removeListeners(): void {
    if (this.unregisterListeners) {
      this.unregisterListeners();
    }
  }

  private insertInfoIcon(): void {
    const infoButtonComponentFactory = this.componentFactoryResolver.resolveComponentFactory(InfoButtonComponent);
    this.infoButtonComponentRef = infoButtonComponentFactory.create(this.injector);
    this.labelElementRef = this.templateRef.elementRef.nativeElement.nextSibling;
    this.renderer.appendChild(this.labelElementRef, this.infoButtonComponentRef.location.nativeElement);
  }

  private listenToClicksOnIcon(infoTemplateRef: TemplateRef<unknown>): void {
    this.infoButtonComponentRef.instance.infoButtonClick.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.infoPopover) {
        this.hideInfoPopup();
      } else {
        this.createInfoPopover(infoTemplateRef);
      }
    });
  }

  private hideInfoPopup() {
    this.infoPopover.remove();
    this.infoPopover = undefined;
    this.removeListeners();
  }

  private createInfoPopover(infoTemplateRef: TemplateRef<unknown>): void {
    const infoComponentFactory = this.componentFactoryResolver.resolveComponentFactory(InfoComponent);
    const infoPopoverRef = infoComponentFactory.create(this.injector);
    infoPopoverRef.instance.info = infoTemplateRef;
    infoPopoverRef.changeDetectorRef.detectChanges();

    const relativeParent = this.labelElementRef.closest(`.${INFO_FIELD_RELATIVE_PARENT_CLASS}`) || this.document.body;

    this.infoPopover = infoPopoverRef.location.nativeElement;
    this.setDimensionPopover(infoPopoverRef);
    relativeParent.appendChild(this.infoPopover);

    this.positionInfoPopover(infoPopoverRef, relativeParent);

    this.onPositionChange = () => {
      this.setDimensionPopover(infoPopoverRef);
      this.positionInfoPopover(infoPopoverRef, relativeParent);
    };

    this.unregisterListeners = this.ngZone.runOutsideAngular(() => {
      const unregisterResize = this.renderer.listen('document', 'resize', this.onPositionChange);
      const unregisterOrientationChange = this.renderer.listen('document', 'orientationchange', this.onPositionChange);
      const unregisterKeyListeners = this.renderer.listen('document', 'keyup', this.onKeyUp.bind(this));

      // The renderer does not support capturing events in the capture phase
      const documentClickListener = this.onDocumentClick.bind(this);
      this.document.addEventListener('click', documentClickListener, { capture: true });

      return () => {
        unregisterOrientationChange();
        unregisterResize();
        unregisterKeyListeners();
        this.document.removeEventListener('click', documentClickListener, { capture: true });
      };
    });
  }

  private onDocumentClick(event: MouseEvent) {
    // click is not inside popup and click click is not on button
    if (
      !this.infoPopover.contains(event.target as Node) &&
      !this.infoButtonComponentRef.location.nativeElement.contains(event.target)
    ) {
      this.hideInfoPopup();
    }
  }

  private onKeyUp(event: KeyboardEvent) {
    if (event.code === KeyboardKeys.Space || event.code === KeyboardKeys.Escape) {
      this.hideInfoPopup();
    }
  }

  private setDimensionPopover(infoPopoverRef: ComponentRef<InfoComponent>): void {
    infoPopoverRef.location.nativeElement.style.width = this.redFieldInfoWidth
      ? `${this.redFieldInfoWidth}px`
      : `${this.labelElementRef.offsetWidth}px`;
  }

  private positionInfoPopover(infoPopoverRef: ComponentRef<InfoComponent>, relativeParent: Element): void {
    const newLabelPosition = this.getOffset(this.labelElementRef);
    const newParentPosition = this.getOffset(relativeParent);

    infoPopoverRef.location.nativeElement.style.left = `${newLabelPosition.left - newParentPosition.left}px`;
    infoPopoverRef.location.nativeElement.style.top = `${newLabelPosition.top -
      newParentPosition.top +
      this.redFieldInfoOffset}px`;
  }

  private getOffset(element: Element) {
    if (!element.getClientRects().length) {
      return { top: 0, left: 0 };
    }

    const rect = element.getBoundingClientRect();
    const win = element.ownerDocument.defaultView;
    return {
      top: rect.top + win.pageYOffset,
      left: rect.left + win.pageXOffset,
    };
  }
}
