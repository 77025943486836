import {
  AfterViewInit,
  Directive,
  Input,
  isDevMode,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: component-selector
  selector: '[redSubformArrayName]',
})
export class SubformArrayNameDirective<T> implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input()
  redSubformArrayName: string;

  @Input()
  redSubformArrayValue: Array<T> = [];

  @Input()
  redSubformArrayTrackBy: (_index: number, element: T) => string;

  private formArray = new FormArray([]);
  private iterableDiffer: IterableDiffer<T> | null;

  constructor(private parent: FormGroupDirective, private iterable: IterableDiffers) {}

  ngOnInit() {
    this.checkParentType();
    this.checkTrackBy();
    this.parent.control.addControl(this.redSubformArrayName, this.formArray);
  }

  ngAfterViewInit() {
    this.updateControls();
  }

  ngOnChanges(changes: { redSubformArrayTrackBy: SimpleChange; redSubformArrayValue: SimpleChange }) {
    if (changes.redSubformArrayTrackBy) {
      this.setIterableDiffer();
    }

    if (changes.redSubformArrayValue) {
      const differChange = this.iterableDiffer.diff(changes.redSubformArrayValue.currentValue);

      if (differChange) {
        differChange.forEachRemovedItem(change => {
          const index = change.previousIndex;
          if (this.redSubformArrayValue.length < this.formArray.length) {
            this.formArray.removeAt(index);
          }
        });
      }
      this.updateControls();
    }
  }

  ngOnDestroy() {
    this.parent.control.removeControl(this.redSubformArrayName);
  }

  attachSubform(index: number, subform: FormGroup) {
    this.formArray.setControl(index, subform);
    this.formArray.controls[index].patchValue(this.redSubformArrayValue[index]);
  }

  private setIterableDiffer() {
    this.iterableDiffer = this.iterable.find(this.redSubformArrayValue).create(this.redSubformArrayTrackBy);
  }

  private updateControls() {
    for (let i = 0; i < this.formArray.controls.length; i++) {
      if (this.redSubformArrayValue[i]) {
        this.formArray.controls[i].patchValue(this.redSubformArrayValue[i]);
      }
    }
  }

  private checkParentType() {
    if (isDevMode()) {
      if (this.parent.control.get(this.redSubformArrayName)) {
        throw new Error(`Parent form already declares a property with name '${this.redSubformArrayName}'`);
      }
    }
  }

  private checkTrackBy() {
    if (isDevMode()) {
      if (!this.redSubformArrayTrackBy) {
        throw new Error(`Track by function needs to be set '${this.redSubformArrayName}'`);
      }
    }
  }
}
