import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FirewallProxyComponent } from './error-pages/firewall-proxy/firewall-proxy.component';
import { VersionMismatchComponent } from './error-pages/version-mismatch/version-mismatch.component';
import { SharedUiModule } from './shared-ui.module';

const routes: Routes = [
  {
    path: 'firewall-proxy',
    component: FirewallProxyComponent,
  },
  {
    path: 'old-version',
    component: VersionMismatchComponent,
  },
];

export const SharedUiRouting = RouterModule.forChild(routes);

@NgModule({
  imports: [SharedUiModule, RouterModule.forChild(routes)],
  declarations: [FirewallProxyComponent, VersionMismatchComponent],
})
export class ErrorPagesModule {}
