import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationErrorHandler } from './application-errorhandler';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: ErrorHandler, useClass: ApplicationErrorHandler }],
  declarations: [],
})
export class ErrorModule {}
