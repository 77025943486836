import { Injectable } from '@angular/core';

/**
 * Get only one audio context for the whole app.
 */
@Injectable({
  providedIn: 'root',
})
export class AudioContextService {
  private readonly audioCtx: AudioContext;

  constructor() {
    // @ts-ignore
    const AudioCtxClass = window.AudioContext || window.webkitAudioContext; // for Safari
    this.audioCtx = new AudioCtxClass() as AudioContext;
  }

  getAudioContext(): AudioContext {
    return this.audioCtx;
  }
}
