import { InjectionToken, Type } from '@angular/core';
import { Subject } from 'rxjs';

export class WorkflowComponentItem {
  constructor(readonly component: Type<any>, readonly data?: any) {}
}

export class WorkflowEndItem {
  constructor(readonly result: any) {}
}

export type WorkflowItem = WorkflowComponentItem | WorkflowEndItem;

export const WORKFLOW_ITEM_DATA = new InjectionToken('workflow-item-data');

export class WorkflowItemRef {
  protected finishSubject = new Subject<any>();

  finishWorkflowItem(result: any) {
    this.finishSubject.next(result);
    this.finishSubject.complete();
  }
}

export class WorkflowItemRefInternal extends WorkflowItemRef {
  readonly finish = this.finishSubject.asObservable();
}
