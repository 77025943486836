import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { environment } from '@video-env/environment';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VersionGuard implements CanActivate {
  /**
   * We only want to check once on startup of the application if the version is correct.
   * This should work out of the box, since we only apply the guard to the empty route (`path: ''`),
   * which will only be matched on startup. But it's better to be save than sorry.
   */
  static hasChecked = false;

  constructor(private router: Router, private http: HttpClient) {}

  canActivate(): Observable<boolean | UrlTree> {
    if (VersionGuard.hasChecked) {
      return of(true);
    }
    return this.http.get('assets/version.txt', { responseType: 'text', headers: { 'Cache-Control': 'no-cache' } }).pipe(
      map(newestVersion => {
        if (newestVersion !== environment.softwareVersion) {
          return this.router.parseUrl('/error-page/old-version');
        }
        VersionGuard.hasChecked = true;
        return true;
      }),
      take(1),
    );
  }
}
