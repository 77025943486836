import { AfterViewInit, Directive, Inject, Input, Self } from '@angular/core';

import { RED_SUBFORM_VALUE_ACCESSOR, SubformValueAccessor } from '../types/subform-value-accessor';
import { SubformArrayNameDirective } from './subform-array-name.directive';

@Directive({
  selector: '[redSubformArrayIndex]',
})
export class SubformArrayIndexDirective<T> implements AfterViewInit {
  @Input()
  redSubformArrayIndex: number;

  constructor(
    private subformArrayName: SubformArrayNameDirective<T>,
    @Self() @Inject(RED_SUBFORM_VALUE_ACCESSOR) private subformValueAccessor: SubformValueAccessor,
  ) {}

  ngAfterViewInit() {
    const subform = this.subformValueAccessor.subform;
    this.subformArrayName.attachSubform(this.redSubformArrayIndex, subform);
  }
}
