import {
  Component,
  Output,
  EventEmitter,
  forwardRef,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'red-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileChooserComponent), multi: true }],
})
export class FileChooserComponent implements ControlValueAccessor {
  @Output()
  change = new EventEmitter<ArrayBuffer | string>();

  @Input()
  disabled: boolean;

  @Input()
  accept: string;

  @Input()
  mode: 'binary' | 'dataUrl' = 'binary';

  @Input()
  chooseFileText = 'Datei auswählen';

  @Input()
  removeFileText = 'Datei entfernen';

  @ViewChild('fileChooser', { static: true })
  fileChooser: ElementRef<HTMLInputElement>;

  value: string | ArrayBuffer;

  constructor(private cdr: ChangeDetectorRef) {}

  // tslint:disable-next-line: no-empty
  onChange = (_value: ArrayBuffer | string) => {};
  // tslint:disable-next-line: no-empty
  onTouched = () => {};

  onChooseFile(files: FileList) {
    this.onTouched();
    if (files.length === 0) {
      return;
    }
    const file = files[0];
    const reader = new FileReader();

    // tslint:disable-next-line: no-this-assignment
    const self = this;

    reader.onload = function() {
      self.writeValue(this.result);
      self.onChange(self.value);
      // reset input filed to allow new selection of file
      self.fileChooser.nativeElement.value = '';
    };

    switch (this.mode) {
      case 'dataUrl':
        reader.readAsDataURL(file);
        break;
      case 'binary':
        reader.readAsArrayBuffer(file);
        break;

      default:
        throw new Error(`Mode ${this.mode} is not supported`);
    }
  }

  removeFile() {
    this.onChange(undefined);
    this.value = undefined;
  }

  writeValue(value: ArrayBuffer | string): void {
    this.value = value;
    this.change.emit(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
