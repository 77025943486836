import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { IDownloadable } from '@redngapps/shared/types';

@Injectable()
export class ZipService {
  async createZip(files: Array<IDownloadable>): Promise<Blob> {
    const zip = new JSZip();
    for (const file of files) {
      zip.file(file.fileName, file.content);
    }

    return await zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 4,
      },
    });
  }

  async createZipBase64(files: Array<IDownloadable>): Promise<string> {
    const zip = new JSZip();
    for (const file of files) {
      zip.file(file.fileName, file.content);
    }

    return await zip.generateAsync({
      type: 'base64',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 4,
      },
    });
  }
}
