import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChipCategory } from '@redngapps/shared/types';
import idx from 'idx';

@Component({
  selector: 'red-category-chip',
  templateUrl: './category-chip.component.html',
  styleUrls: ['./category-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryChipComponent {
  @Input() category: ChipCategory;

  @Input() showName = false;

  get style() {
    const color = idx(this.category, _ => _.color) || 'inherit';

    return {
      color,
      'border-color': color,
    };
  }
}
