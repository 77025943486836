import { Injectable } from '@angular/core';
import { environment } from '@video-env/environment';
import { Breadcrumb, CaptureContext } from '@sentry/types';
import { VssSentryErrorHandler } from './vss-sentry-error-handler.service';

@Injectable()
export class VSSSentryService {
  constructor(private sentryErrorHandler: VssSentryErrorHandler) {}

  handleError(error: any): void {
    if (environment.featureSwitches.sentry) {
      this.sentryErrorHandler.handleError(error);
    }
  }

  captureMessage(message: string, captureContext?: CaptureContext): void {
    this.sentryErrorHandler.captureMessage(message, captureContext);
  }

  addBreadcrumb(breadcrumb: Breadcrumb): void {
    this.sentryErrorHandler.addBreadcrumb(breadcrumb);
  }
}
