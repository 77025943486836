import { Injectable } from '@angular/core';
import { Logger, Severity } from './logger';
import { LoggerString } from './anonymized-logger.models';

@Injectable()
export class LoggerService extends Logger {
  log(loggerString: LoggerString, severity: Severity, error?: Error, ...metaData: any[]): void {
    switch (severity) {
      case Severity.Warning:
        if (metaData) {
          console.warn(loggerString.message, ...metaData);
        } else {
          console.warn(loggerString.message);
        }
        break;

      case Severity.Error:
        if (metaData) {
          if (error) {
            console.error(loggerString.message, error, ...metaData);
          } else {
            console.error(loggerString.message, ...metaData);
          }
        } else {
          if (error) {
            console.error(loggerString.message, error);
          } else {
            console.error(loggerString.message);
          }
        }
        break;

      default:
        if (metaData) {
          console.log(loggerString.message, ...metaData);
        } else {
          console.log(loggerString.message);
        }
        break;
    }
  }
}
