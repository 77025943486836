import { Injectable, OnDestroy } from '@angular/core';
import { ParticipantInfo } from '@redngapps/videosprechstunde/types';
import { Subject } from 'rxjs';
import { AudioContextService } from './audio-context.service';

@Injectable({ providedIn: 'root' })
export class AudioAnalyseService implements OnDestroy {
  onParticipantStartingTalking: Subject<ParticipantInfo> = new Subject();
  private currentTalkingParticipant: ParticipantInfo;

  private AUDIO_SAMPLING_FREQUENCY = 300; // ms
  private MIN_TALKING_TIME_FOR_MAIN_DISPLAY = 1500; // ms
  private destroyed = false;

  constructor(private audioContextService: AudioContextService) {}

  ngOnDestroy() {
    this.destroyed = true;
  }

  createAnalyser(audio: MediaStream): AnalyserNode {
    const analyser = this.audioContextService.getAudioContext().createAnalyser();
    const source = this.audioContextService.getAudioContext().createMediaStreamSource(audio);
    source.connect(analyser);
    analyser.fftSize = 512;

    return analyser;
  }

  listenIfParticipantIsTalking(participant: ParticipantInfo): void {
    participant.analyser = this.createAnalyser(participant.remoteStream);
    if (participant.looper) {
      clearTimeout(participant.looper);
    }
    const looper = () => {
      participant.looper = setTimeout(() => {
        const currentParticipantMaxVolume = this.getMaxVolumeOfAnalyser(participant.analyser);

        if (
          !this.currentTalkingParticipant ||
          currentParticipantMaxVolume > this.getMaxVolumeOfAnalyser(this.currentTalkingParticipant.analyser)
        ) {
          participant.timeActivelyTalking += this.AUDIO_SAMPLING_FREQUENCY;
          if (
            !this.currentTalkingParticipant ||
            participant.timeActivelyTalking >= this.MIN_TALKING_TIME_FOR_MAIN_DISPLAY
          ) {
            this.currentTalkingParticipant = participant;
            this.onParticipantStartingTalking.next(participant);
          }
        } else {
          participant.timeActivelyTalking = 0;
        }
        if (!this.destroyed) {
          looper();
        }
      }, this.AUDIO_SAMPLING_FREQUENCY);
    };
    looper();
  }

  stopListeningToParticipant(participant: ParticipantInfo): void {
    if (participant.looper) {
      clearTimeout(participant.looper);
    }
  }

  getMaxVolumeOfAnalyser(analyser?: AnalyserNode): number {
    let maxVolume = -Number.MAX_SAFE_INTEGER;

    if (analyser) {
      const fftBins = new Float32Array(analyser.frequencyBinCount);
      analyser.getFloatFrequencyData(fftBins);

      for (let i = 4; i < fftBins.length; i++) {
        if (fftBins[i] > maxVolume && fftBins[i] < 0) {
          maxVolume = fftBins[i];
        }
      }
    }
    return maxVolume;
  }

  /**
   * This method is creating an array of evaluated relation between sound volume and sound frequency.
   * The values of this array will be summed up and divided by the analyser.frequencyBinCount. The
   * result is the value for our volume progress bar
   * @param analyser
   */
  getStreamVolume(analyser: AnalyserNode): number {
    const fftBins = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(fftBins);

    const sum = fftBins.reduce((prev: number, current: number) => current + prev, 0);
    return sum / analyser.frequencyBinCount;
  }
}
