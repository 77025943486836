import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { KeyboardKeys } from '@redngapps/shared/types';
import idx from 'idx';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Time } from './time';
import { allOrNothingRequiredValidator } from '@redngapps/shared/util';
/** reading on ControlValueAccessor: https://medium.com/@vinothinikings/control-value-accessor-in-angular-dfc338ea0f18 */

@Component({
  selector: 'red-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TimeInputComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeInputComponent implements ControlValueAccessor, MatFormFieldControl<Time>, OnInit, OnDestroy {
  static nextId = 0;

  @Input()
  get value(): Time | null {
    return this.getTimeFromForm();
  }
  set value(val: Time | null) {
    const formVal = this.getFormFromTime(val);
    this.parts.setValue(formVal);
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(val: string) {
    this._placeholder = val;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  // feels like this should be done by `setDisabledState()`
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // we don't want to trigger valueChanges here
    this._disabled
      ? this.parts.disable({ onlySelf: true, emitEvent: false })
      : this.parts.enable({ onlySelf: true, emitEvent: false });
    this.stateChanges.next();
  }

  get empty(): boolean {
    const {
      value: { hours, minutes },
    } = this.parts;
    return !hours && !minutes;
  }

  get errorState(): boolean {
    return (this.ngControl.touched && this.ngControl.invalid) || (this.parts.touched && this.parts.invalid);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding() id = `red-time-input-${TimeInputComponent.nextId++}`;

  @HostBinding('attr.aria-describedby') describedBy = '';

  @ViewChild('hoursInput', { static: true }) hoursInput: ElementRef;
  @ViewChild('minutesInput', { static: true }) minutesInput: ElementRef;

  controlType = 'red-time-input';

  parts: FormGroup;
  focused = false;

  stateChanges = new Subject<void>();
  private unsubscribe = new Subject<void>();
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  private numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  private functionalKeys = [
    KeyboardKeys.Tab,
    KeyboardKeys.Backspace,
    KeyboardKeys.ArrowLeft,
    KeyboardKeys.ArrowRight,
    KeyboardKeys.Delete,
  ];

  constructor(
    fb: FormBuilder,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = fb.group(
      {
        hours: [null, [Validators.min(0), Validators.max(23)], []],
        minutes: [null, [Validators.min(0), Validators.max(59)]],
      },
      // we have to use this approach, because implementing and providing the `Validator` interface would cause a cyclic dependency error
      { validator: allOrNothingRequiredValidator(['hours', 'minutes']) },
    );

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }

      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    this.parts.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      const time = this.getTimeFromForm();
      this.onChange(time);
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  // tslint:disable-next-line:no-empty
  onChange = (_: any) => {};
  // tslint:disable-next-line:no-empty
  onTouched = () => {};

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    // When user clicks on the element the focus should jump to the hours field.
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.hoursInput.nativeElement.focus();
    }
  }

  /**
   * Update the value of the form control (Send from the parent component).
   * @param obj
   */
  writeValue(obj: Time | null): void {
    this.value = obj;
  }

  /**
   * The control’s value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Interaction with the UI element e.g, blur.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onHoursDown($event: KeyboardEvent): void {
    this.preventInvalidChar($event.key, $event);
    this.preventMax($event.key, $event, 23, this.hoursInput);
    const lengthPrevented = this.preventLength($event.key, $event, this.hoursInput);

    // when there are already 2 hour digits, put the typed one into the minutes field
    if (lengthPrevented) {
      this.minutesInput.nativeElement.focus();
      this.parts.controls.minutes.setValue($event.key);
    }

    // If the cursor is at the end of the input and use clicks arrow right, then switch to minutes input.
    if (
      $event.key === KeyboardKeys.ArrowRight &&
      this.hoursInput.nativeElement.selectionStart === this.hoursInput.nativeElement.selectionEnd &&
      this.hoursInput.nativeElement.selectionStart === this.hoursInput.nativeElement.value.length
    ) {
      this.minutesInput.nativeElement.focus();
      // Timeout is required because we're still inside of event handler.
      setTimeout(() => {
        this.minutesInput.nativeElement.select();
      }, 0);
    }
  }

  onMinutesDown($event: KeyboardEvent): void {
    this.preventInvalidChar($event.key, $event);
    this.preventMax($event.key, $event, 59, this.minutesInput);

    // If backspace is typed in empty minute field -- jump to hours field.
    if (!this.parts.value.minutes && $event.key === KeyboardKeys.Backspace) {
      this.hoursInput.nativeElement.focus();
    }

    // If the cursor is at the beginning of the input and use clicks arrow left, then switch to hours input.
    if (
      $event.key === KeyboardKeys.ArrowLeft &&
      this.minutesInput.nativeElement.selectionStart === this.minutesInput.nativeElement.selectionEnd &&
      this.minutesInput.nativeElement.selectionStart === 0
    ) {
      this.hoursInput.nativeElement.focus();
      // Timeout is required because we're still inside of event handler.
      setTimeout(() => {
        this.hoursInput.nativeElement.select();
      }, 0);
    }
  }

  private preventLength(insertedChar: string, event: Event, el: ElementRef): boolean {
    if (this.numbers.some(n => n === insertedChar)) {
      const newValue = this.getPotentialValue(el, insertedChar);
      if (newValue && newValue.length > 2) {
        event.preventDefault();
        return true;
      }
    }
    return false;
  }

  private preventInvalidChar(insertedChar: string, event: Event): void {
    const validChars = [...this.functionalKeys, ...this.numbers];

    if (!validChars.some(c => c === insertedChar)) {
      event.preventDefault();
    }
  }

  private preventMax(insertedChar: string, event: Event, max: number, el: ElementRef): void {
    if (this.numbers.some(n => n === insertedChar)) {
      const newVal: number = Number(this.getPotentialValue(el, insertedChar));
      if (newVal > max) {
        event.preventDefault();
      }
    }
  }

  /**
   * Returns the new value of the input-field, assuming that the typed key is valid
   * and taking into account, that selected text is overwritten.
   */
  private getPotentialValue(el: ElementRef, insertedChar: string): string {
    if (!el || !el.nativeElement) {
      return undefined;
    }

    return this.replaceRange(
      el.nativeElement.value,
      insertedChar,
      el.nativeElement.selectionStart,
      el.nativeElement.selectionEnd,
    );
  }

  private replaceRange(original: string, replacement: string, from: number, to: number = from) {
    return `${original.substr(0, from)}${replacement}${original.substr(to, original.length - to)}`;
  }

  private getTimeFromForm(): Time | null {
    if (this.parts.invalid) {
      return null;
    }

    const hours = parseInt(this.parts.value.hours, 10);
    const minutes = parseInt(this.parts.value.minutes, 10);

    if (isNaN(hours) || isNaN(minutes)) {
      return null;
    }

    return {
      hours,
      minutes,
    };
  }

  private getFormFromTime(time: Time | null | undefined): { hours: string; minutes: string } {
    const hoursTemp = idx(time, _ => _.hours);
    let hours;
    switch (hoursTemp) {
      case 0:
        hours = '00';
        break;

      case undefined:
      case null:
        hours = null;
        break;

      default:
        hours = `${hoursTemp}`;
        break;
    }

    const minutesTemp = idx(time, _ => _.minutes);
    let minutes;
    switch (minutesTemp) {
      case null:
      case undefined:
        minutes = null;
        break;

      default:
        minutes = `${minutesTemp}`.padStart(2, '0');
        break;
    }

    return {
      hours,
      minutes,
    };
  }
}
