import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
  redLet: T;
  $implicit?: T;
}

@Directive({
  selector: '[redLet]',
})
export class LetDirective<T> {
  private context: LetContext<T> = {
    redLet: undefined,
    $implicit: undefined,
  };

  constructor(viewContainer: ViewContainerRef, templateRef: TemplateRef<LetContext<T>>) {
    viewContainer.createEmbeddedView(templateRef, this.context);
  }

  @Input()
  set redLet(value: T) {
    this.context.redLet = value;
    this.context.$implicit = value;
  }
}
