import { Injectable } from '@angular/core';
import { Router, UrlTree, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { BROWSERS, DeviceDetectorService, OS } from 'ngx-device-detector';
import { BrowserDetectionService } from '@redngapps/shared/util';
import { BrowserState } from '@redngapps/videosprechstunde/feature';

@Injectable({ providedIn: 'root' })
export class SupportedBrowserGuard implements CanActivate {
  constructor(
    private browserDetectionService: BrowserDetectionService,
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const browserState = route.data.browserState;
    const tree = this.router.parseUrl('/login');

    const deviceInfo = this.deviceService.getDeviceInfo();
    const isMobileApple =
      [OS.MAC, OS.IOS].includes(deviceInfo.os) && 'ontouchstart' in window && 'onorientationchange' in window;

    if (browserState === BrowserState.iOSUnsupported && (!isMobileApple || deviceInfo.browser === BROWSERS.SAFARI)) {
      return tree;
    }

    if (
      browserState === BrowserState.unsupportedSafari &&
      ([OS.MAC, OS.IOS].includes(deviceInfo.os) || deviceInfo.browser !== BROWSERS.SAFARI)
    ) {
      return tree;
    }

    if (
      browserState === BrowserState.outdated &&
      !this.browserDetectionService.isUserBrowserSupportedAndOutdatedVersion()
    ) {
      return tree;
    }
    if (
      browserState === BrowserState.unsupported &&
      [BROWSERS.SAFARI, BROWSERS.CHROME, BROWSERS.FIREFOX, BROWSERS.SAMSUNG].includes(deviceInfo.browser)
    ) {
      return tree;
    }

    return true;
  }
}
