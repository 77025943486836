import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IWikiPageContent } from '@redngapps/shared/types';

@Injectable()
export class ConfluenceService {
  private readonly ENDPOINT_URL = 'https://hilfe.redmedical.de/';

  constructor(private http: HttpClient) {}

  getPageContent(pageId: number): Observable<IWikiPageContent> {
    const url = this.createPageContentLink(pageId);

    return this.http.get(url).pipe(
      map((page): IWikiPageContent => page as IWikiPageContent),
      map(page => {
        page.body.view.value = page.body.view.value.replace(/<p>/gi, '<p class="mat-body-1">');
        return page;
      }),
    );
  }

  private createLink(uri: string): string {
    return this.ENDPOINT_URL + uri;
  }

  private createPageContentLink(pageId: number): string {
    return this.createLink(`rest/api/content/${pageId}?expand=body.view`);
  }
}
