import { WorkflowItem } from './models/workflow.models';

export abstract class WorkflowSource {
  /**
   * Callback called when the next step should be shown.
   * @param previousResult the result of the previous step or `undefined` if this is the first step.
   */
  abstract getNextWorkflowItem(previousResult: any): WorkflowItem;
  abstract onWorkflowEnd(result: any): void;
  abstract onWorkflowError(err: any): void;
}
