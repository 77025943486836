import { Component, Input } from '@angular/core';

@Component({
  selector: 'red-autocomplete-arrow',
  templateUrl: './autocomplete-arrow.component.html',
  styleUrls: ['./autocomplete-arrow.component.scss'],
})
export class AutocompleteArrowComponent {
  @Input()
  open = false;
}
