import { Injectable } from '@angular/core';
import { IDownloadable } from '@redngapps/shared/types';
import { ZipService } from './zip.service';

@Injectable()
export class DownloadService {
  constructor(private readonly zipService: ZipService) {}

  downloadFile(file: IDownloadable, mimeType = 'application/octet-stream'): void {
    const blob = new Blob([file.content]);
    this.downloadBlob(blob, file.fileName, mimeType);
  }

  async downloadFilesAsZip(files: Array<IDownloadable>, zipName: string): Promise<void> {
    const blob: Blob = await this.zipService.createZip(files);
    this.downloadBlob(blob, zipName, 'application/zip');
  }

  private downloadBlob(blob: Blob, fileName: string, mimeType: string): void {
    const a = document.createElement('a');
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.setAttribute('download', fileName);
    a.dataset.downloadurl = [mimeType, a.download, a.href].join(':');
    a.draggable = true;
    a.classList.add('dragout');
    // just a.click() not working on firefox
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  }
}
