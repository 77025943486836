import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureDetectionService {
  isIndexDbSupported$: AsyncSubject<boolean> = new AsyncSubject();

  constructor() {
    this.determineIfIndexIsSupported();
  }

  /*
   * To determine if IndexDB is supported the client tries to open a test connection.
   * If it fails IndexDB is not available on this browser.
   */
  determineIfIndexIsSupported(): void {
    try {
      const request: IDBOpenDBRequest = window.indexedDB.open('feature-detection');
      request.onsuccess = () => {
        this.isIndexDbSupported$.next(true);
        this.isIndexDbSupported$.complete();
      };
      request.onerror = () => {
        this.isIndexDbSupported$.next(false);
        this.isIndexDbSupported$.complete();
      };
    } catch (err) {
      this.isIndexDbSupported$.next(false);
      this.isIndexDbSupported$.complete();
    }
  }
}
