import { parseUrl } from '@redngapps/shared/util';
import * as Sentry from '@sentry/browser';
import { HttpErrorResponse } from '@angular/common/http';

const queryStringPattern = /\?(.*=.+)*(&.*=.+)?/;

export interface BreadcrumbWhitelistElement {
  category: string;
  allowedMetadataProperties: string[];
}

/**
 * Replaces the value of each given parameter with `'[not shown]'`.
 * @param url
 * @param parameterNames array of names of the parameters, which values should be replaced
 */
export const anonymizeUrlParameter = (url: string, parameterNames: string[]): string => {
  const parsedUrl = parseUrl(url);

  const joinedParameters = parsedUrl.parameters.map(parameter => {
    if (parameterNames.includes(parameter.name)) {
      // anonymize parameter value if it's name is contained in the given list of parameter names
      parameter.value = '[not shown]';
    }
    return `${parameter.name}=${parameter.value}`;
  });

  // reassemble the individual parts of the URL to a single string
  const parameters: string = joinedParameters && joinedParameters.length ? `?${joinedParameters.join('&')}` : '';

  return `${parsedUrl.path}${parameters}`;
};

export const filterEvent = (
  event: Sentry.Event,
  breadcrumbWhitelist: Array<BreadcrumbWhitelistElement>,
  urlParameterBlacklist: Array<string>,
): Sentry.Event => {
  try {
    event.breadcrumbs = anonymizeBreadcrumbs(event.breadcrumbs, breadcrumbWhitelist, urlParameterBlacklist);
  } catch (e) {
    event.breadcrumbs = [];
  }

  try {
    event.request.url = anonymizeUrlParameter(event.request.url, urlParameterBlacklist);
  } catch (e) {
    event.request.url = '---';
  }

  return event;
};

export const extractError = (error: any) => {
  // Try to unwrap zone.js error.
  // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
  if (error && error.ngOriginalError) {
    // tslint:disable-next-line:no-parameter-reassignment
    error = error.ngOriginalError;
  }

  // Sentry can handle messages and Error objects directly.
  if (typeof error === 'string' || error instanceof Error) {
    return error;
  }

  // If it's http module error, extract as much information from it as we can.
  if (error instanceof HttpErrorResponse) {
    // The `error` property of http exception can be either an `Error` object, which we can use directly...
    if (error.error instanceof Error) {
      return error.error;
    }

    // ... or an`ErrorEvent`, which can provide us with the message but no stack...
    if (error.error instanceof ErrorEvent) {
      return error.error.message;
    }

    // ...or the request body itself, which we can use as a message instead.
    if (typeof error.error === 'string') {
      return `Server returned code ${error.status} with body "${error.error}"`;
    }

    // If we don't have any detailed information, fallback to the request message itself.
    return error.message;
  }

  // Skip if there's no error, and let user decide what to do with it.
  return null;
};

const anonymizeBreadcrumbs = (
  breadcrumbs: Sentry.Breadcrumb[],
  breadcrumbWhitelist: Array<BreadcrumbWhitelistElement>,
  urlParameterBlacklist: Array<string> = [],
): Sentry.Breadcrumb[] => {
  return breadcrumbs
    .filter(breadcrumb => {
      return breadcrumbWhitelist.some(whitelist => breadcrumb && breadcrumb.category === whitelist.category);
    })
    .map(breadcrumb => {
      // anonymize properties which are not whitelisted, ui events don't have a data object
      if (breadcrumb.data) {
        Object.keys(breadcrumb.data).forEach(metaDataKey => {
          const whitelistedProperties = breadcrumbWhitelist.find(
            whitelist => breadcrumb.category === whitelist.category,
          );
          if (!whitelistedProperties.allowedMetadataProperties.includes(metaDataKey)) {
            breadcrumb.data[metaDataKey] = '[not shown]';
          } else {
            if (
              typeof breadcrumb.data[metaDataKey] === 'string' &&
              queryStringPattern.test(breadcrumb.data[metaDataKey])
            ) {
              breadcrumb.data[metaDataKey] = anonymizeUrlParameter(breadcrumb.data[metaDataKey], urlParameterBlacklist);
            }
          }
        });
      }
      return breadcrumb;
    });
};
