import { Injectable } from '@angular/core';
import { CodeType, License, LicenseId } from '@redngapps/videosprechstunde/types';
import idx from 'idx';

export interface IAuthState {
  isLoggedIn: boolean;
  userName: string;
  accessCode: string;
  codeType: CodeType;
  license: License;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get codeType(): CodeType {
    return this._codeType;
  }
  get license(): License {
    return this._license;
  }
  private userName: string;
  private accessCode: string;
  private isLoggedIn: boolean;
  private _codeType: CodeType;
  private _license: License;

  // TODO: there is absolutly no reason why these methods are asynchronus - so they shouldn't be

  async getAuthState(): Promise<IAuthState> {
    return Promise.resolve({
      isLoggedIn: this.isLoggedIn,
      userName: this.userName,
      accessCode: this.accessCode,
      codeType: this._codeType,
      license: this._license,
    });
  }

  async login(userName: string, accessCode: string, codeType: CodeType, license: License): Promise<IAuthState> {
    this.userName = userName;
    this.accessCode = accessCode;
    this.isLoggedIn = true;
    this._codeType = codeType;
    this._license = license;

    return Promise.resolve({
      accessCode,
      userName,
      codeType,
      license,
      isLoggedIn: true,
    });
  }

  async logout(): Promise<void> {
    this.isLoggedIn = false;
    this.userName = null;
    this.accessCode = null;
    this._codeType = null;
    this._license = null;

    return Promise.resolve();
  }

  isConnectPlusLicense(): boolean {
    return idx(this, _ => _.license.id) === LicenseId.ConnectPlus;
  }
}
