import { Injectable } from '@angular/core';
import { environment } from '@video-env/environment';
import { EEnvironment } from '@video-env/environment.enum';
import { AbstractSentryErrorHandler } from '@redngapps/shared/sentry/data-access';

const urlParameterBlacklist: Array<string> = ['name', 'code'];

const breadcrumbWhitelist: Array<{ category: string; allowedMetadataProperties: string[] }> = [
  {
    category: 'logger',
    allowedMetadataProperties: [],
  },
  {
    category: 'navigation',
    allowedMetadataProperties: ['from', 'prevState', 'state', 'title', 'to'],
  },
  {
    category: 'xhr',
    allowedMetadataProperties: ['method', 'status_code', 'url'],
  },
  {
    category: 'ui.click',
    allowedMetadataProperties: [],
  },
  {
    category: 'ui.input',
    allowedMetadataProperties: [],
  },
];

@Injectable()
export class VssSentryErrorHandler extends AbstractSentryErrorHandler {
  constructor() {
    super({
      breadcrumbWhitelist,
      urlParameterBlacklist,
      dsn: 'https://356f87bcbb8a4bc0927463c3d8a35dfe@sentry.redmedical.de/3',
      release: environment.name === EEnvironment.production ? environment.softwareVersion : environment.gitHash,
      environment: environment.name,
    });
  }

  /**
   * Currently no additional Metadata is set for the VSS
   */
  // tslint:disable-next-line:no-empty
  setMetadata(): void {}
}
