// tslint:disable-next-line: no-empty-interface
export interface LogSafeValue {}

export class LogSafeValueImpl implements LogSafeValue {
  constructor(public value: unknown) {}
}

export class LoggerString {
  constructor(public message: string) {}
}
