import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IWebrtcConfig,
  IRedRTCEvent,
  ISignalingMessage,
  IRedDataChannelMessage,
  Participant,
  ParticipantInfo,
  AuthData,
} from '@redngapps/videosprechstunde/types';
import { SignalingService } from './signaling.service';
import { AuthService } from './auth.service';
import { Logger, logMessage, logSafe } from '@redngapps/shared/util';
import { RED_WEBRTC_CONFIG } from '../webrtc-config';
import { MediaService } from './media.service';

@Injectable({
  providedIn: 'root',
})
export class WebRtcService implements OnDestroy {
  onError: ReplaySubject<IRedRTCEvent> = new ReplaySubject(1);
  onPeerJoinedRoom: ReplaySubject<IRedRTCEvent> = new ReplaySubject(1);
  onPeerUpdate: ReplaySubject<ParticipantInfo> = new ReplaySubject(1);
  onJoinedRoom: ReplaySubject<AuthData> = new ReplaySubject(1);
  onStunConnectionFailed: Subject<string> = new Subject();
  onLeave: EventEmitter<void> = new EventEmitter();
  roomTitle: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  private participants: { [socketId: string]: Participant };

  private connected = false;
  private localStream: MediaStream;
  private mediaTrack: MediaStreamTrack;
  private socketId: string;

  private unsubscribe = new Subject();

  constructor(
    @Inject(RED_WEBRTC_CONFIG) private config: IWebrtcConfig,
    private signalingService: SignalingService,
    private authService: AuthService,
    private logger: Logger,
    private mediaService: MediaService,
  ) {
    try {
      this.participants = {};

      this.listenToSignaling();
    } catch (err) {
      this.logger.error(logMessage`could not initialize webrtc`, err);
    }
  }

  ngOnDestroy(): void {
    this.disconnectLocalTracks();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /** Make sure service-state is reset to initial state. So no events from old calls would be re-emitted on new subscriptions. */
  reset(): void {
    // make sure no subscriptions are kept open
    this.onError.complete();
    this.onPeerJoinedRoom.complete();
    this.onPeerUpdate.complete();
    this.onJoinedRoom.complete();

    // reinitialize replay subjects
    this.participants = {};
    this.onError = new ReplaySubject(1);
    this.onPeerJoinedRoom = new ReplaySubject(1);
    this.onPeerUpdate = new ReplaySubject(1);
    this.onJoinedRoom = new ReplaySubject(1);
  }

  async createOrJoin(roomId: string): Promise<void> {
    this.socketId = sessionStorage.getItem('socketId');

    return this.signalingService.join(roomId);
  }

  async initLocalStream() {
    this.localStream = await this.mediaService.getLocalUserMediaStream();

    // make sure other participants get notified about our tracks
    for (const participantId in this.participants) {
      if (this.participants.hasOwnProperty(participantId)) {
        const participant = this.participants[participantId];
        this.localStream.getTracks().forEach(track => {
          participant.localTracks.push(participant.pc.addTrack(track, this.localStream));
        });
        this.emitCameraState(participantId);
      }
    }
  }

  setLocalStream(mediaStream?: MediaStream) {
    this.localStream = mediaStream;
  }

  setConnected(connected: boolean): void {
    this.connected = connected;
  }

  isConnected(): boolean {
    return this.connected;
  }

  /**
   * Send a {@link IRedDataChannelMessage} message over RTCPeerConnection data channel.
   * @param message message to send
   * @param receiverParticipantId Participant id to send data to.
   */
  sendDataChannelMessage(message: IRedDataChannelMessage, receiverParticipantId?: string): void {
    if (receiverParticipantId) {
      try {
        this.participants[receiverParticipantId].dataChannel.send(JSON.stringify(message));
      } catch (e) {
        this.logger.info(logMessage`Couldn't send message over data channel to ${logSafe(receiverParticipantId)}`, e);
      }
    } else {
      for (const participantId in this.participants) {
        if (this.participants.hasOwnProperty(participantId)) {
          try {
            this.participants[participantId].dataChannel.send(JSON.stringify(message));
          } catch (e) {
            this.logger.info(logMessage`Couldn't send message over data channel to ${logSafe(participantId)}`, e);
          }
        }
      }
    }
  }

  disconnectLocalTracks() {
    for (const participantId in this.participants) {
      if (this.participants.hasOwnProperty(participantId)) {
        while (this.participants[participantId].localTracks.length) {
          const sender = this.participants[participantId].localTracks.pop();
          this.participants[participantId].pc.removeTrack(sender);
        }
      }
    }
  }

  /**
   * Notify the signalling server and participants that current participant is leaving.
   * E.g. "Auflegen"
   */
  leave() {
    this.setConnected(false);
    this.disconnectLocalTracks();
    for (const participantId in this.participants) {
      if (this.participants.hasOwnProperty(participantId)) {
        this.participants[participantId].pc.close();
      }
    }
    this.removeScreensharingTrack();
    this.signalingService.leave();
    this.onLeave.next();
  }

  reconnectOverTurn(participantId: string): void {
    if (this.participants[participantId]) {
      this.participants[participantId].selfPcReconnected = true;
      if (this.participants[participantId].peerPcReconnected) {
        this.restartPeerConnection(false, participantId);
      }
      this.signalingService.sendReconnectMessage(this.socketId, participantId);
    }
  }

  /**
   * Notify participants over data channel, that camera state has been toggled (on/off).
   * @param isOff
   */
  notifyCameraToggle(isOff: boolean): void {
    this.sendDataChannelMessage({ content: isOff, type: 'cameraToggle', socketId: this.socketId });
  }

  /**
   * Used to add a screensharing video track to peers' connection.
   * @param track
   */
  addScreensharingTrack(track: MediaStreamTrack): void {
    this.mediaTrack = track;
    for (const participantId in this.participants) {
      if (this.participants.hasOwnProperty(participantId)) {
        this.participants[participantId].mediaTracks.push(this.participants[participantId].pc.addTrack(track));
      }
    }
  }

  /**
   * Removes screensharing video track from peers' connections.
   */
  removeScreensharingTrack(): void {
    delete this.mediaTrack;
    for (const participantId in this.participants) {
      if (this.participants.hasOwnProperty(participantId)) {
        while (this.participants[participantId].mediaTracks.length) {
          const sender = this.participants[participantId].mediaTracks.pop();
          sender.track.stop();
          this.participants[participantId].pc.removeTrack(sender);
        }
      }
    }
    this.emitSharingEnded();
  }

  /**
   * Replaces an existing camera and microphone stream in peer connections.
   * @param newStream
   */
  async changeAudioVideoStream(newStream: MediaStream): Promise<void> {
    this.localStream = newStream;

    const videoTracks = newStream.getVideoTracks();
    const audioTracks = newStream.getAudioTracks();

    for (const participantId in this.participants) {
      if (this.participants.hasOwnProperty(participantId)) {
        const participant = this.participants[participantId];

        if (videoTracks.length) {
          const sender = participant.localTracks.find(s => s.track.kind === videoTracks[0].kind);
          if (sender) {
            await sender.replaceTrack(videoTracks[0]);
          }
        }

        if (audioTracks.length) {
          const sender = participant.localTracks.find(s => s.track.kind === audioTracks[0].kind);
          if (sender) {
            await sender.replaceTrack(audioTracks[0]);
          }
        }
      }
    }
  }

  private listenToSignaling(): void {
    this.signalingService.onConnectionError.pipe(takeUntil(this.unsubscribe)).subscribe((err: IRedRTCEvent) => {
      this.onError.next(err);
      this.disconnectLocalTracks();
    });

    this.signalingService.onError.pipe(takeUntil(this.unsubscribe)).subscribe((err: IRedRTCEvent) => {
      this.onError.next(err);
    });

    this.signalingService.onPeerLeftRoom.pipe(takeUntil(this.unsubscribe)).subscribe((event: IRedRTCEvent) => {
      if (event && this.participants[event.socketId]) {
        const participant = this.participants[event.socketId];
        delete participant.remoteStream;
        while (participant.localTracks.length) {
          const track = participant.localTracks.pop();
          participant.pc.removeTrack(track);
        }
        participant.pc.close();
        delete this.participants[event.socketId];
      }
    });

    this.signalingService.onSignalingMessage
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: ISignalingMessage) => this.onSignalingMessage(message));
  }

  private onSignalingMessage(msg: ISignalingMessage): void {
    if (msg && msg.type === 'joined') {
      this.onJoinedRoom.next({
        codeType: msg.codeType,
        license: msg.license,
        roomTitle: msg.roomTitle,
      });
    } else if (msg && msg.type === 'newJoined') {
      this.startWebRTC(true, msg.socketId);
    } else if (msg && msg.sdp) {
      if (!this.participants[msg.socketId]) {
        if (msg.sdp.type === 'offer') {
          this.startWebRTC(false, msg.socketId);
        }
      }
      // This is called after receiving an offer or answer from another peer
      this.onPeerJoinedRoom.next({ socketId: msg.socketId });
      const pc = this.participants[msg.socketId].pc;
      pc.setRemoteDescription(msg.sdp)
        .then(() => {
          // When receiving an offer lets answer it
          if (pc.remoteDescription && pc.remoteDescription.type === 'offer') {
            pc.createAnswer()
              .then(desc => this.localDescCreated(desc, msg.socketId))
              .catch(err => this.onError.next(err));
          }
        })
        .catch(err => this.onError.next(err));
    } else if (msg && msg.candidate) {
      // Add the new ICE candidate to our connections remote description
      this.participants[msg.socketId].pc
        .addIceCandidate(new RTCIceCandidate(msg.candidate))
        .catch(err => this.onError.next(err));
    } else if (msg && msg.type === 'reconnectPc') {
      if (this.participants[msg.socketId]) {
        this.participants[msg.socketId].peerPcReconnected = true;
        if (this.participants[msg.socketId].selfPcReconnected) {
          this.restartPeerConnection(true, msg.socketId);
        }
      }
    } else {
      this.logger.error(logMessage`Unknown socket message: ${logSafe(msg.type)}`);
    }
  }

  private startWebRTC(isOfferer: boolean, participantId: string, stunOnly = true) {
    const participant: Participant = {
      pc: new RTCPeerConnection(stunOnly ? this.config.peerConfigStunOnly : this.config.peerConfig),
      selfPcReconnected: false,
      peerPcReconnected: false,
      dataChannel: {} as RTCDataChannel,
      currentConnectionIsStunOnly: stunOnly,
      cameraOff: false,
      remoteScreenVideoTrack: null,
      stunConnectionSucceeded: false,
      localTracks: [],
      mediaTracks: [],
    };
    this.participants[participantId] = participant;

    const pc = participant.pc;
    // 'onicecandidate' notifies us whenever an ICE agent needs to deliver a
    // message to the other peer through the signaling server
    pc.addEventListener('icecandidate', event => {
      if (event.candidate && event.candidate.candidate && event.candidate.candidate.length > 0) {
        this.signalingService.sendCandidate(event.candidate, this.socketId, participantId);
      }
    });

    // If user is offerer let the 'negotiationneeded' event create the offer
    if (isOfferer) {
      pc.addEventListener('negotiationneeded', () => this.createAndSendOffer(pc, participantId));
      participant.dataChannel = pc.createDataChannel('dataChannel');
      this.addDataChannelListeners(participantId);
    } else {
      pc.addEventListener('datachannel', event => {
        participant.dataChannel = event.channel;
        this.addDataChannelListeners(participantId);

        pc.addEventListener('negotiationneeded', () => this.createAndSendOffer(pc, participantId));
      });
    }

    pc.addEventListener('track', event => {
      const stream = event.streams[0];
      if (!stream && !this.mediaTrack) {
        // screen being shared
        participant.remoteScreenVideoTrack = event.track;
        this.emitUpdate(participantId);
      } else if (!participant.remoteStream) {
        participant.remoteStream = stream;
        this.emitUpdate(participantId);
      }
    });

    pc.addEventListener('iceconnectionstatechange', () => this.onIceConnectionStateChange(participantId));

    if (this.localStream) {
      this.localStream.getTracks().forEach(track => {
        participant.localTracks.push(pc.addTrack(track, this.localStream));
      });
    }

    if (this.mediaTrack) {
      participant.mediaTracks.push(pc.addTrack(this.mediaTrack));
    }
  }

  private createAndSendOffer(pc: RTCPeerConnection, participantId: string): void {
    if (pc.signalingState !== 'stable') {
      return;
    }
    pc.createOffer()
      .then(desc => this.localDescCreated(desc, participantId))
      .catch(err => this.onError.next(err));
  }

  private localDescCreated(desc: RTCSessionDescriptionInit, receiver: string) {
    this.participants[receiver].pc
      .setLocalDescription(desc)
      .then(() => {
        this.signalingService.sendDescription(this.participants[receiver].pc.localDescription, this.socketId, receiver);
      })
      .catch(err => this.onError.next(err));
  }

  private onIceConnectionStateChange(socketId: string) {
    const participant = this.participants[socketId];
    this.logger.info(
      logMessage`ICE state: ${logSafe(participant && participant.pc && participant.pc.iceConnectionState)}`,
    );
    if (
      participant &&
      participant.pc &&
      ['disconnected', 'failed'].indexOf(participant.pc.iceConnectionState) > -1 &&
      !this.participants[socketId].stunConnectionSucceeded
    ) {
      this.logger.info(logMessage`Peer connection failed - start new connection`);
      this.onStunConnectionFailed.next(socketId);
    }
    if (participant && participant.pc && participant.pc.iceConnectionState === 'connected') {
      participant.selfPcReconnected = false;
      participant.peerPcReconnected = false;
      if (this.participants[socketId].currentConnectionIsStunOnly) {
        this.participants[socketId].stunConnectionSucceeded = true;
      }
    }
  }

  private addDataChannelListeners(socketId: string) {
    const participant = this.participants[socketId];
    participant.dataChannel.addEventListener('open', () => {
      this.emitName(socketId);
      this.emitCameraState(socketId);
    });
    participant.dataChannel.addEventListener('error', err => this.onError.next(err as IRedRTCEvent));
    participant.dataChannel.addEventListener('message', e => {
      try {
        const message = JSON.parse(e.data);
        switch (message.type) {
          case 'broadcastUsername':
            this.saveParticipantName(message);
            break;
          case 'cameraToggle':
            this.saveCameraState(message);
            break;
          case 'sharingEnded':
            participant.remoteScreenVideoTrack = null;
            this.emitUpdate(socketId);
            break;
          default:
            this.logger.error(logMessage`Unknown message type ${logSafe(message.type)}`);
            break;
        }
      } catch (e) {
        this.logger.error(logMessage`Error while parsing message`, e);
      }
    });
  }

  private emitCameraState(receiver: string): void {
    this.sendDataChannelMessage(
      {
        type: 'cameraToggle',
        content: this.localStream ? this.localStream.getVideoTracks().filter(e => e.enabled).length === 0 : true,
        socketId: this.socketId,
      },
      receiver,
    );
  }

  private emitSharingEnded(): void {
    this.sendDataChannelMessage({
      type: 'sharingEnded',
      socketId: this.socketId,
    });
  }

  private async emitName(receiver: string): Promise<void> {
    this.sendDataChannelMessage(
      {
        type: 'broadcastUsername',
        content: (await this.authService.getAuthState()).userName,
        socketId: this.socketId,
      },
      receiver,
    );
  }

  private saveParticipantName(message: IRedDataChannelMessage): void {
    if (this.participants[message.socketId] && !this.participants[message.socketId].name) {
      this.participants[message.socketId].name = message.content as string;
      this.emitUpdate(message.socketId);
    }
  }

  private saveCameraState(message: IRedDataChannelMessage): void {
    if (this.participants[message.socketId]) {
      this.participants[message.socketId].cameraOff = message.content as boolean;
      this.emitUpdate(message.socketId);
    }
  }

  private restartPeerConnection(isOfferer: boolean, participantId: string) {
    delete this.participants[participantId].remoteStream;
    while (this.participants[participantId].localTracks.length) {
      const track = this.participants[participantId].localTracks.pop();
      this.participants[participantId].pc.removeTrack(track);
    }
    this.participants[participantId].pc.close();
    delete this.participants[participantId];
    this.startWebRTC(isOfferer, participantId, false);
  }

  private emitUpdate(participantId: string): void {
    const participant = this.participants[participantId];
    if (participant.name && participant.remoteStream) {
      this.onPeerUpdate.next({
        name: participant.name,
        remoteStream: participant.remoteStream,
        cameraOff: participant.cameraOff,
        remoteScreenVideoTrack: participant.remoteScreenVideoTrack,
        id: participantId,
        timeActivelyTalking: 0,
        isRemoteSharedScreen: false,
      });
    }
  }
}
