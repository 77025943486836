import { Directive, ViewContainerRef } from '@angular/core';

/**
 * Placeholder for a dynamically loaded component that will be shown in sidenav menu.
 */
@Directive({
  selector: '[redSidenavContent]',
})
export class SidenavContentDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
