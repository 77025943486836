import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Pipe({
  name: 'hasRequiredValidator',
  pure: true,
})
export class HasRequiredValidatorPipe implements PipeTransform {
  transform(validator: ValidatorFn | null): boolean {
    if (!validator) {
      return false;
    }
    const validationErrors = validator({} as AbstractControl);
    return !!(validationErrors && validationErrors.required);
  }
}
