import { ScrollStrategy, ScrollDispatcher } from '@angular/cdk/overlay';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { ElementRef, NgZone } from '@angular/core';
import { RepositionScrollableScrollStrategy } from './reposition-scrollable-scroll-strategy';
import { ViewportRuler } from '@angular/cdk/scrolling';

export function redPharmaDatenerfassungAutocompleteScrollStrategyFactory(
  scrollDispatcher: ScrollDispatcher,
  viewportRuler: ViewportRuler,
  ngZone: NgZone,
  element: ElementRef,
): () => ScrollStrategy {
  return () =>
    new RepositionScrollableScrollStrategy(scrollDispatcher, viewportRuler, ngZone, {
      autoClose: true,
      triggerElement: element,
    });
}

export const RED_PHARMA_DATENERFASSUNG_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  deps: [ScrollDispatcher, ViewportRuler, NgZone, ElementRef],
  useFactory: redPharmaDatenerfassungAutocompleteScrollStrategyFactory,
};
