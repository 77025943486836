import { Injectable } from '@angular/core';
import {
  Logger,
  SentryLoggerService,
  Severity,
  LoggerString_NeverUseThisInALoggingStatement,
} from '@redngapps/shared/util';
import { environment } from '@video-env/environment';

@Injectable()
export class VssErrorMonitoringLoggerService extends Logger {
  constructor(private sentryLogger: SentryLoggerService) {
    super();
  }

  log(
    message: LoggerString_NeverUseThisInALoggingStatement,
    severity: Severity,
    error?: Error,
    ...metaData: any[]
  ): void {
    if (environment.featureSwitches.sentry) {
      this.sentryLogger.log(message, severity, error, ...metaData);
    }
  }
}
