import { Directive, TemplateRef } from '@angular/core';

/**
 * Structural directive to be applied to the progressbar image
 */
@Directive({
  selector: '[redProgressBarImage]',
})
export class ProgressBarImageDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
