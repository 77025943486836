import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'red-hang-up-dialog',
  templateUrl: 'hang-up-dialog.component.html',
  styleUrls: ['hang-up-dialog.component.scss'],
})
export class HangUpDialogComponent {
  constructor(public dialogRef: MatDialogRef<HangUpDialogComponent>) {}

  clickCancel(): void {
    this.dialogRef.close(false);
  }

  clickConfirm(): void {
    this.dialogRef.close(true);
  }
}
