import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DeviceErrorType } from '@redngapps/videosprechstunde/types';
import { ConferenceInstancesService } from '@redngapps/videosprechstunde/util';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'red-initialization-error',
  templateUrl: './initialization-error.component.html',
  styleUrls: ['./initialization-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitializationErrorComponent implements OnInit {
  @Input() signalingUnknownError: boolean;
  @Input() isPreviousBrowserVersion: boolean;
  @Input() isLoading: boolean;
  @Input() deviceError: DeviceErrorType;
  @Input() waitingForUserToAuthorizeAccess: boolean;
  @Input() isRoomSizeReachedError: boolean;
  @Input() isLoginQuotaExceededError: boolean;

  conferenceOpenInOtherTab = new BehaviorSubject<boolean>(false);
  conferenceOpenInOtherTab$: Observable<boolean>;

  DeviceErrorType = DeviceErrorType;

  constructor(private conferenceInstancesServices: ConferenceInstancesService) {}

  ngOnInit() {
    this.conferenceOpenInOtherTab$ = this.conferenceOpenInOtherTab.asObservable().pipe(distinctUntilChanged());
    this.checkIfConferenceAlreadyOpen();
  }

  private checkIfConferenceAlreadyOpen(): void {
    this.conferenceOpenInOtherTab.next(this.conferenceInstancesServices.isAConferenceRunning());

    setInterval(() => {
      this.conferenceOpenInOtherTab.next(this.conferenceInstancesServices.isAConferenceRunning());
    }, 1000);
  }
}
