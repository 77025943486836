import { Injectable } from '@angular/core';
import { DeviceErrorType, IUserMediaDevices } from '@redngapps/videosprechstunde/types';
import { Logger, logMessage, logSafe } from '@redngapps/shared/util';
import { Subject } from 'rxjs';
import { ICallSettings } from '../settings/call-settings';
import idx from 'idx';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  localUserMediaStream: MediaStream;

  onMediaTrackEnded: Subject<void> = new Subject();
  onPermissionDeniedBySystem: Subject<void> = new Subject();

  constructor(private logger: Logger) {}

  /**
   * Enables or disables the camera.
   * @param enabled
   */
  updateCameraState(enabled: boolean): void {
    if (this.localUserMediaStream) {
      this.localUserMediaStream.getVideoTracks().forEach((mediaStreamTrack: MediaStreamTrack) => {
        mediaStreamTrack.enabled = enabled;
      });
    }
  }

  /**
   * Enables or disabled the microphone.
   * @param enabled
   */
  updateMicrophoneState(enabled: boolean): void {
    if (this.localUserMediaStream) {
      this.localUserMediaStream.getAudioTracks().forEach((mediaStreamTrack: MediaStreamTrack) => {
        mediaStreamTrack.enabled = enabled;
      });
    }
  }

  /**
   * Notify the browser that resources are no longer needed. I.e. free up camera and microphone.
   */
  stopTracks(): void {
    if (this.localUserMediaStream) {
      const tracks: MediaStreamTrack[] = this.localUserMediaStream.getTracks();

      tracks.forEach((track: MediaStreamTrack) => {
        if (track.readyState !== 'ended') {
          track.stop();
        }
      });

      // prevent reusing stopped tracked in `getLocalUserMediaStream`
      this.localUserMediaStream = null;
    }
  }

  async getLocalUserMediaStream(): Promise<MediaStream> {
    if (!this.localUserMediaStream) {
      this.localUserMediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
    }
    return this.localUserMediaStream;
  }

  getLocalUserMediaStreamByDeviceId(kind: MediaDeviceKind, deviceId: string): Promise<MediaStream> {
    const constraints: MediaStreamConstraints = {};
    switch (kind) {
      case 'audioinput':
      case 'audiooutput':
        constraints.audio = {
          deviceId,
        };
        break;

      case 'videoinput':
        constraints.video = {
          deviceId,
        };
        break;

      default:
        throw Error(`Unknown device kind: ${kind}`);
    }

    return navigator.mediaDevices.getUserMedia(constraints);
  }

  /**
   * Gets media stream for screensharing.
   */
  async getDisplayMediaStream(): Promise<MediaStream> {
    try {
      // TypeScript doesn't know getDisplayMedia: https://github.com/microsoft/TypeScript/issues/33232
      // @ts-ignore
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      stream.getTracks().forEach(track => {
        track.addEventListener('ended', () => {
          this.onMediaTrackEnded.next();
        });
      });
      return stream;
    } catch (error) {
      if (
        (error.name === 'NotAllowedError' && error.message === 'Permission denied by system') ||
        (error.name === 'NotFoundError' && error.message === 'The object can not be found here.')
      ) {
        // Firefox
        this.onPermissionDeniedBySystem.next();
      }
      return null;
    }
  }

  /**
   * Check IO devices that are needed for VSS.
   * If there's no video input or no audio input -> returns {@link DeviceErrorType.NOT_FOUND}.
   */
  async checkIODevices(): Promise<DeviceErrorType | null> {
    try {
      const mediaDevices: IUserMediaDevices = await this.getUserMediaDevices();

      if (!mediaDevices.videoInput.length || !mediaDevices.audioInput.length) {
        this.logger.info(logMessage`initDeviceValidityCheck() :
        Audio In: ${logSafe(mediaDevices.audioInput.length)};
        Audio Out: ${logSafe(mediaDevices.audioOutput.length)};
        Video In: ${logSafe(mediaDevices.videoInput.length)}`);
        return DeviceErrorType.NOT_FOUND;
      }
      return null;
    } catch (e) {
      return this.mapDeviceErrors(e);
    }
  }

  async getUserMediaDevices(): Promise<IUserMediaDevices> {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      const userMediaDeviceList: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();

      const userMediaDevices: IUserMediaDevices = {
        audioOutput: [],
        audioInput: [],
        videoInput: [],
      };

      userMediaDeviceList.forEach((userMediaDevice: MediaDeviceInfo) => {
        switch (userMediaDevice.kind) {
          case 'audioinput':
            userMediaDevices.audioInput.push(userMediaDevice);
            break;

          case 'audiooutput':
            userMediaDevices.audioOutput.push(userMediaDevice);
            break;

          case 'videoinput':
            userMediaDevices.videoInput.push(userMediaDevice);
            break;
        }
      });
      return userMediaDevices;
    }

    return {
      audioInput: [],
      videoInput: [],
      audioOutput: [],
    };
  }

  mapDeviceErrors(e: Error): DeviceErrorType {
    this.logger.error(logMessage`Error, name: ${logSafe(e.name)}`);

    switch (e.name) {
      case 'SecurityError':
        return DeviceErrorType.NOT_ALLOWED_BY_SYSTEM;
      case 'NotAllowedError':
        if (e.message === 'Permission denied by system') {
          return DeviceErrorType.NOT_ALLOWED_BY_SYSTEM;
        }
        return DeviceErrorType.NOT_ALLOWED;
      case 'NotFoundError':
        // this error is thrown by Firefox when the browser has not been given access to the camera.
        // As we are checking at the beginning that the user does have a camera, it is unlikely that this error
        // occurs for any other reason than the one mentioned above
        return DeviceErrorType.NOT_ALLOWED_BY_SYSTEM;
      case 'OverconstrainedError':
        this.logger.error(logMessage`Unexpected OverconstrainedError`, e);
        return DeviceErrorType.ERROR;
      case 'NotReadableError':
        // this error is thrown by Windows when the browser has not been given access to the camera.
        return DeviceErrorType.NOT_ALLOWED_BY_SYSTEM;
      case 'AbortError':
      default:
        return DeviceErrorType.ERROR;
    }
  }

  /**
   * Use another camera or mic.
   * @param settings
   * @returns A promise to a new MediaStream with specified settings.
   */
  async changeInputOutput(settings: ICallSettings): Promise<MediaStream> {
    let disableMic = false;
    let disableCamera = false;

    const constraints: MediaStreamConstraints = {};

    if (this.localUserMediaStream) {
      this.localUserMediaStream.getVideoTracks().forEach(track => {
        // If the camera is disabled, disable it in new stream as well.
        if (!track.enabled) {
          disableCamera = true;
        }

        track.stop();
      });

      this.localUserMediaStream.getAudioTracks().forEach(track => {
        // If mic is disabled, disable it in new stream as well.
        if (!track.enabled) {
          disableMic = true;
        }

        track.stop();
      });
    }

    const audioInputId = idx(settings, _ => _.audio.input);
    constraints.audio = {
      deviceId: {
        exact: audioInputId,
      },
    };

    const videoInputId = idx(settings, _ => _.video.input);
    if (videoInputId) {
      constraints.video = {
        deviceId: {
          exact: videoInputId,
        },
      };
    }

    const videoInputFacingMode = idx(settings, _ => _.video.facingMode);
    if (videoInputFacingMode) {
      constraints.video = {
        // Not using `exact` here to avoid problems if a device doesn't have a facing mode.
        // If no `exact` is used, no exception should be thrown and the constraint should be ignored.
        facingMode: videoInputFacingMode,
      };
    }

    if (!constraints.video) {
      this.logger.warn(
        logMessage`Neither deviceId nor facingMode was specified for video source: ${logSafe(
          settings,
        )}, setting to 'true'`,
      );
      constraints.video = true;
    }

    this.localUserMediaStream = await navigator.mediaDevices.getUserMedia(constraints);
    if (disableCamera) {
      this.localUserMediaStream.getVideoTracks().forEach(t => (t.enabled = false));
    }

    if (disableMic) {
      this.localUserMediaStream.getAudioTracks().forEach(t => (t.enabled = false));
    }

    return this.localUserMediaStream;
  }
}
