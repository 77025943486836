import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserDetectionService, logMessage, Logger, logSafe } from '@redngapps/shared/util';
import { delay } from 'rxjs/operators';
import { Subscription, merge } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { WebRtcService } from '../services/web-rtc.service';
import { MediaService } from '../services/media.service';
import { SignalingService } from '../services/signaling.service';
import { DeviceErrorType, ICustomError } from '@redngapps/videosprechstunde/types';

@Component({
  selector: 'red-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy, OnInit {
  get accessCode(): AbstractControl {
    return this.form.get('accessCode');
  }

  get canLogin(): boolean {
    return !this.deviceError && !this.signalingUnknownError;
  }

  readonly maskSnippet = /[ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0-9]/;
  readonly mask = [
    this.maskSnippet,
    this.maskSnippet,
    this.maskSnippet,
    '-',
    this.maskSnippet,
    this.maskSnippet,
    this.maskSnippet,
    '-',
    this.maskSnippet,
    this.maskSnippet,
    this.maskSnippet,
  ];

  DeviceErrorType = DeviceErrorType;

  isLoading = false;
  waitingForUserToAuthorizeAccess = false;
  isRoomSizeReachedError = false;
  signalingUnknownError = false;
  reloadNeeded = false;
  deviceError: DeviceErrorType;
  isPreviousBrowserVersion = false;
  conferenceOpenInOtherTab = false;
  isLoginQuotaExceededError = false;

  form: FormGroup;

  private subscribers: Subscription[] = [];

  constructor(
    private router: Router,
    private mediaService: MediaService,
    private signalingService: SignalingService,
    private webRtcService: WebRtcService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
    private browserDetectionService: BrowserDetectionService,
    private logger: Logger,
  ) {}

  // tslint:disable-next-line:red-restrict-async-lifecycle-hooks - DEV-10762
  async ngOnInit() {
    this.webRtcService.reset();

    this.isPreviousBrowserVersion = this.browserDetectionService.isUserBrowserSupportedAndPreviousVersion();

    this.form = this.fb.group({
      name: [null, Validators.required],
      accessCode: [null, Validators.required],
      acceptedDataPrivacy: [false, Validators.requiredTrue],
    });

    this.handleSignalingErrors();
    this.initAutoLogin();
  }

  onPasteAccessCode(e: ClipboardEvent) {
    const paste = e.clipboardData.getData('text');
    // remove all whitespaces and linebreaks that may have been accidentally copied in addition to the access-code
    // otherwise parts of the code would be lost by the text-mask-directive
    this.accessCode.setValue(paste.trim());
    e.preventDefault();
  }

  refreshPage(): void {
    window.location.reload();
  }

  onFocusCodeInput({ target }: FocusEvent): void {
    const inputElement = target as HTMLInputElement;
    const code = inputElement.value;
    const position = code ? code.indexOf('_') : 0;
    setTimeout(() => {
      inputElement.setSelectionRange(position, position);
    });
  }

  async onLogin(): Promise<void> {
    if (this.form.valid) {
      this.logger.info(logMessage`Login has happened.`);

      this.isRoomSizeReachedError = false;
      this.isLoading = true;
      const timeout = setTimeout(() => {
        this.waitingForUserToAuthorizeAccess = true;
      }, 800);
      clearTimeout(timeout);
      this.waitingForUserToAuthorizeAccess = false;
      await this.connect(this.form.value.name, this.form.value.accessCode);
    }
  }

  async connect(name: string, accessCode: string): Promise<void> {
    const localStream = await this.mediaService.getLocalUserMediaStream();
    if (!localStream) {
      return;
    }
    this.webRtcService.setLocalStream(localStream);
    // this will intentionally also clear the
    // auto-login subscriber
    this.clearSubscribers();

    this.subscribers.push(
      this.signalingService.onCustomError.pipe(delay(500)).subscribe((customError: ICustomError) => {
        // show loading message
        this.isLoading = false;

        // reset error flags
        this.isRoomSizeReachedError = false;
        this.isLoginQuotaExceededError = false;

        this.webRtcService.disconnectLocalTracks();

        switch (customError) {
          case 'key_invalid':
          case 'key_in_use':
            this.accessCode.setErrors({ invalid: true });
            this.accessCode.markAsTouched();
            break;
          case 'max_room_size_reached':
            this.isRoomSizeReachedError = true;
            break;
          case 'login_quota_exceeded':
            this.isLoginQuotaExceededError = true;
            break;
          default:
            this.signalingUnknownError = true;
            this.reloadNeeded = true;
            this.logger.error(logMessage`WebRTC custom error: ${logSafe(customError)}`);
            break;
        }
      }),
    );

    this.subscribers.push(
      this.signalingService.onLoginQuotaAvailable.subscribe(() => {
        this.isLoginQuotaExceededError = false;
      }),
    );

    this.subscribers.push(
      this.webRtcService.onJoinedRoom.subscribe(async event => {
        this.isRoomSizeReachedError = false;
        this.isLoading = false;

        this.webRtcService.setConnected(true);
        this.webRtcService.roomTitle.next(event ? event.roomTitle : undefined);

        await this.authService.login(name, accessCode, event.codeType, event.license);

        await this.router.navigate(['/conference']);
      }),
    );

    this.isRoomSizeReachedError = false;

    // show loading message
    this.isLoading = true;
    // ensure websocket connection to be opened before trying to join room
    try {
      // on manual login via login form the connection is established before (on page load)
      await this.webRtcService.createOrJoin(accessCode);
    } catch (e) {
      // websocket connection might not be opened before when application is opened via url
      // including the access code
      this.subscribers.push(
        this.signalingService.onConnectionOpen.subscribe(async () => {
          this.logger.info(logMessage`onConnectionOpen`);
          await this.webRtcService.createOrJoin(accessCode);
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.clearSubscribers();
  }

  private async initAutoLogin(): Promise<void> {
    // optional auto-login
    this.subscribers.push(
      this.route.queryParams.subscribe(async (queryParams: any) => {
        const { code: accessCode, conference } = queryParams;
        let { name } = queryParams;

        const isConference = conference && conference === 'true';

        if ((!name || !name.length || name === 'undefined') && accessCode && accessCode.length && !isConference) {
          name = 'Unbekannt';
        }
        this.form.patchValue({
          name,
          accessCode,
        });

        if (name && accessCode && this.canLogin) {
          await this.onLogin();
        } else if (accessCode && !name) {
          this.form.get('name').markAsTouched();
        }
      }),
    );
  }

  private clearSubscribers() {
    this.subscribers.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private handleSignalingErrors(): void {
    this.subscribers.push(
      merge(
        this.signalingService.onConnectionError,
        this.signalingService.onError,
        this.signalingService.onConnectionInterrupted,
      ).subscribe(error => {
        this.isLoading = false;
        this.signalingUnknownError = true;
        this.reloadNeeded = true;
        this.handleError(error);
      }),
    );
  }

  private handleError(error: any): void {
    // if the error object is undefined the socket connection was lost
    if (!error) {
      this.logger.warn(logMessage`Socket connection lost`);
      // if the error object has the following form the socket connection is interrupted
    } else if (
      error &&
      error.isTrusted === true &&
      error.target instanceof WebSocket &&
      error.currentTarget instanceof WebSocket
    ) {
      this.logger.warn(logMessage`Socket connection interrupted`);
      // if the error object has the following form it wasn't possible to establish a socket connection
    } else if (error && error.message === 'Socket connection not open' && error.name === 'Error') {
      this.logger.warn(logMessage`Socket connection not open`);
    } else {
      this.logger.error(logMessage`Error during login`, null, logSafe(error));
    }
  }
}
