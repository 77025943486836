import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'button[textLink]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./text-link-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextLinkButtonComponent {
  @HostBinding('class.red-text-link-button')
  readonly bodyClass = true;
}
