const BOOLEAN_VALUE_TRUE = 'true';
const BOOLEAN_VALUE_FALSE = 'false';

export abstract class StorageService {
  constructor(private storage: Storage) {}

  put(key: string, value: string | null): void {
    this.storage.setItem(key, value);
  }

  get(key: string): string | null {
    return this.storage.getItem(key);
  }

  remove(key: string): void {
    this.storage.removeItem(key);
  }

  /**
   * retrieves a boolean value from a key
   * @param {string} key
   * @return {boolean | null}
   */
  getBoolean(key: string): boolean | null {
    const value = this.get(key);

    if (value === BOOLEAN_VALUE_TRUE) {
      return true;
    }

    if (value === BOOLEAN_VALUE_FALSE) {
      return false;
    }

    return null;
  }

  putBoolean(key: string, value: boolean): void {
    this.put(key, value ? BOOLEAN_VALUE_TRUE : BOOLEAN_VALUE_FALSE);
  }
}
