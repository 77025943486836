import { Component, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'red-overlay-progress-bar',
  template: `
    <div *ngIf="showProgress" class="progress-overlay">
      <red-progress-bar [mode]="mode" [text]="text" [progress]="progress"></red-progress-bar>
    </div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./overlay-progressbar.component.scss'],
})
export class OverlayProgressBarComponent {
  @Input()
  set showProgress(value: boolean | string) {
    this._showProgress = coerceBooleanProperty(value);
  }
  get showProgress() {
    return this._showProgress;
  }

  @Input() mode: 'indeterminate' | 'determinate' = 'indeterminate';
  @Input() text: string | undefined | null;
  @Input() progress: number | undefined | null;

  private _showProgress = false;
}
