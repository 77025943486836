export interface License {
  id: string;
  name: string;
  features?: { [key: string]: any };
}

export enum LicenseId {
  Connect = '1',
  Basis = '2',
  Pharma = '3',
  Apo = '4',
  ConnectPlus = '5',
}
