import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUtilModule } from '@redngapps/shared/util';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { CustomMatSnackBarComponent } from './custom-mat-snack-bar/custom-mat-snack-bar.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { GenericAlertDialogComponent } from './generic-alert-dialog/generic-alert-dialog.component';
import { ProgressBarImageDirective } from './progress-bar/progress-bar-image.directive';
import { OverlayProgressBarComponent } from './progress-bar/overlay-progressbar.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedUtilModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDialogModule,
  ],
  declarations: [
    CustomMatSnackBarComponent,
    TimeInputComponent,
    ProgressBarComponent,
    ProgressBarImageDirective,
    GenericAlertDialogComponent,
    OverlayProgressBarComponent,
  ],
  entryComponents: [CustomMatSnackBarComponent, GenericAlertDialogComponent],
  exports: [
    CustomMatSnackBarComponent,
    TimeInputComponent,
    ProgressBarComponent,
    ProgressBarImageDirective,
    GenericAlertDialogComponent,
    OverlayProgressBarComponent,
  ],
})
export class SharedComponentsUiModule {}
