import { Directive, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { environment } from '@client-env/environment';
import { IdCollector } from '@redmedical/hvstr-client';

@Directive({
  // tslint:disable-next-line
  selector: '[e2e]',
})
export class E2eIdDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line
  @Input('e2e')
  e2eId: string;

  handle: string;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (environment.featureSwitches.hvstrClientEnabled) {
      this.handle = IdCollector.add(this.el.nativeElement, this.e2eId);
    }
  }

  ngOnDestroy(): void {
    if (environment.featureSwitches.hvstrClientEnabled) {
      IdCollector.remove(this.handle);
    }
  }
}
