import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { Observable, from, EMPTY } from 'rxjs';

import { SignalingService } from '@redngapps/videosprechstunde/feature';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SocketConnectionResolver implements Resolve<Observable<void>> {
  constructor(private signalingService: SignalingService, private router: Router) {}

  // if the connection to the websocket server cannot be created the user is redirected to
  // an error page
  // we dont want the user to shortly see the login page before being redirected, that is why the socket is
  // not created in the component
  // we could have used a guard to check if the connection is possible, but this would have required us to
  // whether create the connection again in the component or directly use the connection created in the guard,
  // which is a weird side effect for a guard.
  // This is why a resolver is used.
  resolve(): Observable<void> {
    return from(this.signalingService.init()).pipe(
      catchError(() => {
        this.router.navigate(['/error-page/firewall-proxy']);
        return EMPTY;
      }),
    );
  }
}
