import { Component, Input } from '@angular/core';
import { ParticipantInfo } from '@redngapps/videosprechstunde/types';

@Component({
  selector: 'red-participant-small-videos',
  templateUrl: './participant-small-videos.component.html',
  styleUrls: ['./participant-small-videos.component.scss'],
})
export class ParticipantSmallVideosComponent {
  @Input() participants: ParticipantInfo[] = [];
  @Input() localStream: MediaStream;
  @Input() cameraActivationState: boolean;
  @Input() screenSharingActivationState: boolean;
  @Input() audioOutputDeviceId: string;
}
