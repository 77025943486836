import { Injectable } from '@angular/core';

import { StorageService } from './storage-service';
import { SharedUtilModule } from '../shared-util.module';

@Injectable({ providedIn: SharedUtilModule })
export class SessionStorageService extends StorageService {
  constructor() {
    super(window.sessionStorage);
  }
}
