import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

function defaultCompareFunction(a: any, b: any) {
  return a === b;
}

@Component({
  selector: 'red-compare-to',
  template: '<ng-content></ng-content>',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'red-compare-to',
  },
})
export class CompareToFormValueComponent implements AfterContentInit, OnDestroy, OnChanges {
  @ContentChild(MatFormFieldControl, { static: false }) _controlNonStatic: MatFormFieldControl<any>;
  @ContentChild(MatFormFieldControl, { static: true }) _controlStatic: MatFormFieldControl<any>;
  get _control() {
    // TODO(crisbeto): we need this hacky workaround in order to support both Ivy
    // and ViewEngine. We should clean this up once Ivy is the default renderer.
    return this.explicitFormFieldControl || this._controlNonStatic || this._controlStatic;
  }
  set _control(value) {
    this.explicitFormFieldControl = value;
  }

  @Input()
  value: any;

  @Input()
  compareFunction: (a: any, b: any) => boolean;

  @HostBinding('class.red-compare-to-value-changed')
  hasValueChanged = false;

  readonly hasValueChangedChanges: Observable<boolean>;

  readonly valueChanges: Observable<any>;

  private explicitFormFieldControl: MatFormFieldControl<any>;
  private internalCompareFunction = defaultCompareFunction;
  private valueChangedChangesSubject = new Subject<boolean>();
  private valueChangesSubject = new Subject<boolean>();
  private destroyed = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
    this.hasValueChangedChanges = this.valueChangedChangesSubject.asObservable().pipe(distinctUntilChanged());
    this.valueChanges = this.valueChangesSubject.asObservable().pipe(distinctUntilChanged());
  }

  ngAfterContentInit() {
    const control = this._control;
    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.updateHasValueChanged();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.updateHasValueChanged();
      });
    }

    // Move updateHasValueChanged to the end of macrotask to avoid ExpressionChangedAfterItHasBeenCheckedError
    Promise.resolve().then(() => this.updateHasValueChanged());
  }

  ngOnChanges(changes: SimpleChanges) {
    const redCompareTo = changes['value'];
    if (redCompareTo && !redCompareTo.firstChange) {
      this.updateHasValueChanged();
      this.valueChangesSubject.next(redCompareTo.currentValue);
    }

    const compareFn = changes['compareFunction'];
    if (compareFn) {
      this.internalCompareFunction = compareFn.currentValue || defaultCompareFunction;
      if (!compareFn.firstChange) {
        this.updateHasValueChanged();
      }
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private updateHasValueChanged() {
    if (this._control) {
      const hasValueChanged =
        !this.internalCompareFunction(this._control.value, this.value) && !this._control.errorState;

      if (this.hasValueChanged !== hasValueChanged) {
        this.hasValueChanged = hasValueChanged;
        this.valueChangedChangesSubject.next(hasValueChanged);
        this.cdr.markForCheck();
      }
    }
  }
}
