import { Directive } from '@angular/core';

export const INFO_FIELD_RELATIVE_PARENT_CLASS = 'info-field-relative-parent';

@Directive({
  selector: '[redFieldInfoRelativeParent]',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[style.position]': '"relative"',
    // dynamic field is not supported by language service,
    // therefore the class name needs to be repeated within the metadata
    '[class.info-field-relative-parent]': 'true',
  },
})
export class FieldInfoRelativeParentDirective {}
