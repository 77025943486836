import { Component, Input } from '@angular/core';

@Component({
  selector: 'red-one-page',
  templateUrl: './one-page.component.html',
  styleUrls: ['./one-page.component.scss'],
})
export class OnePageComponent {
  @Input() showProgress = false;

  _logoSuffix = 'main';
  @Input()
  set logoSuffix(suffix: string) {
    this._logoSuffix = suffix || 'main';
  }
}
