import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  Self,
  Optional,
  HostBinding,
  DoCheck,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, NgForm, FormGroupDirective, NgControl } from '@angular/forms';
import { takeUntil, startWith } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatCheckbox } from '@angular/material/checkbox';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'mat-checkbox',
})
export class CheckboxErrorStateDirective implements OnInit, OnDestroy, DoCheck, OnChanges {
  @Input()
  errorStateMatcher: ErrorStateMatcher;

  @HostBinding('class.has-error-state')
  errorState = false;

  @HostBinding('class.mat-checkbox-error-state')
  readonly checkboxErrorStateMarker = true;

  private destroy$ = new Subject<void>();

  constructor(
    private matCheckbox: MatCheckbox,
    private defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() private ngControl: NgControl,
    @Optional() private parentForm: NgForm,
    @Optional() private parentFormGroup: FormGroupDirective,
  ) {}

  ngOnInit(): void {
    const updateStateSources = [
      this.matCheckbox.change,
      this.parent ? this.parent.statusChanges : undefined,
      this.ngControl ? this.ngControl.statusChanges : undefined,
    ].filter(c => !!c);

    merge(...updateStateSources)
      .pipe(
        startWith(undefined),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateErrorState();
      });
  }

  ngDoCheck() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const errorStateMatcherChanges = changes['errorStateMatcher'];
    if (errorStateMatcherChanges && !errorStateMatcherChanges.firstChange) {
      this.updateErrorState();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /** Updates the error state based on the provided error state matcher. */
  private updateErrorState() {
    const oldState = this.errorState;
    const matcher = this.errorStateMatcher || this.defaultErrorStateMatcher;
    const control = this.ngControl ? (this.ngControl.control as FormControl) : null;
    const newState = matcher.isErrorState(control, this.parent);

    if (newState !== oldState) {
      this.errorState = newState;
    }
  }

  private get parent() {
    return this.parentFormGroup || this.parentForm;
  }
}
