import { BigNumber } from 'bignumber.js';

export class NumberUtils {
  static isNumeric(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /**
   * Parses float string in format "n(,nn)" and returns either float or NaN.
   * WARNING: Unexpected behaviour if German format is used with thousands, e.g. "10.000,00"
   * @param {string} numWithComma
   */
  static parseFloatWithComma(numWithComma: string): number {
    if (numWithComma) {
      const num = numWithComma.replace(/,/, '.');
      return parseFloat(num);
    }

    return NaN;
  }

  static toNumber(value: any): number {
    let v = value;
    if (typeof value === 'number') {
      // 'toString' on wert to avoid 'has more than 15 significant digits' error.
      v = value.toString();
    }
    return new BigNumber(v).toNumber();
  }

  static toNumberFixed(value: any, fractionDigits: number): number {
    return NumberUtils.toNumber(NumberUtils.toNumber(value).toFixed(fractionDigits));
  }

  static multiply(a: any, b: any): string {
    return new BigNumber(a).multipliedBy(b).toString();
  }
}
