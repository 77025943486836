import { ErrorHandler } from '@angular/core';
import { BreadcrumbWhitelistElement, extractError, filterEvent } from '../functions/util-functions';
import { Breadcrumb, CaptureContext } from '@sentry/types';
import * as Sentry from '@sentry/browser';

export interface SentryErrorHandlerArgs {
  dsn: string;
  /** Softwareversion or Githash */
  release: string;
  environment: string;
  breadcrumbWhitelist: Array<BreadcrumbWhitelistElement>;
  urlParameterBlacklist: Array<string>;
}

export abstract class AbstractSentryErrorHandler implements ErrorHandler {
  protected constructor(args: SentryErrorHandlerArgs) {
    Sentry.init({
      dsn: args.dsn,
      release: args.release,
      environment: args.environment,
      attachStacktrace: true,
      beforeSend(event: Sentry.Event): PromiseLike<Sentry.Event | null> | Sentry.Event | null {
        return filterEvent(event, args.breadcrumbWhitelist, args.urlParameterBlacklist);
      },
      integrations: [
        new Sentry.Integrations.TryCatch({
          XMLHttpRequest: false,
        }),
      ],
    });
  }

  handleError(error): void {
    const extractedError = extractError(error);
    // Capture handled exception and send it to Sentry.
    this.captureException(extractedError);
  }

  captureException(exception: any, captureContext?: CaptureContext): void {
    Sentry.captureException(exception, captureContext);
    this.clearBreadcrumbs();
  }

  captureMessage(message: string, captureContext?: CaptureContext): void {
    Sentry.captureMessage(message, captureContext);
    this.clearBreadcrumbs();
  }

  addBreadcrumb(breadcrumb: Breadcrumb): void {
    Sentry.addBreadcrumb(breadcrumb);
  }

  clearBreadcrumbs(): void {
    Sentry.configureScope(scope => scope.clearBreadcrumbs());
  }

  /**
   * Set app context specific metadata
   * @param args
   */
  abstract setMetadata(...args: any): void;
}
