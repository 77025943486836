import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { OnePageComponent } from './layout/one-page/one-page.component';
import { SharedComponentsUiModule } from '@redngapps/shared/components/ui';
import { InfoComponent } from './field-info/info/info.component';
import { InfoButtonComponent } from './field-info/info-button/info-button.component';
import { FieldInfoDirective } from './field-info/field-info.directive';
import { FieldInfoRelativeParentDirective } from './field-info/field-info-relative-parent.directive';
import { CheckboxStarDirective } from './star-directive/checkbox-star.directive';
import { CheckboxErrorStateDirective } from './checkbox-error-state-directive/checkbox-error-state.directive';
import { RadioGroupErrorStateDirective } from './radio-group-error-state-directive/radio-group-error-state.directive';
import { MessageComponent } from './message/message.component';
import { RedChipComponent } from './chips/chip.component';
import { CategoryChipComponent } from './chips/category/category-chip.component';
import { TextLinkButtonComponent } from './text-link-button/text-link-button.component';
import { WikiFieldInfoComponent } from './wiki-field-info/wiki-field-info.component';
import { WikiPageRendererComponent } from './wiki-page-renderer/wiki-page-renderer.component';

const exportedComponents = [
  OnePageComponent,
  FieldInfoDirective,
  FieldInfoRelativeParentDirective,
  CheckboxStarDirective,
  CheckboxErrorStateDirective,
  RadioGroupErrorStateDirective,
  MessageComponent,
  RedChipComponent,
  CategoryChipComponent,
  TextLinkButtonComponent,
  WikiPageRendererComponent,
  WikiFieldInfoComponent,
];

const materialModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatOptionModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTableModule,
  MatSortModule,
  CdkAccordionModule,
  OverlayModule,
  ScrollingModule,
];

@NgModule({
  imports: [CommonModule, SharedComponentsUiModule, ...materialModules],
  declarations: [...exportedComponents, InfoComponent, InfoButtonComponent],
  exports: [...materialModules, ...exportedComponents],
  entryComponents: [InfoComponent, InfoButtonComponent],
})
export class SharedUiModule {}
