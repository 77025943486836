import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'red-sharing-not-authorized-dialog',
  templateUrl: 'sharing-not-authorized-dialog.component.html',
  styleUrls: ['sharing-not-authorized-dialog.component.scss'],
})
export class SharingNotAuthorizedDialogComponent {
  constructor(public dialogRef: MatDialogRef<SharingNotAuthorizedDialogComponent>) {}

  clickClose(): void {
    this.dialogRef.close();
  }
}
