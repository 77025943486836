import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface GenericAlertDialogData {
  type: 'error' | 'warning' | 'info';
  faIcon?: string;
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText?: string;
}

const DEFAULT_ICONS = {
  error: 'fa-times-circle',
  warning: 'fa-exclamation-circle',
  info: 'fa-info-circle',
};

@Component({
  selector: 'red-generic-alert-dialog',
  templateUrl: 'generic-alert-dialog.component.html',
  styleUrls: ['generic-alert-dialog.component.scss'],
})
export class GenericAlertDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GenericAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericAlertDialogData,
  ) {}

  ngOnInit(): void {
    if (!this.data.faIcon) {
      this.data.faIcon = DEFAULT_ICONS[this.data.type];
    }

    this.dialogRef.addPanelClass('red-alert-container');
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('416px');
  }

  clickCancel(): void {
    this.dialogRef.close(false);
  }

  clickConfirm(): void {
    this.dialogRef.close(true);
  }
}
