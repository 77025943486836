import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class PasswordErrorStateMatcher extends ErrorStateMatcher {
  readonly passwordMismatchErrorKey = 'mismatch';

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const defaultErrorState = super.isErrorState(control, form);

    if (defaultErrorState) {
      // when default is already an error, so it is already an error
      return true;
    }

    return !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.hasError(this.passwordMismatchErrorKey) &&
      (control.parent.dirty || control.parent.touched)
    );
  }
}
