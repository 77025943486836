import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { FileChooserComponent } from './file-chooser.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [MatButtonModule, CommonModule],
  declarations: [FileChooserComponent],
  exports: [FileChooserComponent],
})
export class FileChooserModule {}
