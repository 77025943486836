import { Injectable } from '@angular/core';
import { BROWSERS, DeviceDetectorService, DeviceInfo, OS } from 'ngx-device-detector';
import * as _cloneDeep from 'lodash.clonedeep';

import * as browserVersions from './browserVersions.json';

// https://github.com/KoderLabs/ngx-device-detector/blob/master/src/device-detector.constants.ts
interface BrowserVersion {
  [browser: string]: number;
}

@Injectable()
export class BrowserDetectionService {
  readonly currentBrowserVersions: BrowserVersion;
  readonly previousBrowserVersions: BrowserVersion;
  readonly outdatedBrowserVersions: BrowserVersion;

  constructor(private deviceDetectorService: DeviceDetectorService) {
    // https://www.google.de/search?q=latest+browser+versions
    this.currentBrowserVersions = browserVersions.currentBrowserVersions;

    this.previousBrowserVersions = browserVersions.previousBrowserVersions;

    this.outdatedBrowserVersions = browserVersions.outdatedBrowserVersions;
  }

  private static mockChromeWhenInHeadless(deviceInfo: DeviceInfo): DeviceInfo {
    const clonedDeviceInfo = _cloneDeep(deviceInfo);
    const isChromeHeadless = clonedDeviceInfo.userAgent.includes('HeadlessChrome');

    if (isChromeHeadless) {
      clonedDeviceInfo.browser = BROWSERS.CHROME;
      clonedDeviceInfo.browser_version = '74.0.3729.169';
    }

    return clonedDeviceInfo;
  }

  getDeviceInformation(): DeviceInfo {
    let deviceInfo = this.deviceDetectorService.getDeviceInfo();

    deviceInfo = BrowserDetectionService.mockChromeWhenInHeadless(deviceInfo);

    return deviceInfo;
  }

  isUserBrowserSupportedAndCurrentVersion(): boolean {
    const device = this.getDeviceInformation();
    const usersCurrentMajorVersion = this.getUsersCurrentBrowserMajorVersion();
    const currentMajorBrowserVersion = this.currentBrowserVersions[device.browser];

    // allow for graceful acceptance of newer browser versions to be the current ones
    // e.g. we shipped the code, saying 69 is current. One day later 70 is released
    // and we didn't update in time then this code ensures we gracefully accept 70
    // as the most current version (because obviously it is as the user installed a
    // newer version than we know; smartly gets around the problem of false-positively
    // showing the user a warning that he should update the browser even tho the newest
    // version is already installed
    return usersCurrentMajorVersion && usersCurrentMajorVersion >= currentMajorBrowserVersion;
  }

  isUserBrowserSupportedAndPreviousVersion(): boolean {
    const device = this.getDeviceInformation();
    const usersCurrentMajorVersion = this.getUsersCurrentBrowserMajorVersion();
    const previousMajorBrowserVersion = this.previousBrowserVersions[device.browser];

    return usersCurrentMajorVersion && usersCurrentMajorVersion === previousMajorBrowserVersion;
  }

  isUserBrowserSupportedAndOutdatedVersion(): boolean {
    const device = this.getDeviceInformation();
    const usersCurrentMajorVersion = this.getUsersCurrentBrowserMajorVersion();
    const outdatedBrowserVersion = this.outdatedBrowserVersions[device.browser];

    return usersCurrentMajorVersion && usersCurrentMajorVersion <= outdatedBrowserVersion;
  }

  getUsersCurrentBrowserMajorVersion(): number {
    const device = this.getDeviceInformation();
    let currentMajorVersion;
    try {
      currentMajorVersion = parseInt(device.browser_version.substring(0, device.browser_version.indexOf('.')), 10);
    } catch (e) {
      // parsing major version failed - set it to -1 to let browser detection fail and return version as old for generally supported browser
      currentMajorVersion = -1;
      console.error('browser detection error', 'failed to parse version', e, 'setting version to -1');
    }
    return currentMajorVersion;
  }

  getMinimumSupportedVersions(): BrowserVersion {
    return this.previousBrowserVersions;
  }

  isTouchCapable(global: any): boolean {
    return 'ontouchstart' in global;
  }

  hasOrientationChange(global: any): boolean {
    return 'onorientationchange' in global;
  }

  isIE(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === BROWSERS.IE;
  }

  isChrome(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === BROWSERS.CHROME;
  }

  isFirefox(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === BROWSERS.FIREFOX;
  }

  isSafari(): boolean {
    return this.deviceDetectorService.getDeviceInfo().browser === BROWSERS.SAFARI;
  }

  isWindows(): boolean {
    return this.deviceDetectorService.getDeviceInfo().os === OS.WINDOWS;
  }

  isMac(): boolean {
    return this.deviceDetectorService.getDeviceInfo().os === OS.MAC;
  }

  isLinux(): boolean {
    return this.deviceDetectorService.getDeviceInfo().os === OS.LINUX;
  }

  isMobile(): boolean {
    return this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
  }
}
