export enum KeyboardKeys {
  Comma = ',',
  Period = '.',
  Subtract = '-',
  Decimal = 'Decimal',

  CapsLock = 'CapsLock',
  Control = 'Control',
  Shift = 'Shift',
  Enter = 'Enter',
  Space = ' ',
  Backspace = 'Backspace',
  Escape = 'Escape',
  Tab = 'Tab',
  Delete = 'Delete',

  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',

  /** returned for every key on mobile soft-keyboards */
  Unidentified = 'Unidentified',
}
