import { Component, Input, ChangeDetectionStrategy, ContentChild } from '@angular/core';

import { ProgressBarImageDirective } from './progress-bar-image.directive';

@Component({
  selector: 'red-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input() mode: 'indeterminate' | 'determinate' = 'indeterminate';
  @Input() text: string | undefined | null;
  @Input() progress: number | undefined | null;

  @ContentChild(ProgressBarImageDirective, { static: false })
  image: ProgressBarImageDirective;
}
