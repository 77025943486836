import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AudioSettingsService } from '../../services/audio-settings.service';
import { MatSelectChange } from '@angular/material/select';
import { IAudioSettings } from '../call-settings';
import { BrowserDetectionService, Logger, logMessage } from '@redngapps/shared/util';
import idx from 'idx';
import { Observable } from 'rxjs';

@Component({
  selector: 'red-audio-settings',
  templateUrl: './audio-settings.component.html',
  styleUrls: ['./audio-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioSettingsComponent implements OnInit {
  browserSupportsChangingAudioOutput = true;
  volume$: Observable<number>;
  isFirefox: boolean;

  @Input() inputSources: MediaDeviceInfo[];
  @Input() outputSources: MediaDeviceInfo[];

  /**
   * Initial audio settings.
   */
  @Input() settings: IAudioSettings;
  @Output() change = new EventEmitter<IAudioSettings>();

  constructor(
    private audioSettingsService: AudioSettingsService,
    private logger: Logger,
    private browserDetectionService: BrowserDetectionService,
  ) {}

  ngOnInit() {
    this.isFirefox = this.browserDetectionService.isFirefox();
    this.browserSupportsChangingAudioOutput = this.checkSinkIdSupport();
    if (this.settings.input) {
      this.volume$ = this.audioSettingsService.getVolume(this.settings.input);
    }
  }

  onPlaySound(): void {
    const audio: HTMLAudioElement = new Audio('/assets/test_sound_red_connect.wav');
    this.audioSettingsService.playTestSound(idx(this.settings, _ => _.output), audio).catch(e => {
      this.logger.error(logMessage`Couldn't play sound`, e);
    });
  }

  onOutputChange(event: MatSelectChange): void {
    if (this.settings) {
      this.settings.output = event.value;
    } else {
      this.settings = {
        output: event.value,
      };
    }

    this.change.emit(this.settings);
  }

  onMicChange(event: MatSelectChange): void {
    this.volume$ = this.audioSettingsService.getVolume(event.value);
    if (this.settings) {
      this.settings.input = event.value;
    } else {
      this.settings = {
        input: event.value,
      };
    }

    this.change.emit(this.settings);
  }

  /**
   * @returns true if browser supports {@link HTMLMediaElement} setSinkId method or false otherwise.
   */
  private checkSinkIdSupport(): boolean {
    const videoElement = document.createElement('video');
    return !!videoElement['setSinkId'];
  }
}
