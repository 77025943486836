import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Type,
  ChangeDetectorRef,
} from '@angular/core';
import { Observable, of, Subscription, Subject, EMPTY } from 'rxjs';
import { expand, map, switchMap, takeWhile } from 'rxjs/operators';

import { WorkflowSource } from './workflow.source';
import {
  WORKFLOW_ITEM_DATA,
  WorkflowEndItem,
  WorkflowItemRefInternal,
  WorkflowItemRef,
} from './models/workflow.models';

@Component({
  selector: 'red-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowComponent implements OnChanges, OnDestroy {
  @Input()
  workflowSource: WorkflowSource;

  currentComponent: Type<unknown>;
  componentInjector: Injector;
  subscription: Subscription;

  private currentResult: any;
  private workflowSubject = new Subject<WorkflowSource>();
  private destroy = new Subject<void>();

  constructor(private readonly injector: Injector, private cdr: ChangeDetectorRef) {
    this.subscription = this.workflowSubject
      .asObservable()
      .pipe(
        switchMap(workflowSource => {
          if (!workflowSource) {
            return EMPTY;
          }

          return this.showWorkflowItem(undefined).pipe(
            expand(({ type, data }) => {
              if (type === 'end') {
                return of({ type, data });
              }

              return this.showWorkflowItem(data);
            }, undefined),
          );
        }),
        takeWhile(data => data.type === 'step', true),
      )
      .subscribe({
        next: result => {
          this.currentResult = result.data;
        },
        error: err => this.workflowSource.onWorkflowError(err),
        complete: () => {
          // we need to move this into the next tick, sometimes we do react inside
          // a change detection event so it might trigger ExpressionHasChangedAfterItHasBeenChecked
          Promise.resolve().then(() => {
            this.workflowSource.onWorkflowEnd(this.currentResult);
          });
        },
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const sourceChanges = changes['workflowSource'];
    if (sourceChanges) {
      this.workflowSubject.next(sourceChanges.currentValue);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroy.next();
    this.destroy.complete();
  }

  private showWorkflowItem(previousResult: any): Observable<{ type: 'end' | 'step'; data: any }> {
    const workflowItem = this.workflowSource.getNextWorkflowItem(previousResult);

    this.cdr.markForCheck();

    if (workflowItem instanceof WorkflowEndItem) {
      this.componentInjector = undefined;
      this.currentComponent = undefined;
      return of({ type: 'end', data: workflowItem.result });
    }

    const ref = this.initializeNewComponent(workflowItem.component, workflowItem.data);
    return ref.finish.pipe(map(result => ({ data: result, type: 'step' })));
  }

  private initializeNewComponent(component: Type<unknown>, data: any) {
    const workflowRef = new WorkflowItemRefInternal();

    const injector = Injector.create({
      providers: [{ provide: WORKFLOW_ITEM_DATA, useValue: data }, { provide: WorkflowItemRef, useValue: workflowRef }],
      parent: this.injector,
      name: 'WorkflowItemInjector',
    });

    this.currentComponent = component;
    this.componentInjector = injector;
    return workflowRef;
  }
}
