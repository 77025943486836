import { Directive, Input, Self, Inject, OnInit, isDevMode, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { SubformValueAccessor, RED_SUBFORM_VALUE_ACCESSOR } from '../types/subform-value-accessor';

/**
 * Directive used to implement parent child forms. The child form will be added to the parent form
 * automatically without the need to specify the form in one place.
 *
 * The directive expects to be placed within a form group and the Component the directive is placed
 * on needs to provide an implementation for the `RED_SUBFORM_VALUE_ACCESSOR` InjectionToken.
 */
@Directive({
  selector: `[redSubformName]`,
})
export class SubformDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  redSubformName: string;

  constructor(
    private parent: FormGroupDirective,
    @Self() @Inject(RED_SUBFORM_VALUE_ACCESSOR) private subformValueAccessor: SubformValueAccessor,
  ) {}

  ngOnInit() {
    this.checkParentType();
    this.parent.control.addControl(this.redSubformName, this.subformValueAccessor.subform);
  }

  ngOnDestroy() {
    this.parent.control.removeControl(this.redSubformName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isDevMode()) {
      const nameChanges = changes['redSubformName'];
      if (nameChanges && !nameChanges.firstChange) {
        throw new Error('Renaming of subform keys is not supported');
      }
    }
  }

  private checkParentType() {
    if (isDevMode()) {
      if (this.parent.control.get(this.redSubformName)) {
        throw new Error(`Parent form already declares a property with name '${this.redSubformName}'`);
      }
    }
  }
}
