import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'mat-checkbox[required]',
})
export class CheckboxStarDirective implements OnInit, OnChanges {
  @Input() required: boolean | string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'mat-checkbox-required');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['required']) {
      if (coerceBooleanProperty(this.required)) {
        this.renderer.addClass(this.el.nativeElement, 'is-required');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'is-required');
      }
    }
  }
}
