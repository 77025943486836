import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

import { RedTooltipDirective } from './tooltip';

/**
 * To make sure the directive works as expected do not use paddings on the element the directive is applied on!
 * FireFox doesn't take paddings into account for the scroll-width.
 */
@Directive({ selector: '[redTooltip][redEllipsisTooltip]' })
export class EllipsisTooltipDirective {
  /** If set to `true` text is only truncated, when it can not be wrapped or still hasn't enough space afterwards */
  @HostBinding('class.red-text-ellipsis-prefer-wrap') @Input() preferWrap = false;

  @HostBinding('class.red-text-ellipsis') ellipsis = true;

  constructor(private elementRef: ElementRef, private tooltipDirective: RedTooltipDirective) {}

  @HostListener('mouseover')
  check() {
    const shown = this.isEllipsisActive(this.elementRef.nativeElement);
    this.tooltipDirective.disabled = !shown;
  }

  private isEllipsisActive(e: HTMLElement) {
    return e.offsetWidth < e.scrollWidth;
  }
}
