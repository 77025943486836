import { FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

/**
 * This validator is supposed to be set on a form-group.
 *
 * If one or more controls have a value, all controls will be required.
 * If none has a value, no control is required.
 */
export const allOrNothingRequiredValidator = (controlNames: string[]): ValidatorFn => {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const controls = controlNames.map(name => formGroup.get(name));
    const atLeastOneHasValue = controls.some(c => c.value);

    if (atLeastOneHasValue) {
      const errors = controls.map(c => Validators.required(c));
      if (errors && errors.some(err => err !== null)) {
        return { notAllFieldsAreSet: true };
      }
    }

    return null;
  };
};
