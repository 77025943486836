import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OverlayModule } from '@angular/cdk/overlay';
import { VssLayoutComponent } from './vss-layout/vss-layout.component';
import { FooterComponent } from './footer/footer.component';
import { ParticipantListComponent } from './participant-list/participant-list.component';
import { MatListModule } from '@angular/material/list';
import { DevicesErrorMessageComponent } from './error/device-error-message/devices-error-message.component';
import { DevicesErrorPageComponent } from './error/devices-error-page/devices-error-page.component';
import { SharedUiModule } from '@redngapps/shared/ui';
import { InitializationErrorComponent } from './error/initialization-error/initialization-error.component';

const exportedDeclarations = [
  VssLayoutComponent,
  ParticipantListComponent,
  FooterComponent,
  DevicesErrorMessageComponent,
  DevicesErrorPageComponent,
  InitializationErrorComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule, OverlayModule, MatIconModule, MatButtonModule, MatListModule, SharedUiModule],
  declarations: [...exportedDeclarations],
  exports: [...exportedDeclarations],
})
export class VideosprechstundeUiModule {}
