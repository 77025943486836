import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceErrorType } from '@redngapps/videosprechstunde/types';
import idx from 'idx';

@Component({
  selector: 'red-devices-error-page',
  templateUrl: './devices-error-page.component.html',
  styleUrls: ['./devices-error-page.component.scss'],
})
export class DevicesErrorPageComponent {
  deviceErrorType: DeviceErrorType;
  deviceErrorTypes = DeviceErrorType;

  constructor(route: ActivatedRoute, private router: Router) {
    this.deviceErrorType = idx(route, _ => _.snapshot.data.deviceErrorType);
  }

  async refreshPage(): Promise<void> {
    await this.router.navigate(['/']);
  }
}
