import {
  Directive,
  Input,
  TemplateRef,
  OnInit,
  ViewContainerRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { startWith, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CompareToFormValueComponent } from './compare-to-form-value.component';
import { MatHint } from '@angular/material/form-field';

@Directive({
  selector: '[redCompareToForm]',
})
export class CompareToFormHintDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  redCompareToForm: string;

  @Input()
  redCompareToFormBeforePrefix: string;

  @Input()
  redCompareToFormBeforeEmpty: string;

  @ViewChild(MatHint, { static: false })
  matHint: MatHint;

  private destroy$ = new Subject<void>();
  private context: { $implicit: string };

  constructor(
    private compareToFormComponent: CompareToFormValueComponent,
    private templateRef: TemplateRef<{ $implicit: string }>,
    private viewContainer: ViewContainerRef,
  ) {
    this.context = { $implicit: '' };
  }

  ngOnInit() {
    this.updateMessage();

    this.compareToFormComponent.hasValueChangedChanges
      .pipe(
        startWith(this.compareToFormComponent.hasValueChanged),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(hasChanged => {
        if (hasChanged) {
          this.viewContainer.createEmbeddedView(this.templateRef, this.context);
        } else {
          this.viewContainer.clear();
        }
      });

    this.compareToFormComponent.valueChanges
      .pipe(
        startWith(this.compareToFormComponent.value),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateMessage();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const beforePrefix = changes['redCompareToFormBeforePrefix'];
    if (beforePrefix) {
      this.updateMessage();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateMessage() {
    this.context.$implicit = `${this.redCompareToFormBeforePrefix || 'Zuvor'}: ${this.compareToFormComponent.value ||
      this.redCompareToFormBeforeEmpty ||
      'Nicht angegeben'}`;
  }
}

// tslint:disable-next-line: no-host-metadata-property
@Directive({ selector: '[redCompareToHint]', host: { class: 'compare-to-hint' } })
export class CompareToHintMarkerDirective {}
