import { RouterModule, Routes } from '@angular/router';

import {
  ConferenceComponent,
  ImprintComponent,
  LoginComponent,
  TermsAndConditionsComponent,
  UnsupportedBrowserComponent,
  BrowserState,
  DataPrivacyStatementComponent,
} from '@redngapps/videosprechstunde/feature';

import { SupportedBrowserGuard } from './guards/supported-browser.guard';
import { UnsupportedBrowserGuard } from './guards/unsupported-browser.guard';
import { VersionGuard } from './guards/version.guard';
import { SocketConnectionResolver } from './resolvers/socket-connection.resolver';
import { WebRTCConnectedGuard } from './guards/webRTC-connected.guard';
import { DevicesUnavailableGuard } from './guards/devices-unavailable.guard';
import { DevicesAvailableGuard } from './guards/devices-available.guard';
import { DevicesErrorPageComponent } from '@redngapps/videosprechstunde/ui';
import { DeviceErrorType } from '@redngapps/videosprechstunde/types';

const routes: Routes = [
  {
    path: 'unsupported-browser',
    component: UnsupportedBrowserComponent,
    canActivate: [SupportedBrowserGuard],
    data: {
      browserState: BrowserState.unsupported,
    },
  },
  {
    path: 'unsupported-browser-version',
    component: UnsupportedBrowserComponent,
    canActivate: [SupportedBrowserGuard],
    data: {
      browserState: BrowserState.outdated,
    },
  },
  {
    path: 'unsupported-safari',
    component: UnsupportedBrowserComponent,
    canActivate: [SupportedBrowserGuard],
    data: {
      browserState: BrowserState.unsupportedSafari,
    },
  },
  {
    path: 'iOS-unsupported',
    component: UnsupportedBrowserComponent,
    canActivate: [SupportedBrowserGuard],
    data: {
      browserState: BrowserState.iOSUnsupported,
    },
  },
  {
    path: 'devices-not-found',
    component: DevicesErrorPageComponent,
    canActivate: [DevicesAvailableGuard],
    data: {
      deviceErrorType: DeviceErrorType.NOT_FOUND,
    },
  },
  {
    path: 'devices-error',
    component: DevicesErrorPageComponent,
    canActivate: [DevicesAvailableGuard],
    data: {
      deviceErrorType: DeviceErrorType.ERROR,
    },
  },
  {
    path: 'imprint',
    component: ImprintComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: '',
    canActivateChild: [UnsupportedBrowserGuard, DevicesUnavailableGuard],
    children: [
      {
        path: 'conference',
        component: ConferenceComponent,
        canActivate: [WebRTCConnectedGuard],
      },
      {
        path: 'data-privacy-statement',
        component: DataPrivacyStatementComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
        resolve: [SocketConnectionResolver],
        canActivate: [VersionGuard],
      },
      {
        path: 'error-page',
        loadChildren: () => import('@redngapps/shared/ui').then(m => m.SharedUiModule),
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'prefix',
        canActivate: [VersionGuard],
      },
      {
        path: '**',
        redirectTo: '/login',
      },
    ],
  },
];

export const VideosprechstundeFeatureShellRoutingModule = RouterModule.forRoot(routes, {
  useHash: true,
});
