import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'red-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  constructor(private router: Router) {}

  async onHome(event: MouseEvent): Promise<void> {
    event.preventDefault();

    await this.router.navigate(['/login']);
  }
}
