import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VideosprechstundeFeatureModule } from '@redngapps/videosprechstunde/feature';
import { environment } from '@video-env/environment';
import { ErrorModule } from './error-module/error.module';
import { FeatureShellComponent } from './feature-shell/feature-shell.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SharedUtilModule } from '@redngapps/shared/util';
import { VideosprechstundeFeatureShellRoutingModule } from './videosprechstunde-feature-shell.routing.module';

@NgModule({
  imports: [
    CommonModule,
    SharedUtilModule,
    VideosprechstundeFeatureShellRoutingModule,
    VideosprechstundeFeatureModule,
    environment.featureSwitches.importErrorModule ? ErrorModule : [],
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
  declarations: [FeatureShellComponent],
  exports: [FeatureShellComponent],
})
export class VideosprechstundeFeatureShellModule {}
