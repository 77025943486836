import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { getYear, getQuarter } from 'date-fns';

@Pipe({
  name: 'quarter',
  pure: true,
})
export class QuarterDatePipe extends DatePipe implements PipeTransform {
  transform(value: string | Date): string {
    const date = value instanceof Date ? value : new Date(value);
    return `Q${getQuarter(date)}/${getYear(date)}`;
  }
}
