import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'red-wiki-field-info',
  templateUrl: './wiki-field-info.component.html',
  styleUrls: ['./wiki-field-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiFieldInfoComponent {
  @Input() wikiPageId: number;
}
