import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';

import { FullscreenOverlayModule } from './fullscreen-overlay.module';
import { FullscreenOverlayRef } from './fullscreen-overlay-ref';

@Injectable({ providedIn: FullscreenOverlayModule })
export class FullScreenOverlay {
  constructor(private overlay: Overlay) {}

  open<T>(component: ComponentType<T>): FullscreenOverlayRef<T> {
    const componentPortal = new ComponentPortal(component);
    const overlayRef = this.overlay.create({
      width: '100vw',
      height: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
    const componentRef = overlayRef.attach(componentPortal);

    return {
      instance: componentRef.instance,
      close() {
        overlayRef.detach();
      },
    };
  }
}
