import { Component, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'red-screen-sharing-placeholder',
  templateUrl: './screen-sharing-placeholder.component.html',
  styleUrls: ['./screen-sharing-placeholder.component.scss'],
})
export class ScreenSharingPlaceholderComponent {
  @Output() stopSharing = new EventEmitter();

  click(): void {
    this.stopSharing.emit();
  }
}
