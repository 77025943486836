import { IEnvironment } from './environment.interface';
import { EEnvironment } from './environment.enum';

export const environment: IEnvironment = {
  name: EEnvironment.production,
  urls: {
    licenseSelect: 'https://www.redmedical.de/preise-red-connect/',
    help: 'https://hilfe.redmedical.de',
    videoAppointment: 'https://video.redmedical.de/',
    impress: 'https://www.redmedical.de/impressum/',
    privacyNotice: '/#/datenschutz',
  },
  featureSwitches: {
    devTools: false,
    sentry: true,
    basis: true,
    pharma: false,
    apo: true,
    storeFreeze: false,
    sendEmail: true,
    sendBccToInfoMail: true,
    useCustomErrorHandler: true,
    chipcardSimulatorAvailable: false,
    useFakeDates: false,
    angularProdModeEnabled: true,
    hvstrClientEnabled: false,
    monitorSlowRedServiceRequests: false,
    showFakeDataKVAbrechnung: false,
    showNewPatientDataOverview: true,
    matomo: {
      url: 'https://analyse.redmedical.de/',
      siteId: 3,
    },
    krw: false,
  },
  softwareVersion: '2.48.0',
  gitHash: 'will-be-replaced',
};
