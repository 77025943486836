import { Component, Inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

export type SnackbarButtonOnClickCallback = () => void;

export interface ICustomSnackBarMessage {
  message: string;
  buttons?: Array<{ text: string; onClickCallback?: SnackbarButtonOnClickCallback }>;
  config?: MatSnackBarConfig;
  onClickButton$?: Subject<SnackbarButtonOnClickCallback>;
}

@Component({
  selector: 'red-custom-mat-snack-bar',
  templateUrl: './custom-mat-snack-bar.component.html',
  styleUrls: ['./custom-mat-snack-bar.component.scss'],
})
export class CustomMatSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ICustomSnackBarMessage, public snackBar: MatSnackBar) {}

  onClick(callback: SnackbarButtonOnClickCallback): void {
    this.data.onClickButton$.next(callback);
    this.snackBar.dismiss();
  }
}
