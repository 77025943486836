import { NgModule } from '@angular/core';

import { AutocompleteArrowComponent } from './autocomplete-arrow.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [MatIconModule],
  declarations: [AutocompleteArrowComponent],
  exports: [AutocompleteArrowComponent],
})
export class AutocompleteModule {}
