import { Component, ElementRef } from '@angular/core';

const CHIP_HOST_ATTRIBUTES = ['red-static-chip', 'red-action-chip', 'red-filter-chip'];

@Component({
  selector: CHIP_HOST_ATTRIBUTES.map(attribute => 'mat-chip[' + attribute + ']').join(', '),
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class RedChipComponent {
  constructor(private elementRef: ElementRef) {
    for (const attr of CHIP_HOST_ATTRIBUTES) {
      if (this.hasHostAttributes(attr)) {
        this.hostElement.classList.add(attr);
      }
    }
  }

  get hostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some(attribute => this.hostElement.hasAttribute(attribute));
  }
}
