export enum ServerErrorErrno {
  AUTH_NOT_LOGGED_IN = -50,
  UNKNOWN_TENANT = -60,
  AUTH_UNKNOWN_USERNAME_PASSWORD = -70,
  AUTH_UNKNOWN_USERNAME = -71,
  AUTH_USER_DEACTIVATED = -75,
  UNIQUE_GROUP_CONSTRAINT_ERROR = -79,
  UNIQUE_CONSTRAINT_ERROR = -80,
  CONSTRAINT_DELETE = -110,
  UPDATE_RECORD_DELETED = -170,
  LICENSE_UPGRADE = -229,
  AUTH_ALREADY_LOGGED_IN = -420,
  /** Indicates that the user tried to login from multiple tabs or devices */
  AUTH_LOGIN_CAP_REACHED = -425,
  AUTH_TERMINAL_NOT_AUTHORIZED = -440,
  CONSTRAINT_IDENT = -530,
  UPDATE_RECORD_UPDATED = -560,
  DELETE_USER_NOT_FOUND = -615,
  DELETE_RECORD_DELETED = -618,
  DELETE_RECORD_UPDATED = -619,
  PASSWORD_RESET = -680,
  AUT_USER_TERMINATED = -691,
  AUTH_TERMINAL_DEACTIVATED = -830,
  READ_RECORD_UPDATED = -1000,
}

export enum ServerErrorKeys {
  UNKNOWN_TENANT = 'error_auth_unknown_tenant',
  AUTH_UNKNOWN_USERNAME_PASSWORD = 'error_auth_unknown_username_password',
  AUTH_UNKNOWN_USERNAME = 'error_auth_unknown_user',
  AUTH_USER_DEACTIVATED = 'error_auth_user_deactivated',
  AUTH_NOT_LOGGED_IN = 'error_auth_not_logged_in',
  AUTH_ALREADY_LOGGED_IN = 'error_auth_already_logged_in',
  AUTH_LOGIN_CAP_REACHED = 'user_login_cap_reached',
  AUTH_TERMINAL_DEACTIVATED = 'error_auth_terminal_deactivated',
  AUT_USER_TERMINATED = 'error_user_terminated',
  UNIQUE_CONSTRAINT_ERROR = 'error_constraint_unique',
  DUPLICATE_AUTHORIZATION_REQUEST = 'error_duplicate_authorization_request',
  ERROR_UPDATE_RECORD_UPDATED = 'error_update_record_updated',
  ERROR_UPDATE_RECORD_DELETED = 'error_update_record_deleted',
  ERROR_DELETE_RECORD_DELETED = 'error_delete_record_deleted',
  ERROR_DELETE_RECORD_UPDATED = 'error_delete_record_updated',
  ERROR_DELETE_USER_NOT_FOUND = 'error_delete_user_not_found',

  ERROR_READ_RECORD_UPDATED = 'error_read_record_updated',
  AUTH_TERMINAL_NOT_AUTHORIZED = 'error_auth_terminal_not_authorized',
  ERROR_PASSWORD_RESET = 'error_password_reset',
  ERROR_CONSTRAINT_DELETE = 'error_constraint_delete',
  ERROR_CONSTRAINT_DATASET_LOCKED = 'error_constraint_dataset_locked',

  ERROR_SOCKET_CONNECTION = 'error_socket_connect',
  ERROR_LICENSE_UPGRADE = 'error_license_upgrade',
  AJAX_ERROR = 'AJAXError',
}

export enum ServerErrorMessage {
  // Server Errors
  UNKNOWN_TENANT = 'Sie haben leider einen falschen Benutzernamen oder Ihr Passwort falsch eingegeben. ' +
    'Bitte prüfen Sie Ihre Eingaben und versuchen es erneut.',
  // Server Auth Errors
  AUTH_UNKNOWN_USERNAME_PASSWORD = 'Sie haben leider einen falschen Benutzernamen oder Ihr Passwort falsch eingegeben. ' +
    'Bitte prüfen Sie Ihre Eingaben und versuchen es erneut.',
  AUTH_UNKNOWN_USERNAME = 'Unbekannter Benutzername.',
  AUTH_USER_DEACTIVATED = 'Benutzer wurde deaktiviert',
  AUTH_NOT_LOGGED_IN = 'Sie sind nicht mehr angemeldet.',
  AUTH_ALREADY_LOGGED_IN = 'Der Benutzer ist bereits eingeloggt.',
  AUTH_LOGIN_CAP_REACHED = 'Sie sind noch an einem anderen Arbeitsplatz angemeldet. Sie müssen sich dort erst abmelden, bevor Sie sich an einem anderem Arbeitsplatz einloggen können.',
  AUTH_TERMINAL_DEACTIVATED = 'Das ausgewählte Endgerät ist gesperrt. Bitte kontaktieren Sie den Systemverantwortlichen für die Entsperrung.',
  AUTH_TERMINAL_NOT_AUTHORIZED = 'Sie haben die Freischaltung an diesem Arbeitsplatz beauftragt ' +
    'und Ihren Systemverantwortlichen informiert. Bis zur Freischaltung kann' +
    ' dieses Endgerät nicht verwendet werden.',
  AUT_USER_TERMINATED = 'Ihr Benutzerzugang wurde gelöscht. ' +
    'Bei Fragen wenden Sie sich bitte an ' +
    'den Hauptnutzer Ihrer Organisation.',
  ERROR_PASSWORD_RESET = 'Ihr Passwort wurde zurückgesetzt.',
  // CRUD Errors
  ERROR_UPDATE_RECORD_UPDATED = 'Der Datensatz wurde inzwischen von einem anderen Benutzer bearbeitet.',
  ERROR_UPDATE_RECORD_DELETED = 'Der Datensatz wurde inzwischen von einem anderen Benutzer gelöscht.',
  ERROR_DELETE_RECORD_DELETED = 'Der Datensatz wurde inzwischen von einem anderen Benutzer gelöscht.',
  ERROR_DELETE_RECORD_UPDATED = 'Der Datensatz wurde inzwischen von einem anderen Benutzer bearbeitet.',
  ERROR_DELETE_USER_NOT_FOUND = 'Der Benutzer konnte nicht gefunden werden.',
  ERROR_READ_RECORD_UPDATED = 'Der Datensatz hat sich zwischenzeitlich geändert.',
  ERROR_CONSTRAINT_DELETE = 'Dieser Datensatz kann nicht gelöscht werden, da noch Daten auf ihn verweisen.',
  // Custom Errors
  AJAX_ERROR = 'Es konnte keine Verbindung zum Server hergestellt werden.',
  UNDEFINED_ERROR = 'Ein unbekannter Fehler ist aufgetreten.',
  ERROR_LICENSE_UPGRADE = 'Ihre Lizenz kann nicht aktualisiert werden.',
}

export enum ServerErrorPageRoute {
  FIREWALL_PROXY = 'error-page/firewall-proxy',
}

// Currently there two implementations for mapping server-errors (by errno and by key)
export const serverErrorsMappedByKey = {
  [ServerErrorKeys.UNKNOWN_TENANT]: ServerErrorMessage.UNKNOWN_TENANT,
  [ServerErrorKeys.AUTH_UNKNOWN_USERNAME_PASSWORD]: ServerErrorMessage.AUTH_UNKNOWN_USERNAME_PASSWORD,
  [ServerErrorKeys.AUTH_NOT_LOGGED_IN]: ServerErrorMessage.AUTH_NOT_LOGGED_IN,
  [ServerErrorKeys.AUTH_TERMINAL_DEACTIVATED]: ServerErrorMessage.AUTH_TERMINAL_DEACTIVATED,
  [ServerErrorKeys.AUTH_NOT_LOGGED_IN]: ServerErrorMessage.AUTH_NOT_LOGGED_IN,
  [ServerErrorKeys.AUT_USER_TERMINATED]: ServerErrorMessage.AUT_USER_TERMINATED,
  [ServerErrorKeys.AUTH_LOGIN_CAP_REACHED]: ServerErrorMessage.AUTH_LOGIN_CAP_REACHED,
  [ServerErrorKeys.ERROR_UPDATE_RECORD_UPDATED]: ServerErrorMessage.ERROR_UPDATE_RECORD_UPDATED,
  [ServerErrorKeys.ERROR_UPDATE_RECORD_DELETED]: ServerErrorMessage.ERROR_UPDATE_RECORD_DELETED,
  [ServerErrorKeys.ERROR_DELETE_RECORD_DELETED]: ServerErrorMessage.ERROR_DELETE_RECORD_DELETED,
  [ServerErrorKeys.ERROR_DELETE_RECORD_UPDATED]: ServerErrorMessage.ERROR_DELETE_RECORD_UPDATED,
  [ServerErrorKeys.ERROR_DELETE_USER_NOT_FOUND]: ServerErrorMessage.ERROR_DELETE_USER_NOT_FOUND,
  [ServerErrorKeys.ERROR_READ_RECORD_UPDATED]: ServerErrorMessage.ERROR_READ_RECORD_UPDATED,
  [ServerErrorKeys.AUTH_TERMINAL_NOT_AUTHORIZED]: ServerErrorMessage.AUTH_TERMINAL_NOT_AUTHORIZED,
  [ServerErrorKeys.ERROR_PASSWORD_RESET]: ServerErrorMessage.ERROR_PASSWORD_RESET,
  [ServerErrorKeys.ERROR_CONSTRAINT_DELETE]: ServerErrorMessage.ERROR_CONSTRAINT_DELETE,
  [ServerErrorKeys.ERROR_LICENSE_UPGRADE]: ServerErrorMessage.ERROR_LICENSE_UPGRADE,
};

export const ServerErrorsMappedByErrno = {
  [ServerErrorErrno.AUTH_ALREADY_LOGGED_IN]: ServerErrorMessage.AUTH_ALREADY_LOGGED_IN,
  [ServerErrorErrno.UNKNOWN_TENANT]: ServerErrorMessage.UNKNOWN_TENANT,
  [ServerErrorErrno.AUTH_UNKNOWN_USERNAME_PASSWORD]: ServerErrorMessage.AUTH_UNKNOWN_USERNAME_PASSWORD,
  [ServerErrorErrno.AUTH_UNKNOWN_USERNAME]: ServerErrorMessage.AUTH_UNKNOWN_USERNAME,
  [ServerErrorErrno.AUTH_USER_DEACTIVATED]: ServerErrorMessage.AUTH_USER_DEACTIVATED,
  [ServerErrorErrno.AUT_USER_TERMINATED]: ServerErrorMessage.AUT_USER_TERMINATED,
  [ServerErrorErrno.CONSTRAINT_DELETE]: ServerErrorMessage.ERROR_CONSTRAINT_DELETE,
  [ServerErrorErrno.UPDATE_RECORD_DELETED]: ServerErrorMessage.ERROR_UPDATE_RECORD_DELETED,
  [ServerErrorErrno.AUTH_ALREADY_LOGGED_IN]: ServerErrorMessage.AUTH_ALREADY_LOGGED_IN,
  [ServerErrorErrno.AUTH_LOGIN_CAP_REACHED]: ServerErrorMessage.AUTH_LOGIN_CAP_REACHED,
  [ServerErrorErrno.AUTH_TERMINAL_NOT_AUTHORIZED]: ServerErrorMessage.AUTH_TERMINAL_NOT_AUTHORIZED,
  [ServerErrorErrno.UPDATE_RECORD_UPDATED]: ServerErrorMessage.ERROR_UPDATE_RECORD_UPDATED,
  [ServerErrorErrno.DELETE_USER_NOT_FOUND]: ServerErrorMessage.ERROR_DELETE_USER_NOT_FOUND,
  [ServerErrorErrno.DELETE_RECORD_DELETED]: ServerErrorMessage.ERROR_DELETE_RECORD_DELETED,
  [ServerErrorErrno.DELETE_RECORD_UPDATED]: ServerErrorMessage.ERROR_DELETE_RECORD_UPDATED,
  [ServerErrorErrno.PASSWORD_RESET]: ServerErrorMessage.ERROR_PASSWORD_RESET,
  [ServerErrorErrno.LICENSE_UPGRADE]: ServerErrorMessage.ERROR_LICENSE_UPGRADE,
};

export const ServerErrorPagesMappedByKey = {
  [ServerErrorKeys.ERROR_SOCKET_CONNECTION]: ServerErrorPageRoute.FIREWALL_PROXY,
};
