import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

// fixes: https://github.com/nrwl/nx/issues/436
// CustomRouterSerializer is a workaround to make store freeze work with angular router

/**
 * Application-wide router state object.
 */
export interface RouterStateUrl {
  /** current url */
  url: string;
  /** id parameter for modal components extracted from the current url */
  modalId?: string;
}

/**
 * Convert the default router reducer state from `RouterStateSnapshot` to
 * a custom object `RouterStateUrl` containing regular id and modal window id
 * in addition to the url. Any other data is dropped because of complexity and unnecessity.
 *
 * The stored id parameters can then be used via custom selectors to automatically retrieve the
 * appropriate object from the store.
 */
@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    let modalId: string;

    // iterate over primary route nodes only
    while (route) {
      // check for a direct modal sub-node
      if (!modalId) {
        const modalSubnode = route.children.find(r => r.outlet === 'modal');

        // extract its id, if available
        if (modalSubnode && modalSubnode.params.id) {
          modalId = modalSubnode.params.id;
        }
      }

      // proceed to the next primary route node
      route = route.children.find(r => r.outlet === 'primary');
    }

    return { modalId, url: routerState.url };
  }
}
