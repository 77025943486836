import { Injectable } from '@angular/core';
import { Severity as SentrySeverity } from '@sentry/types';
import { SentryService } from './sentry.service';
import { Severity } from './logger';
import { isLogSafeValue, getLogValue } from '../../functions/anonymized-logger.utils';
import { LoggerString } from './anonymized-logger.models';

@Injectable()
export class SentryLoggerService {
  constructor(private sentryService: SentryService) {}

  log(message: LoggerString, severity: Severity, error?: Error, ...metaData: any[]): void {
    const mappedMetaData = metaData.map(val => (isLogSafeValue(val) ? getLogValue(val) : 'XXX'));

    if (error) {
      this.logToConsole(message, severity, error, ...mappedMetaData);
    } else {
      this.logToConsole(message, severity, undefined, ...mappedMetaData);
    }

    // Send only errors directly to sentry, the rest should be saved as breadcrumbs.
    // When an error occurs, breadcrumbs will be sent with the error.
    if (severity === Severity.Error) {
      this.sendErrorReport(message, error, ...mappedMetaData);
    } else {
      this.leaveBreadcrumb(message, severity, ...mappedMetaData);
    }
  }

  private sendErrorReport(loggerString: LoggerString, ...metaData: any[]): void {
    const context = {
      level: SentrySeverity.Error,
      extra: { ...metaData },
    };
    this.sentryService.captureMessage(loggerString.message, context);
  }

  private leaveBreadcrumb(loggerString: LoggerString, severity: Severity, ...metaData: any[]): void {
    this.sentryService.addBreadcrumb({
      message: loggerString.message,
      category: 'logger',
      level: severity === Severity.Warning ? SentrySeverity.Warning : SentrySeverity.Info,
      data: { ...metaData },
    });
  }

  private logToConsole(loggerString: LoggerString, severity: Severity, error?: Error, ...metaData: any[]) {
    switch (severity) {
      case Severity.Warning:
        if (metaData) {
          console.warn(loggerString.message, ...metaData);
        } else {
          console.warn(loggerString.message);
        }
        break;

      case Severity.Error:
        if (metaData) {
          if (error) {
            console.error(loggerString.message, error, ...metaData);
          } else {
            console.error(loggerString.message, ...metaData);
          }
        } else {
          if (error) {
            console.error(loggerString.message, error);
          } else {
            console.error(loggerString.message);
          }
        }
        break;

      default:
        if (metaData) {
          console.log(loggerString.message, ...metaData);
        } else {
          console.log(loggerString.message);
        }
        break;
    }
  }
}
