import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import idx from 'idx';
import { environment } from '@client-env/environment';

export enum BrowserState {
  unsupported,
  outdated,
  iOSUnsupported,
  unsupportedSafari,
  privateFirefox,
}

@Component({
  selector: 'red-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss'],
})
export class UnsupportedBrowserComponent {
  browserState: BrowserState;
  browserStates = BrowserState;

  redHelpLink = environment.urls.help + '/x/WQDW';

  constructor(route: ActivatedRoute) {
    this.browserState = idx(route, _ => _.snapshot.data.browserState);
  }
}
