import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { format as formatDate, isDate, parse as parseDate } from 'date-fns';
import * as locale from 'date-fns/locale/de';
import * as moment from 'moment';

export const WEEK_STARTS_ON = 1; // 0 sunday, 1 monday...
const INVALID_DATE = new Date(NaN);

@Injectable({ providedIn: 'root' })
export class RedDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if (typeof value === 'string') {
      const momentFourDigits = moment(value, 'D.M.YYYY', true);
      if (momentFourDigits.isValid()) {
        return momentFourDigits.toDate();
      }
      return moment(value, 'D.M.YY', true).toDate();
    }
    return value ? (isDate(value) ? parseDate(value) : INVALID_DATE) : null;
  }

  format(date: Date, displayFormat: any): string {
    return formatDate(date, displayFormat, { locale });
  }

  getFirstDayOfWeek(): number {
    return WEEK_STARTS_ON;
  }
}
